<script setup>
/* eslint-env jquery */
import { ref, computed, watch } from 'vue';
import { useRoute } from 'vue-router';
import OrderDetailsGeneral from './OrderDetailsGeneral.vue';
import OrderDetailsAdditional from './OrderDetailsAdditional.vue';
import OrderActionsDetails from './OrderActionsDetails.vue';
import { navItems } from '@/helpers/navigationPanelHelper.js';
import { useNavigationPanelStore } from '@/stores/navigationPanel';
import OrderRangesSummary from './OrderRangesSummary.vue';
import { useMapStore } from '../../stores/map';

const SHRINKED_LEFT_PANEL_WIDTH = '100px';
const STRETCHED_LEFT_PANEL_WIDTH = '25%';

const orderDetailsGeneralComponentKey = ref(0);
const orderDetailsAdditionalComponentKey = ref(0);
const orderDetailsActionsComponentKey = ref(0);
const orderDetailsRangesComponentKey = ref(0);
const isLoading = ref(false);
const order = ref(null);
const route = useRoute();
const navigationPanelStore = useNavigationPanelStore();
const generalDetailsCollapsed = ref(false);
const orderDetailsAdditional = ref();
const orderActionDetails = ref();
const mapStore = useMapStore();
const leftPanelWidth = ref(STRETCHED_LEFT_PANEL_WIDTH);

const orderContractorsTabVisible = computed(() => {
  return orderDetailsAdditional.value?.currentlyLoaded === 'orderContractors';
});

const orderIdForDetails = computed(() => {
  return parseInt(route.params.id);
});

watch(orderIdForDetails, () => {
  refreshAll();
});

// created
navigationPanelStore.setOrderId(orderIdForDetails.value);
navigationPanelStore.setNavigationPanelItems(navItems.ORDER_DETAILS);

function collapseGeneralDetails() {
  toggleLayout();
  generalDetailsCollapsed.value = true;
}

function expandGeneralDetails() {
  toggleLayout();
}

function refreshAll() {
  orderDetailsGeneralComponentKey.value++;
  orderDetailsAdditionalComponentKey.value++;
  orderDetailsActionsComponentKey.value++;
  orderDetailsRangesComponentKey.value++;
}

function toggleLayout() {
  leftPanelWidth.value =
    leftPanelWidth.value === STRETCHED_LEFT_PANEL_WIDTH
      ? SHRINKED_LEFT_PANEL_WIDTH
      : STRETCHED_LEFT_PANEL_WIDTH;
}

function onLeftPanelTransitionEnd(event) {
  if (event.propertyName === 'flex-basis') {
    mapStore.tryToRefreshMaps();

    if (leftPanelWidth.value === STRETCHED_LEFT_PANEL_WIDTH) {
      generalDetailsCollapsed.value = false;
    }
  }
}
</script>

<template>
  <div>
    <div class="resizeable-container">
      <div
        class="left"
        :style="{ flexBasis: leftPanelWidth }"
        @transitionend="onLeftPanelTransitionEnd"
      >
        <OrderDetailsGeneral
          :key="orderDetailsGeneralComponentKey"
          :id="orderIdForDetails"
          :collapsed="generalDetailsCollapsed"
          @refresh="orderDetailsGeneralComponentKey++"
          @onIsLoadingChanged="isLoading = $event"
          @onOrderLoaded="order = $event"
          @collapsePanel="collapseGeneralDetails"
          @expandPanel="expandGeneralDetails"
        />
      </div>
      <div v-if="order" class="right">
        <OrderDetailsAdditional
          ref="orderDetailsAdditional"
          :key="orderDetailsAdditionalComponentKey"
          :id="orderIdForDetails"
          :isLoading="isLoading"
          :order="order"
          @refresh-general="orderDetailsGeneralComponentKey++"
          @refreshActionsMap="orderActionDetails.refreshMapBounds()"
        />
        <OrderActionsDetails
          v-show="orderContractorsTabVisible"
          ref="orderActionDetails"
          :key="orderDetailsActionsComponentKey"
          class="mt-4"
          :orderId="orderIdForDetails"
          :orderNumber="order?.order_number"
          :order="order"
          @refreshAll="refreshAll"
        />
        <OrderRangesSummary
          v-show="orderContractorsTabVisible"
          :key="orderDetailsRangesComponentKey"
          class="mt-4"
          :orderId="orderIdForDetails"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.resizeable-container {
  display: flex;
  gap: 25px;
}

.left {
  transition: flex-basis 1s ease;
}

.right {
  flex-grow: 1;
}
</style>
