export const PAGINATION_LIMIT = 5;
export const PAGINATION_INITIAL_ITEMS_PER_PAGE = 10;
export const FILE_MAX_SIZE = 50;
export const USER_COLORS = [
  '#6e400b',
  '#ad323b',
  '#CD3F2C',
  '#845EC2',
  '#c493ff',
  '#f556ef',
  '#faccff',
  '#ff8066',
  '#ffc800',
  '#61ffbb',
  '#00c9a7',
  '#0e4a2a',
  '#42eff5',
  '#78acff',
  '#428df5',
  '#4f4694'
];
export const ORDER_TYPES_IDS_WITHOUT_GEODESY = [1];

export const START_ORDER_ACTION_POPUP_CAPTION = 'Rozpoczęcie wykonania zlecenia';
export const STOP_ORDER_ACTION_POPUP_CAPTION = 'Zakończenie wykonania zlecenia';
export const RADO_LOGO_SIZE = [151, 54];
export const RADO_LOGO_SIZE_LOGIN_PAGE = [151 * 2.5, 54 * 2.5];
export const APP_PRIMARY_COLOR = '276fb3';
export const DEFAULT_INITIAL_LAT = 50.29044;
export const DEFAULT_INITIAL_LON = 21.42891;

export const REPORT_STORAGE_PLACE_TYPE_FOR_MAP = 4;
export const OSD_TYPES_FOR_MAP_IDS = [5, 6];
export const OSD_DEM_ELEM_TYPE_ID = 6;
export const POLE_DEM_ELEM_TYPE_ID = 1;
export const DISMANTLING_FAILURE_TYPE_FOR_MAP = 3;
export const DISMANTLING_FAILURE_TYPE_FOR_POINTS = 3;
export const FAILURE_REASON_ACTIVE_POLE_ID = 6;
export const FAILURE_REASON_OWNER_DISAGREEMENT_ID = 10;

export const NUMBER_UNIT_TYPE_ID = 1;
export const KG_UNIT_TYPE_ID = 2;

export const DRIVER_IS_WAITING_ORDER_STATUS_ID = 1;
export const DRIVER_IS_WAITING_FOR_VERIFICATION_STATUS_ID = 3;
export const FAILURE_ACTION_TYPE_ID = 3;
export const COMPLETED_REPORT_TYPE_ID = 5;
