<script setup>
import { ref, watch } from 'vue';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import Swal from 'sweetalert2';
import ModalGallery from '../utils/ModalGallery.vue';
import { APP_PRIMARY_COLOR, PAGINATION_LIMIT } from '../../data/constants/appConstants.js';
import PaginationCountPicker from '@/components/utils/PaginationCountPicker.vue';
import { usePaginationStore } from '@/stores/storePagination';
import ServerErrorPanel from '@/components/utils/ServerErrorPanel.vue';
import { Bootstrap4Pagination } from 'laravel-vue-pagination';
import { handleResponseWithMessages } from '@/helpers/wwwHelper.js';
import { isNotEmptyArray } from '../../helpers/utilsHelper';
import { REPO_FILES_TABLE_POST_COMPONENT_PAGINATION_KEY } from '../../data/paginationInjectionKeys';
import { getSwalConfiguration } from '../../helpers/swalHelper';

const RepositoryFiles = RepositoryFactory.get('files');

const props = defineProps({
  url: { type: String, default: null },
  showBorder: { type: Boolean, default: true },
  widerPaginationCountPicker: { type: Boolean, default: false },
  noElementsCaption: { type: String, default: 'Brak plików do wyświetlenia' },
  modalId: { type: String, default: 'abcasd' },
  deletingEnabled: { type: Boolean, default: false },
  mobileAppSharingEnabled: { type: Boolean, default: false },
  customPaginationKey: { type: String, default: null },
  searchFromOutside: { type: String, default: '' },
  userFilter: { type: Object, default: null },
  deleteFunctionOverride: { type: Function, default: null }
});

const emit = defineEmits(['fileDeleted', 'fileShared', 'dataFetched']);
const paginationStore = usePaginationStore();
const isLoading = ref(false);
const resourcesObject = ref(null);
const searchBox = ref(props.searchFromOutside ?? '');
const modalGallery = ref();
const imgSources = ref([]);

watch(
  () => props.url,
  () => {
    fetchData();
  },
  { immediate: true }
);

async function fetchData(page = 1) {
  isLoading.value = true;

  RepositoryFiles.getFilesObjectPOST(
    page,
    paginationStore.getPaginationItemsCount(
      props.customPaginationKey ?? REPO_FILES_TABLE_POST_COMPONENT_PAGINATION_KEY
    ),
    searchBox.value,
    '',
    props.url,
    props.userFilter
  )
    .then((data) => {
      resourcesObject.value = data;

      if (
        isNotEmptyArray(resourcesObject.value?.data) &&
        resourcesObject.value.data[0].resource != null
      ) {
        resourcesObject.value.data = resourcesObject.value?.data?.map((x) => x.resource);
      }

      resourcesObject.value?.data.forEach((resource) => {
        if (resource.path != null) {
          resource.path = import.meta.env.VITE_VUE_APP_IMAGES_URL + resource.path;
        }

        if (resource.path_min != null) {
          resource.path_min = import.meta.env.VITE_VUE_APP_IMAGES_URL + resource.path_min;
        }
      });

      imgSources.value = resourcesObject.value?.data.map((x) => x.path);

      emit('dataFetched');
    })
    .catch((error) => console.log(error))
    .finally(() => {
      isLoading.value = false;
    });
}

function showModal(pickedImgId) {
  modalGallery.value.currentSourceIndex = resourcesObject.value?.data.findIndex(
    (x) => x.id === pickedImgId
  );
}

function getFileIcon(resource) {
  if (!resource) return "<div class='icon'><i class='fa fa-file'></i></div>";

  if (resource?.id_resource_type == null)
    return `<div class='icon'><i class='fa fa-file' style='color: #${APP_PRIMARY_COLOR}77;'></i></div>`;

  switch (resource.id_resource_type) {
    case 2:
      return `<div class='icon'><i class='fa fa-file-pdf-o' style='color: #${APP_PRIMARY_COLOR}77;'></i></div>`;
    case 3:
    case 4:
    case 5:
      if (resource.path_min != null)
        return `<div class='image text-center'><img alt='image' class='img-fluid' style='max-height: 100%;' src='${resource.path_min}' /></div>`;
      else
        return `<div class='image text-center'><img alt='image' class='img-fluid' style='max-height: 100%;' src='${resource.path}' /></div>`;
    case 7:
      return `<div class='icon'><i class='fa fa-file-zip-o' style='color: #${APP_PRIMARY_COLOR}77;'></i></div>`;
    default:
      return `<div class='icon'><i class='fa fa-file' style='color: #${APP_PRIMARY_COLOR}77;'></i></div>`;
  }
}

function showFileInNewTab(fileToShow) {
  if (fileToShow?.path != null) {
    window.open(fileToShow.path, '_blank');
  }
}

function onDeleteFileButtonClicked(fileToDelete) {
  Swal.fire(
    getSwalConfiguration('Usuwanie pliku', 'Czy na pewno chcesz usunąć wybrany plik?')
  ).then((result) => {
    if (result.value) {
      if (props.deleteFunctionOverride != null) {
        props.deleteFunctionOverride(fileToDelete?.id);
      } else {
        deleteFile(fileToDelete);
      }
    }
  });
}

async function deleteFile(fileToDelete) {
  var response = await RepositoryFiles.deleteFile(fileToDelete?.id);

  handleResponseWithMessages(response, 'Plik został usunięty.', () => {
    emit('fileDeleted');
  });
}

function onShareFileToMobileAppButtonClicked(resource) {
  Swal.fire(
    getSwalConfiguration(
      'Udostępnianie pliku',
      `Czy na pewno chcesz ${
        resource.shared === 1 ? 'ukryć' : 'pokazać'
      } wybrany plik w aplikacji mobilnej?`
    )
  ).then((result) => {
    if (result.value) {
      shareFileToMobileApp(resource);
    }
  });
}

async function shareFileToMobileApp(resource) {
  var response = await RepositoryFiles.shareFile(resource.id, resource.shared === 0);

  handleResponseWithMessages(
    response,
    `Plik będzie ${resource.shared === 1 ? 'ukryty' : 'widoczny'} ukryty w aplikacji mobilnej.`,
    () => {
      emit('fileShared');
    }
  );
}

function onItemsPerPageChanged() {
  fetchData();
}
</script>

<template>
  <div class="ibox">
    <div
      class="ibox-content"
      :class="{ 'sk-loading': isLoading }"
      :style="props.showBorder ? '' : 'border-style: none'"
    >
      <div class="sk-spinner sk-spinner-three-bounce">
        <div class="sk-bounce1"></div>
        <div class="sk-bounce2"></div>
        <div class="sk-bounce3"></div>
      </div>

      <div>
        <div class="grid-container" v-if="isNotEmptyArray(resourcesObject?.data)">
          <div
            class="file-box grid-item"
            v-for="resource in resourcesObject.data"
            :key="resource.id"
            @click="
              resource.id_resource_type === 3 ||
              resource.id_resource_type === 4 ||
              resource.id_resource_type === 5
                ? showModal(resource.id)
                : showFileInNewTab(resource)
            "
            data-toggle="modal"
            :data-target="
              resource.id_resource_type === 3 ||
              resource.id_resource_type === 4 ||
              resource.id_resource_type === 5
                ? `#${modalId}`
                : ''
            "
          >
            <div class="file grid-child-item">
              <button
                v-if="mobileAppSharingEnabled"
                type="button"
                class="btn btn-white btn-xs"
                style="position: absolute; left: 5px; top: 5px"
                :style="resource?.shared === 1 ? `background-color: #${APP_PRIMARY_COLOR}77;` : ''"
                :title="`${resource?.shared === 1 ? 'Ukryj' : 'Pokaż'} plik w aplikacji mobilnej`"
                @click="onShareFileToMobileAppButtonClicked(resource)"
                onclick="event.stopPropagation()"
              >
                <i
                  class="fa fa-users"
                  :style="resource?.shared === 1 ? 'color: green' : 'color: gray'"
                ></i>
              </button>

              <button
                v-if="props.deletingEnabled"
                type="button"
                class="btn btn-white btn-xs"
                style="position: absolute; right: 5px; top: 5px"
                title="Usuń plik"
                @click="onDeleteFileButtonClicked(resource)"
                onclick="event.stopPropagation()"
              >
                <i class="fa fa-trash-o" style="color: gray"></i>
              </button>

              <span v-html="getFileIcon(resource)" />

              <div class="file-name" style="overflow-wrap: break-word">
                {{
                  resource.path != null
                    ? resource.path.substring(resource.path.lastIndexOf('/') + 1)
                    : '-'
                }}
                <br />
                <small
                  >Dodane przez:
                  {{
                    resource.created_by?.firstName && resource.created_by?.lastName
                      ? resource.created_by.firstName + ' ' + resource.created_by.lastName
                      : '-'
                  }}</small
                >
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="resourcesObject?.error">
          <ServerErrorPanel @onRetry="fetchData" />
        </div>
        <div v-else class="col-12">
          <p class="text-center mt-3">{{ noElementsCaption }}</p>
        </div>
      </div>

      <div v-if="isNotEmptyArray(resourcesObject?.data)" class="row mt-3">
        <div :class="widerPaginationCountPicker ? 'col-xl-6 offset-xl-1' : 'col-xl-6 offset-xl-3'">
          <Bootstrap4Pagination
            v-if="resourcesObject?.data"
            align="center"
            :data="resourcesObject"
            :limit="PAGINATION_LIMIT"
            @pagination-change-page="fetchData"
          ></Bootstrap4Pagination>
        </div>

        <div :class="widerPaginationCountPicker ? 'col-xl-5' : 'col-xl-3'">
          <PaginationCountPicker
            :paginationKey="
              props.customPaginationKey ?? REPO_FILES_TABLE_POST_COMPONENT_PAGINATION_KEY
            "
            v-show="!isLoading"
            @selectionChanged="onItemsPerPageChanged"
          />
        </div>
      </div>
    </div>

    <ModalGallery ref="modalGallery" :sources="imgSources" :id="modalId" />
  </div>
</template>

<style scoped>
.grid-child-item {
  cursor: pointer;
  margin: 0%;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 20px;
}

.grid-item {
  min-width: 200px;
  max-width: 250px;
  width: 100%;
}
</style>
