import { isNotEmptyArray } from './utilsHelper';

export function getAvailableActionTypes(demPoints, actionsWithNoDemPoint) {
  let result = new Array();

  if (isNotEmptyArray(demPoints)) {
    const actionTypesFromDemPoints = demPoints.reduce((accumulator, currentDemPoint) => {
      if (!isNotEmptyArray(currentDemPoint?.actions)) return accumulator;

      currentDemPoint.actions.forEach((action) => {
        if (
          !accumulator.some(
            (actionType) => actionType.id != null && actionType.id === action.action_type.id
          )
        ) {
          accumulator.push({
            id: action.action_type.id,
            name: action.action_type.name
          });
        }
      });

      return accumulator;
    }, []);

    result = result.concat(actionTypesFromDemPoints);
  }

  if (isNotEmptyArray(actionsWithNoDemPoint)) {
    const actionTypesFromActionsWithNoDemPoint = actionsWithNoDemPoint.reduce(
      (accumulator, currentAction) => {
        if (
          !accumulator.some(
            (actionType) => actionType.id != null && actionType.id === currentAction.action_type.id
          )
        ) {
          accumulator.push({
            id: currentAction.action_type.id,
            name: currentAction.action_type.name
          });
        }

        return accumulator;
      },
      []
    );

    result = result.concat(actionTypesFromActionsWithNoDemPoint);
  }

  if (!isNotEmptyArray(result)) return [];

  const uniqueResult = result.reduce((accumulator, currentAction) => {
    if (!accumulator.some((entry) => entry.id != null && entry.id === currentAction.id)) {
      accumulator.push({ id: currentAction.id, name: currentAction.name });
    }

    return accumulator;
  }, []);

  return uniqueResult;
}
