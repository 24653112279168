<script setup>
import { ref } from 'vue';
import ModalBase from '@/components/utils/ModalBase.vue';
import ModalBody from '@/components/utils/ModalBody.vue';
import AcceptanceProtocolGenerationForm from './AcceptanceProtocolGenerationForm.vue';

const props = defineProps({ orderId: Number });
const emit = defineEmits(['succeeded']);
const modalBase = ref();
const acceptanceProtocolGenerationFormRef = ref();
const acceptanceProtocolGenerationFormKey = ref(0);

function show() {
  modalBase.value.show();
  acceptanceProtocolGenerationFormKey.value++;
}

function onReportSuccessfullyGenerated() {
  modalBase.value.hide();
  emit('succeeded');
}

function submitExternal() {
  acceptanceProtocolGenerationFormRef.value?.requestSubmit();
}

defineExpose({ show });
</script>

<template>
  <ModalBase ref="modalBase">
    <ModalBody>
      <template v-slot:title> Generowanie protokołu odbioru </template>

      <template v-slot:body>
        <AcceptanceProtocolGenerationForm
          ref="acceptanceProtocolGenerationFormRef"
          v-if="acceptanceProtocolGenerationFormKey > 0"
          :key="acceptanceProtocolGenerationFormKey"
          :orderId="props.orderId"
          @succeeded="onReportSuccessfullyGenerated"
        />
      </template>

      <template v-slot:footer>
        <button type="button" class="btn btn-outline btn-info" @click="modalBase.hide">
          Zamknij
        </button>

        <button type="button" @click="submitExternal" class="btn btn-info">Generuj</button>
      </template>
    </ModalBody>
  </ModalBase>
</template>

<style scoped></style>
