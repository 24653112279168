import { ref } from 'vue';
import { getMarker } from '../../helpers/markersCreator';
import L from 'leaflet';
import { isNotEmptyArray } from '../../helpers/utilsHelper';

export function useFeatureGroups(map, features) {
  const featureGroups = ref([]);

  function getFeatureGroupWithKey(key) {
    return featureGroups.value.find((group) => group.key === key)?.group;
  }

  function createAdditionalMarker(groupKey, markerData, onMarkerClick, onMarkerMoveEnd) {
    const newMarker = getMarker(markerData, onMarkerClick, onMarkerMoveEnd);

    const foundFeatureGroup = getFeatureGroupWithKey(groupKey);

    if (foundFeatureGroup) {
      foundFeatureGroup.addLayer(newMarker);
    } else {
      const newFeatureGroup = L.featureGroup([newMarker]);
      features.value.leafletObject.addLayer(newFeatureGroup);
      featureGroups.value.push({ key: groupKey, group: newFeatureGroup });
    }

    return newMarker;
  }

  function createAdditionalMarkerWithConnection(
    groupKey,
    markerData,
    onMarkerClick,
    onMarkerMoveEnd,
    markerToConnectTo,
    fitConnectionBounds
  ) {
    const additionalMarker = createAdditionalMarker(
      groupKey,
      markerData,
      onMarkerClick,
      onMarkerMoveEnd
    );
    const polyline = getPolylineBetweenMarkers(additionalMarker, markerToConnectTo);

    getFeatureGroupWithKey(groupKey).addLayer(polyline);

    if (fitConnectionBounds) {
      map.value.leafletObject.fitBounds(polyline.getBounds());
    }
  }

  // returns true if removed
  function removeFeatureGroupWithKey(groupKey) {
    const foundFeatureGroup = getFeatureGroupWithKey(groupKey);

    if (foundFeatureGroup) {
      features.value.leafletObject.removeLayer(foundFeatureGroup);
      const index = featureGroups.value.findIndex((group) => group.key === groupKey);
      featureGroups.value.splice(index, 1);
      return true;
    }

    return false;
  }

  function getPolylineBetweenMarkers(marker1, marker2) {
    if (marker1?.data?.lng == null || marker2?.data?.lng == null) return;

    const latlngs = [
      [marker1.data.lat, marker1.data.lng],
      [marker2.data.lat, marker2.data.lng]
    ];

    return L.polyline(latlngs, { color: 'red' });
  }

  function removeAllFeatureGroups() {
    if (!isNotEmptyArray(featureGroups.value)) return;

    featureGroups.value.forEach((featureGroup) => {
      features.value.leafletObject.removeLayer(featureGroup.group);
    });

    featureGroups.value = [];
  }

  return {
    createAdditionalMarker,
    removeFeatureGroupWithKey,
    createAdditionalMarkerWithConnection,
    removeAllFeatureGroups
  };
}
