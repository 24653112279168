<script setup>
import { ref, computed } from 'vue';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import { createToaster } from '@meforma/vue-toaster';
import { handleResponseWithMessages } from '@/helpers/wwwHelper.js';

const RepositoryDictionaries = RepositoryFactory.get('dictionaries');
const RepositoryOrders = RepositoryFactory.get('orders');

const toaster = createToaster({ position: 'top-right', duration: 3000 });
const props = defineProps({ orderId: Number });
const emit = defineEmits(['dataSent']);
const isLoading = ref(false);
const demElemTypes = ref(null);
const locationsFormData = ref({
  pickedDemElemTypeId: null,
  nameColumn: null,
  latColumn: null,
  lonColumn: null,
  commentsColumn: null
});

const demElemTypesAvailable = computed(
  () => Array.isArray(demElemTypes.value?.data) && demElemTypes.value.data.length > 0
);

async function fetchDemElemTypes() {
  isLoading.value = true;

  RepositoryDictionaries.getDictionaryElementsObject(1, 100, '', '', '', 'demelemtype')
    .then((data) => {
      demElemTypes.value = data;
    })
    .catch((error) => console.log(error))
    .finally(() => {
      isLoading.value = false;
    });
}

function submitForm(e) {
  e.preventDefault();

  const { correct, rows } = validateFormData();

  if (correct) {
    console.log(rows);
    sendLocations(rows.nameTextRows, rows.latTextRows, rows.lonTextRows, rows.commentsTextRows);
  }
}

function validateFormData() {
  if (!validateDataPresence()) return { correct: false, rows: null };

  let nameTextRows = locationsFormData.value.nameColumn.split('\n').filter((x) => x.trim() !== '');
  let latTextRows = locationsFormData.value.latColumn
    .split('\n')
    .map((x) => x.replaceAll(',', '.'))
    .filter((x) => x.trim() !== '');
  let lonTextRows = locationsFormData.value.lonColumn
    .split('\n')
    .map((x) => x.replaceAll(',', '.'))
    .filter((x) => x.trim() !== '');
  let commentsTextRows = locationsFormData.value.nameColumn
    .split('\n')
    .filter((x) => x.trim() !== '');

  // validate length
  if (
    nameTextRows.length !== latTextRows.length ||
    nameTextRows.length !== lonTextRows.length ||
    nameTextRows.length !== commentsTextRows.length
  ) {
    toaster.show('Liczba wierszy w poszczególnych polach różni się', { type: 'warning' });
    return { correct: false, rows: null };
  }

  // validate names duplications
  if (new Set(nameTextRows).size !== nameTextRows.length) {
    toaster.show('Powtórzenia w oznaczeniach', { type: 'warning' });
    return { correct: false, rows: null };
  }

  // validate type
  if (
    latTextRows.find((lat) => lat == null || isNaN(lat)) !== undefined ||
    lonTextRows.find((lon) => lon == null || isNaN(lon)) !== undefined
  ) {
    toaster.show('Nieprawidłowy format danych', { type: 'warning' });
    return { correct: false, rows: null };
  }

  return { correct: true, rows: { nameTextRows, latTextRows, lonTextRows, commentsTextRows } };
}

function validateDataPresence() {
  if (
    locationsFormData.value.nameColumn == null ||
    locationsFormData.value.nameColumn === '' ||
    locationsFormData.value.latColumn == null ||
    locationsFormData.value.latColumn === '' ||
    locationsFormData.value.lonColumn == null ||
    locationsFormData.value.lonColumn === ''
  ) {
    toaster.show('Uzupełnij wymagane pola (oznaczenie i współrzędne geograficzne).', {
      type: 'warning'
    });
    return false;
  }

  return true;
}

async function sendLocations(nameRows, latRows, lonRows, commentsRows) {
  let objRows = [];

  for (let index = 0; index < nameRows.length; index++) {
    let objRow = {
      oznaczenie: nameRows[index],
      lat: Number.parseFloat(latRows[index]),
      lon: Number.parseFloat(lonRows[index]),
      comment: commentsRows[index]
    };

    objRows.push(objRow);
  }

  var response = await RepositoryOrders.addOrderLocations(
    props.orderId,
    locationsFormData.value.pickedDemElemTypeId,
    objRows
  );

  handleResponseWithMessages(response, 'Dane zostały przesłane.', () => {
    emit('dataSent');

    locationsFormData.value.nameColumn = '';
    locationsFormData.value.latColumn = '';
    locationsFormData.value.lonColumn = '';
    locationsFormData.value.commentsColumn = '';
  });
}

// created
fetchDemElemTypes();
</script>

<template>
  <div class="ibox">
    <div
      class="ibox-content"
      :class="{ 'sk-loading': isLoading }"
      style="border-style: none; background-color: inherit"
    >
      <div class="sk-spinner sk-spinner-three-bounce">
        <div class="sk-bounce1"></div>
        <div class="sk-bounce2"></div>
        <div class="sk-bounce3"></div>
      </div>

      <form role="form" @submit="submitForm">
        <div class="row">
          <div class="form-group">
            <label>Zakres/typ</label>
            <select
              class="form-control m-b"
              v-if="demElemTypesAvailable"
              v-model="locationsFormData.pickedDemElemTypeId"
              required
            >
              <option disabled :value="null">Wybierz zakres/typ</option>
              <option
                v-for="demElemType in demElemTypes.data"
                :key="demElemType.id"
                :value="demElemType.id"
              >
                {{ demElemType.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="row">
          <div class="col-3 p-1">
            <h5 class="text-center mb-3">Oznaczenie</h5>
            <textarea
              style="width: 100%; height: 100px"
              placeholder="Wklej zawartość kolumny z oznaczeniem"
              v-model="locationsFormData.nameColumn"
            ></textarea>
          </div>

          <div class="col-3 p-1">
            <h5 class="text-center mb-3">Szerokość geograficzna</h5>
            <textarea
              style="width: 100%; height: 100px"
              placeholder="Wklej zawartość kolumny z szerokością geograficzną"
              v-model="locationsFormData.latColumn"
            ></textarea>
          </div>

          <div class="col-3 p-1">
            <h5 class="text-center mb-3">Długość geograficzna</h5>
            <textarea
              style="width: 100%; height: 100px"
              placeholder="Wklej zawartość kolumny z długością geograficzną"
              v-model="locationsFormData.lonColumn"
            ></textarea>
          </div>

          <div class="col-3 p-1">
            <h5 class="text-center mb-3">Uwagi</h5>
            <textarea
              style="width: 100%; height: 100px"
              placeholder="Wklej zawartość kolumny z uwagami"
              v-model="locationsFormData.commentsColumn"
            ></textarea>
          </div>
        </div>

        <div class="row mb-2">
          <div class="col-12">
            <button type="submit" class="btn btn-primary mt-2" style="float: right">
              Prześlij
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
