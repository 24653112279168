<script setup>
import { ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import { PAGINATION_LIMIT } from '../../data/constants/appConstants.js';
import ServerErrorPanel from '@/components/utils/ServerErrorPanel.vue';
import TableElementsInfo from '@/components/utils/TableElementsInfo.vue';
import PaginationCountPicker from '@/components/utils/PaginationCountPicker.vue';
import { usePaginationStore } from '@/stores/storePagination';
import { Bootstrap4Pagination } from 'laravel-vue-pagination';
import { ORDERS_TABLE_FOR_MAP_PAGINATION_KEY } from '../../data/paginationInjectionKeys';

const RepositoryOrders = RepositoryFactory.get('orders');

const props = defineProps({
  selectedOrdersIds: { type: Array, default: () => [] }
});

const router = useRouter();
const isLoading = ref(false);
const ordersObject = ref({});
const searchBox = ref('');
const currentSort = ref({ by: null, direction: null });
const userFilter = ref(null);
const paginationStore = usePaginationStore();

watch(
  () => props.selectedOrdersIds,
  () => fetchData()
);

function fetchData(page = 1) {
  isLoading.value = true;

  RepositoryOrders.getOrdersForMapObjectByOrdersIds(
    page,
    paginationStore.getPaginationItemsCount(ORDERS_TABLE_FOR_MAP_PAGINATION_KEY),
    searchBox.value,
    currentSort.value.by,
    currentSort.value.direction,
    userFilter.value,
    props.selectedOrdersIds
  )
    .then((data) => {
      ordersObject.value = data;
    })
    .catch((error) => console.log(error))
    .finally(() => {
      isLoading.value = false;
      //eslint-disable-next-line no-undef
      $('[data-toggle="tooltip"]').tooltip({ placement: 'right' });
    });
}

function showDetails(order) {
  let routeData = router.resolve({ name: 'orderDetails', params: { id: order.id } });
  window.open(routeData.href, '_blank');
}

function edit(order) {
  router.push({ name: 'orderAddEdit', params: { id: order.id } });
}

function getTarget(order) {
  if (!order?.target) return '-';

  let target = '';

  for (const property in order.target) {
    target += property + ': ' + order.target[property] + '\n';
  }

  return target;
}

function onItemsPerPageChanged() {
  fetchData();
}
</script>

<template>
  <div class="ibox">
    <div class="ibox-content p-0" :class="{ 'sk-loading': isLoading }" style="border-style: none">
      <div class="sk-spinner sk-spinner-three-bounce">
        <div class="sk-bounce1"></div>
        <div class="sk-bounce2"></div>
        <div class="sk-bounce3"></div>
      </div>

      <TableElementsInfo :metaObject="ordersObject?.meta ?? null" />

      <div v-if="ordersObject?.data && ordersObject.data.length > 0" class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Nr</th>
              <th>Liczba elementów</th>
              <th>Pozostało do zdemontowania</th>
              <th>Status zlecenia</th>
              <th>Rodzaj zlecenia</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="order in ordersObject.data"
              :key="order.id"
              @click="showDetails(order)"
              style="cursor: pointer"
            >
              <td style="white-space: nowrap">
                {{ order?.order_number ?? '-' }}
              </td>
              <td style="white-space: pre">
                {{ getTarget(order) }}
              </td>
              <td style="white-space: nowrap">
                {{ order?.left ?? '-' }}
              </td>
              <td style="white-space: nowrap; text-align: center">
                <span
                  class="label label-primary"
                  :style="
                    order?.order_status?.color != null
                      ? 'background-color:' + order.order_status.color
                      : ''
                  "
                >
                  {{ order?.order_status?.name ?? '-' }}</span
                >
              </td>
              <td style="white-space: nowrap; text-align: center">
                <span
                  class="label label-primary"
                  :style="
                    order?.direction?.color != null
                      ? 'background-color:' + order.direction.color
                      : ''
                  "
                >
                  {{ order?.direction?.name ?? '-' }}</span
                >
              </td>
              <td style="white-space: nowrap">
                <div class="float-right">
                  <button
                    class="btn btn-outline btn-primary btn-xs mr-2"
                    type="button"
                    @click="showDetails(order)"
                    onclick="event.stopPropagation()"
                  >
                    Pokaż
                  </button>
                  <button
                    class="btn btn-outline btn-primary btn-xs"
                    type="button"
                    @click="edit(order)"
                    onclick="event.stopPropagation()"
                  >
                    Edytuj
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else-if="ordersObject?.error">
        <ServerErrorPanel @onRetry="fetchData" />
      </div>
      <div v-else>
        <p class="text-center mt-3">Brak elementów do wyświetlenia</p>
      </div>

      <div class="row mt-3">
        <div class="col-xl-6">
          <Bootstrap4Pagination
            v-if="ordersObject?.data"
            align="center"
            :data="ordersObject"
            :limit="PAGINATION_LIMIT"
            @pagination-change-page="fetchData"
          ></Bootstrap4Pagination>
        </div>

        <div class="col-xl-6">
          <PaginationCountPicker
            :paginationKey="ORDERS_TABLE_FOR_MAP_PAGINATION_KEY"
            v-show="!isLoading"
            @selectionChanged="onItemsPerPageChanged"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
