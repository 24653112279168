<script setup>
/* eslint-env jquery */
import { ref, watch, nextTick } from 'vue';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import FormErrorPanel from '../utils/FormErrorPanel.vue';
import { isNotEmptyArray } from '../../helpers/utilsHelper';
import { getDateWithoutTime } from '../../helpers/dateFormatHelper';
import { createToaster } from '@meforma/vue-toaster';
import { TOASTER_SERVER_ERROR_MSG } from '../../data/constants/toasterMessagesConstants';

const RepositoryAcceptanceProtocols = RepositoryFactory.get('acceptanceProtocols');

const props = defineProps({ orderId: Number });

const toaster = createToaster({ position: 'top-right', duration: 3000 });
const isLoading = ref(false);
const formRef = ref();
const formInfo = ref(null);
const formErrors = ref([]);
const poTypes = ref([]);
const commentsTypes = ref([]);
const emit = defineEmits(['succeeded']);

watch(formInfo, async (newValue, oldValue) => {
  if (oldValue == null && newValue != null) {
    await nextTick();
    const datepickerConfig = {
      todayBtn: 'linked',
      keyboardNavigation: true,
      forceParse: true,
      calendarWeeks: true,
      autoclose: true,
      weekStart: 1
    };

    $('#reportCreationDate .input-group.date').datepicker(datepickerConfig);
    $(document.body).on(
      'change',
      'input[id^="reportCreationDateId"]',
      updateReportCreationDateFromPicker
    );
  }
});

async function fetchRaportPreSummary() {
  isLoading.value = true;

  formInfo.value = await RepositoryAcceptanceProtocols.getRaportPreSummary(props.orderId);

  if (formInfo.value.raport_creation_date != null) {
    formInfo.value.raport_creation_date = getDateWithoutTime(formInfo.value.raport_creation_date);
    $('#reportCreationDate .input-group.date').datepicker(
      'setDate',
      getDateWithoutTime(formInfo.value.raport_creation_date)
    );
  }

  poTypes.value = formInfo.value.dictionary_type;
  commentsTypes.value = formInfo.value.dictionary_h;
  formInfo.value.ln_present = formInfo.value.ln_present === 1;
  formInfo.value.po_type = null;
  formInfo.value.comments_type = null;

  isLoading.value = false;
}

function updateReportCreationDateFromPicker() {
  formInfo.value.raport_creation_date = document.getElementById('reportCreationDateId').value;
}

function requestSubmit() {
  formRef.value.requestSubmit();
}

function submitForm(e) {
  e.preventDefault();

  sendRequest();
}

async function sendRequest() {
  isLoading.value = true;

  formInfo.value.ln_present = formInfo.value.ln_present ? 1 : 0;

  var response = await RepositoryAcceptanceProtocols.generateReport(props.orderId, formInfo.value);

  if (response?.serverError) {
    toaster.show(TOASTER_SERVER_ERROR_MSG, { type: 'error' });
  } else {
    if (response) {
      if (response.id != null) {
        toaster.show('Protokół został wygenerowany.', { type: 'success' });
        emit('succeeded');
      } else formErrors.value = response;
    }
  }

  isLoading.value = false;
}

fetchRaportPreSummary();

defineExpose({ requestSubmit });
</script>

<template>
  <div class="ibox">
    <div class="ibox-content" :class="{ 'sk-loading': isLoading }" style="border-style: none">
      <div class="sk-spinner sk-spinner-three-bounce">
        <div class="sk-bounce1"></div>
        <div class="sk-bounce2"></div>
        <div class="sk-bounce3"></div>
      </div>

      <FormErrorPanel :errors="formErrors" />

      <form ref="formRef" v-if="formInfo" @submit="submitForm">
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label>Numer zlecenia</label>
              <input type="text" v-model="formInfo.order_number" disabled class="form-control" />
            </div>

            <div class="form-group">
              <label>EBP</label>
              <input type="text" v-model="formInfo.ebp" disabled class="form-control" />
            </div>

            <div class="form-group" id="reportCreationDate">
              <label>Data utworzenia raportu</label>
              <div class="input-group date">
                <span class="input-group-addon" :style="'background-color: #e9ecef'"
                  ><i class="fa fa-calendar"></i></span
                ><input
                  id="reportCreationDateId"
                  type="text"
                  class="form-control"
                  v-model="formInfo.raport_creation_date"
                  required
                />
              </div>
            </div>

            <div class="form-group mt-2">
              <label>Dla protokołu</label>
              <select class="form-control m-b" v-model="formInfo.po_type" required>
                <option disabled :value="null" label="Wybierz typ" />
                <option v-for="poType in poTypes" :key="poType" :value="poType">
                  {{ poType }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label>Typ uwag</label>
              <select class="form-control m-b" v-model="formInfo.comments_type" required>
                <option disabled :value="null" label="Wybierz typ" />
                <option
                  v-for="commentsType in commentsTypes"
                  :key="commentsType"
                  :value="commentsType"
                >
                  {{ commentsType }}
                </option>
              </select>
            </div>

            <div class="form-group">
              <label>Uwagi dla miejsca składowania</label>
              <textarea
                type="text"
                placeholder="Wpisz uwagi"
                v-model="formInfo.collection_text"
                class="form-control"
                maxlength="500"
                style="height: 114px"
              />
            </div>

            <div class="form-group">
              <label>Informacja o kablach z LN</label>
              <div class="switch">
                <div class="onoffswitch">
                  <input
                    type="checkbox"
                    class="onoffswitch-checkbox"
                    id="lnPresentId"
                    v-model="formInfo.ln_present"
                  />
                  <label class="onoffswitch-label" style="margin-top: 8px" for="lnPresentId">
                    <span class="onoffswitch-inner"></span>
                    <span class="onoffswitch-switch"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="isNotEmptyArray(formInfo?.addresses)" class="table-responsive mt-3">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Adres</th>
                <th>Zakres</th>
                <th>Ilość zlecona</th>
                <th>Ilość zrealizowana</th>
                <th>Uwagi/załączniki</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="address in formInfo.addresses" :key="address">
                <td>
                  {{ address.address ?? '-' }}
                </td>

                <td>
                  {{ address.order_range ?? '-' }}
                </td>

                <td>
                  {{ address.quantity != null ? address.quantity : '-' }}
                </td>

                <td>
                  <input
                    type="number"
                    @mousewheel.prevent=""
                    placeholder="Wpisz ilość"
                    v-model="address.quantity_fullfilled"
                    class="form-control"
                    min="0"
                    required
                  />
                </td>

                <td>
                  <input
                    type="text"
                    @mousewheel.prevent=""
                    placeholder="Wpisz uwagi/załączniki"
                    v-model="address.attachment"
                    class="form-control"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div v-else>
          <p class="text-center mt-3">Brak adresów</p>
        </div>
      </form>
    </div>
  </div>
</template>

<style scoped></style>
