<script setup>
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import ClusterMap from '@/components/map/ClusterMap.vue';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import ServerErrorPanel from '@/components/utils/ServerErrorPanel.vue';
import { copyToClipboard, isNotEmptyArray, pushSafe } from '../../helpers/utilsHelper';
import TransportMapSummaryPanel from './TransportMapSummaryPanel.vue';
import {
  COPY_TO_CLIPBOARD_BUTTON_TYPE_NAME,
  SHOW_COORDS_ON_MAP_BUTTON_TYPE_NAME
} from '../../data/constants/buttonsNamesConstants';
import { useMapLogic } from '../../composables/mapLogic';
import { ClusterGroupsSettings } from '../../data/models/ClusterGroupsSettings';
import TransportOrdersMapMarkersFilters from './TransportOrdersMapMarkersFilters.vue';
import { showOnGoogleMaps } from '../../helpers/navigator';

const RepositoryTransportOrders = RepositoryFactory.get('transportOrders');
const STORAGE_TYPES_GROUP_1 = [2, 3, 4]; // "słup drewniany", "słup betonowy", "szczudło"
const STORAGE_TYPES_GROUP_2 = [1, 5, 6]; // "szafa", "fundament", "inne"
const USER_ORDER_TRANSPORT_VERIFICATION_STATUS_ID = 3;

const props = defineProps({
  filterFromParent: { type: Object, default: null },
  searchFromParent: { type: String, default: '' }
});

const emit = defineEmits(['afterSuccessfulFetch']);
const isLoading = ref(false);
const ordersObject = ref({});
const router = useRouter();
const { clusterMap, onSelectedMarkersChanged, onSingleMarkerSelected, resetSelectedIfCtrl } =
  useMapLogic();

const showPlacesFilter = ref(true);
const showPriorityPlacesFilter = ref(true);
const showOrdersFilter = ref(true);
const showPriorityOrdersFilter = ref(true);
const showOrdersWithAdditionalStatusFilter = ref(true);

const selectedPlacesIds = computed(() => {
  if (!isNotEmptyArray(clusterMap.value?.markersRef)) return [];

  let result = [];

  clusterMap.value.markersRef.forEach((marker) => {
    if (marker.data.selected) {
      result.push(marker.options.placeId);
    }
  });

  return result;
});

const markersDataAvailable = computed(() => {
  return isNotEmptyArray(ordersObject.value?.places) || isNotEmptyArray(ordersObject.value?.orders);
});

function fetchData(page = 1, setBoundsAfterRefresh = true) {
  isLoading.value = true;

  RepositoryTransportOrders.getTransportOrdersForMapObject(
    page,
    1000,
    props.searchFromParent ?? '',
    null,
    null,
    props.filterFromParent
  )
    .then((data) => {
      ordersObject.value = data;
      prepareMarkersForMap(setBoundsAfterRefresh);
      emit('afterSuccessfulFetch');
    })
    .catch((error) => console.log(error))
    .finally(() => {
      isLoading.value = false;
    });
}

function prepareMarkersForMap(setBoundsAfterRefresh = false) {
  const markers = new Array();
  const priorityOrAdditionalStatusClusterGroupId = 0;

  if (isNotEmptyArray(ordersObject.value?.places)) {
    ordersObject.value?.places.forEach((place) => {
      if (!shouldShowPlaceMarker(place)) return;

      let lat = place?.geotimestamp?.lat;
      let lon = place?.geotimestamp?.lon;

      let markerPopupCaption =
        `${lat}, ${lon} ` +
        `<button clickable type='button' class="btn btn-white btn-xs ml-1" id='${COPY_TO_CLIPBOARD_BUTTON_TYPE_NAME}:place:${place.id}' lat='${lat}' lon='${lon}' click_action_type='${COPY_TO_CLIPBOARD_BUTTON_TYPE_NAME}'><i class="fa fa-copy" clickable_child></i></button>` +
        `<button clickable type='button' class="btn btn-white btn-xs ml-1" id='${SHOW_COORDS_ON_MAP_BUTTON_TYPE_NAME}:place:${place.id}' lat='${lat}' lon='${lon}' click_action_type='${SHOW_COORDS_ON_MAP_BUTTON_TYPE_NAME}'><i class="fa fa-google" clickable_child></i></button>`;

      let marker = {
        lat: lat,
        lng: lon,
        popupCaption: markerPopupCaption,
        draggable: false,
        markerId: 'place' + place.id,
        options: { type: 'place', placeId: place.id },
        selected: false,
        selectable: true
      };

      marker = Object.assign(marker, getIconForPlaceMarker(place));

      if (place.priority) {
        marker.clusterGroupId = priorityOrAdditionalStatusClusterGroupId;
      }

      markers.push(marker);
    });
  }

  if (isNotEmptyArray(ordersObject.value?.orders)) {
    ordersObject.value?.orders.forEach((order) => {
      if (!shouldShowOrderMarker(order)) return;

      let lat = order?.position?.lat;
      let lon = order?.position?.lon;

      let markerPopupCaption =
        `<div style='display: flex; flex-direction: column; row-gap: 5px;'><div>${lat}, ${lon} ` +
        `<button clickable type='button' class="btn btn-white btn-xs ml-1" id='${COPY_TO_CLIPBOARD_BUTTON_TYPE_NAME}:order:${order.id}' lat='${lat}' lon='${lon}' click_action_type='${COPY_TO_CLIPBOARD_BUTTON_TYPE_NAME}'><i class="fa fa-copy" clickable_child></i></button>` +
        `<button clickable type='button' class="btn btn-white btn-xs ml-1" id='${SHOW_COORDS_ON_MAP_BUTTON_TYPE_NAME}:order:${order.id}' lat='${lat}' lon='${lon}' click_action_type='${SHOW_COORDS_ON_MAP_BUTTON_TYPE_NAME}'><i class="fa fa-google" clickable_child></i></button></div>` +
        `<button clickable type='button' class='btn btn-outline-info btn-xs' id='showOrderButton:${order.id}' id_order='${order.id}'>Pokaż zlecenie</button></div>`;

      let marker = {
        lat: lat,
        lng: lon,
        popupCaption: markerPopupCaption,
        draggable: false,
        markerId: 'order' + order.id,
        options: { type: 'order', placeId: order.id_action },
        selected: false,
        selectable: true
      };

      marker = Object.assign(marker, getIconForOrderMarker(order));

      if (order.priority || order.additionalStatus != null) {
        marker.clusterGroupId = priorityOrAdditionalStatusClusterGroupId;
      }

      assignShadowForOrderMarker(marker, order);
      markers.push(marker);
    });
  }

  const clustersGroupSettings = new ClusterGroupsSettings();
  clustersGroupSettings.add(priorityOrAdditionalStatusClusterGroupId, {
    disableClusteringAtZoom: 0
  });

  clusterMap.value.initializeMap({
    markersData: markers,
    clusterGroupsSettingsOverride: clustersGroupSettings,
    setBoundsAfterRefresh
  });
}

function shouldShowPlaceMarker(place) {
  if (!showPlacesFilter.value && !showPriorityPlacesFilter.value) return false;
  if (!showPlacesFilter.value && showPriorityPlacesFilter.value) return place.priority;
  if (showPlacesFilter.value && !showPriorityPlacesFilter.value) return !place.priority;

  return true;
}

function shouldShowOrderMarker(order) {
  const all = showOrdersFilter.value;
  const priority = showPriorityOrdersFilter.value;
  const additionalStatus = showOrdersWithAdditionalStatusFilter.value;

  if (!all && !priority && !additionalStatus) return false;
  if (!all && !priority) return order.additionalStatus != null;
  if (!all && !additionalStatus) return order.priority;
  if (!priority && !additionalStatus) return !order.priority && order.additionalStatus == null;
  if (!all) return order.priority || order.additionalStatus != null;
  if (!priority) return !order.priority;
  if (!additionalStatus) return order.additionalStatus == null;

  return true;
}

function getIconForPlaceMarker(place) {
  if (place.priority) {
    return {
      icon: 'exclamation_mark_circle_icon',
      iconSize: [26, 26],
      iconAnchor: [13, 13]
    };
  }

  const assignedGroupOfStorageTypes = assignStorageTypesToTheGroup(place.dem_storages);

  if (assignedGroupOfStorageTypes === 1) {
    return {
      icon: place.after_deadline ? 'circle_red' : 'circle_green',
      iconSize: [21.5, 19.5],
      iconAnchor: [10.75, 9.75]
    };
  }

  if (assignedGroupOfStorageTypes === 2) {
    return {
      icon: place.after_deadline ? 'square_red' : 'square_green',
      iconSize: [20, 20],
      iconAnchor: [10, 10]
    };
  }

  if (assignedGroupOfStorageTypes === 0) {
    return {
      icon: place.after_deadline ? 'star_marker_red' : 'star_marker_green',
      iconSize: [20, 20],
      iconAnchor: [10, 10]
    };
  }

  return {
    icon: place.after_deadline ? 'red_marker' : 'green_marker',
    iconSize: [12, 20],
    iconAnchor: [6, 20]
  };
}

// returns:
// -1 - sth is not right,
// 0 - mixed,
// 1 - group 1,
// 2 - group 2
function assignStorageTypesToTheGroup(storageTypes) {
  if (!isNotEmptyArray(storageTypes)) return -1;

  const containsGroup1Elems = storageTypes.some((elem) =>
    STORAGE_TYPES_GROUP_1.includes(elem.id_storage_type)
  );

  const containsGroup2Elems = storageTypes.some((elem) =>
    STORAGE_TYPES_GROUP_2.includes(elem.id_storage_type)
  );

  if (containsGroup1Elems) {
    if (containsGroup2Elems) return 0;
    else return 1;
  } else if (containsGroup2Elems) return 2;

  return -1;
}

function getIconForOrderMarker(order) {
  return order.is_order_completed === 1
    ? getIconForCompletedOrderMarker(order)
    : getIconForNotCompletedOrderMarker(order);
}

function getIconForCompletedOrderMarker(order) {
  if (isAnyDriverWaitingForVerification(order)) {
    return {
      icon: 'yellow_marker',
      iconSize: [12, 20],
      iconAnchor: [6, 20]
    };
  }

  // if last_worker_left equals null or is empty or contains only zeros
  if (
    !isNotEmptyArray(order.last_worker_left) ||
    order.last_worker_left.every((el) => el.number === 0)
  ) {
    return {
      icon: 'blue_marker',
      iconSize: [12, 20],
      iconAnchor: [6, 20]
    };
  }

  if (order.additionalStatus != null) {
    return {
      icon: 'no_entering',
      iconSize: [26, 26],
      iconAnchor: [13, 13]
    };
  }

  if (order.priority === 1) {
    return {
      icon: 'exclamation_mark_circle_icon',
      iconSize: [26, 26],
      iconAnchor: [13, 13]
    };
  }

  const assignedGroupOfStorageTypes = assignStorageTypesToTheGroup(order?.last_worker_left);

  if (assignedGroupOfStorageTypes === 1) {
    return {
      icon: order.after_deadline ? 'circle_red' : 'circle_green',
      iconSize: [21.5, 19.5],
      iconAnchor: [10.75, 9.75]
    };
  }

  if (assignedGroupOfStorageTypes === 2) {
    return {
      icon: order.after_deadline ? 'square_red' : 'square_green',
      iconSize: [20, 20],
      iconAnchor: [10, 10]
    };
  }

  if (assignedGroupOfStorageTypes === 0) {
    return {
      icon: order.after_deadline ? 'star_marker_red' : 'star_marker_green',
      iconSize: [20, 20],
      iconAnchor: [10, 10]
    };
  }

  return {
    icon: order.after_deadline ? 'red_marker' : 'green_marker',
    iconSize: [12, 20],
    iconAnchor: [6, 20]
  };
}

function getIconForNotCompletedOrderMarker(order) {
  if (areThereMultipleActiveDrivers(order.drivers)) {
    return {
      icon: 'star_marker_orange',
      iconSize: [20, 20],
      iconAnchor: [10, 10]
    };
  }

  const lastActiveDriversColor = getColorOfLastActiveDriver(order);
  const borderColor = lastActiveDriversColor ? 'black' : 'white';
  const fillColor = lastActiveDriversColor ?? 'black';

  return {
    divIcon: {
      className: '',
      iconSize: [16, 16],
      iconAnchor: [8, 8],
      html: `
      <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
        <circle cx="10" cy="10" r="8" stroke="${borderColor}" stroke-width="1" fill="${fillColor}" />
      </svg>
      `
    }
  };
}

function assignShadowForOrderMarker(marker, order) {
  if (marker.iconSize == null || marker.iconAnchor == null) return null;
  if (order.priority !== 1) return null;

  const size = [5.25, 15.375];

  marker.shadows = pushSafe(marker.shadows, {
    url: 'exclamation_shadow',
    size: size,
    topPx: -size[1],
    leftPx: marker.iconSize[0]
  });
}

function isAnyDriverWaitingForVerification(order) {
  if (!isNotEmptyArray(order?.drivers)) return false;

  return order.drivers.some(
    (driver) =>
      driver.id_user_order_transport_status === USER_ORDER_TRANSPORT_VERIFICATION_STATUS_ID
  );
}

function getColorOfLastActiveDriver(order) {
  if (!isNotEmptyArray(order?.drivers)) return null;

  const lastActiveDriver = order.drivers.findLast(
    (driver) => driver.id_user_order_transport_status === 1
  );

  return lastActiveDriver?.color;
}

function areThereMultipleActiveDrivers(drivers) {
  if (drivers.length < 2) return false;

  const activeDrivers = drivers.filter((driver) => driver.id_user_order_transport_status === 1);

  const uniqueActiveDriversCount = activeDrivers.reduce((acc, current) => {
    if (!acc.includes(current.id)) {
      acc.push(current.id);
    }
    return acc;
  }, []).length;

  return uniqueActiveDriversCount > 1;
}

function onMapClickableClicked(clickable) {
  let orderId = clickable.getAttribute('id_order');

  let clickActionType = clickable.getAttribute('click_action_type');
  const lat = clickable.getAttribute('lat');
  const lon = clickable.getAttribute('lon');

  switch (clickActionType) {
    case COPY_TO_CLIPBOARD_BUTTON_TYPE_NAME:
      if (lat != undefined && lon != undefined) {
        copyToClipboard(lat + ',' + lon);
      }
      break;
    case SHOW_COORDS_ON_MAP_BUTTON_TYPE_NAME:
      if (lat != undefined && lon != undefined) {
        showOnGoogleMaps(lat, lon);
      }
      break;
  }

  if (Number.isInteger(parseInt(orderId))) {
    let routeData = router.resolve({ name: 'transportOrderDetails', params: { id: orderId } });
    window.open(routeData.href, '_blank');
  }
}

function forceFetch(setBoundsAfterRefresh = false) {
  fetchData(1, setBoundsAfterRefresh);
}

function refreshMap() {
  setTimeout(() => {
    clusterMap.value?.refreshMapAfterTick(false);
  }, 100);
}

function onShowPlacesFilterChanged(updatedShowPlacesFilter) {
  showPlacesFilter.value = updatedShowPlacesFilter;
  prepareMarkersForMap();
}

function onShowPriorityPlacesFilterChanged(updatedShowPriorityPlacesFilter) {
  showPriorityPlacesFilter.value = updatedShowPriorityPlacesFilter;
  prepareMarkersForMap();
}

function onShowOrdersFilterChanged(updatedShowOrdersFilter) {
  showOrdersFilter.value = updatedShowOrdersFilter;
  prepareMarkersForMap();
}

function onShowPriorityOrdersFilterChanged(updatedShowPriorityOrdersFilter) {
  showPriorityOrdersFilter.value = updatedShowPriorityOrdersFilter;
  prepareMarkersForMap();
}

function onShowOrdersWithAdditionalStatusFilterChanged(
  updatedShowOrdersWithAdditionalStatusFilter
) {
  showOrdersWithAdditionalStatusFilter.value = updatedShowOrdersWithAdditionalStatusFilter;
  prepareMarkersForMap();
}

// created
fetchData();

defineExpose({ forceFetch, refreshMap });
</script>

<template>
  <div class="row">
    <div class="col-lg-8">
      <div class="ibox">
        <div class="ibox-content" :class="{ 'sk-loading': isLoading }" style="border-style: none">
          <div class="sk-spinner sk-spinner-three-bounce">
            <div class="sk-bounce1"></div>
            <div class="sk-bounce2"></div>
            <div class="sk-bounce3"></div>
          </div>

          <ClusterMap
            ref="clusterMap"
            v-show="markersDataAvailable"
            height="700px"
            :areaSelecting="true"
            :disableClusteringAtZoomOverride="19"
            :maxClusterRadiusOverride="1"
            @selected-markers-changed="onSelectedMarkersChanged"
            @map-clicked="resetSelectedIfCtrl"
            @markerClicked="onSingleMarkerSelected"
            @clickableClicked="onMapClickableClicked"
          />
          <div v-if="ordersObject?.error">
            <ServerErrorPanel @onRetry="fetchData" />
          </div>
          <div v-else-if="!markersDataAvailable">
            <p class="text-center mt-3">Brak elementów do wyświetlenia</p>
          </div>

          <TransportOrdersMapMarkersFilters
            @showPlacesFilterChanged="onShowPlacesFilterChanged"
            @showPriorityPlacesFilterChanged="onShowPriorityPlacesFilterChanged"
            @showOrdersFilterChanged="onShowOrdersFilterChanged"
            @showPriorityOrdersFilterChanged="onShowPriorityOrdersFilterChanged"
            @showOrdersWithAdditionalStatusFilterChanged="
              onShowOrdersWithAdditionalStatusFilterChanged
            "
          />
        </div>
      </div>
    </div>

    <div class="col-lg-4 pt-3">
      <slot name="globalFilterPanel"></slot>
      <TransportMapSummaryPanel :selectedPlacesIds="selectedPlacesIds" />
    </div>
  </div>
</template>

<style scoped></style>
