<script setup>
import { computed } from 'vue';
import { useNavigationPanelStore } from '@/stores/navigationPanel';

const navigationPanelStore = useNavigationPanelStore();

const title = computed(() => {
  var items = navigationPanelStore.navigationPanelItems;
  if (!items || items.length <= 0) return '';

  let item = items[items.length - 1];
  let titleToReturn = item.header != undefined ? item.header : item.name;

  return titleToReturn;
});

const titleAppendix = computed(() => {
  var items = navigationPanelStore.navigationPanelItems;
  if (!items || items.length <= 0) return '';

  let item = items[items.length - 1];

  if (item.headerAppendix == null) return '';

  let appendix = item.headerAppendix;

  if (navigationPanelStore.orderNumber != null) {
    appendix = appendix.replace('#orderNumber', navigationPanelStore.orderNumber);
  }

  if (navigationPanelStore.transportOrderNumber != null) {
    appendix = appendix.replace('#transportOrderNumber', navigationPanelStore.transportOrderNumber);
  }

  return appendix;
});

const items = computed(() => {
  return navigationPanelStore.navigationPanelItems;
});
</script>

<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>
        {{ title }}<b>{{ titleAppendix }}</b>
      </h2>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/">Dashboard</router-link>
        </li>

        <li v-for="(item, index) in items" :key="item.name" class="breadcrumb-item">
          <strong v-if="index == items.length - 1">{{ item.name }}</strong>
          <router-link v-else :to="item.link">{{ item.name }}</router-link>
        </li>
      </ol>
    </div>
  </div>
</template>

<style></style>
