import { createRouter, createWebHistory } from 'vue-router';
import { useAuthStore } from '@/stores/auth';

// views
import ViewLogin from '@/views/ViewLogin.vue';
import ViewUsers from '@/views/ViewUsers.vue';
import ViewForgottenPassword from '@/views/ViewForgottenPassword.vue';
import ViewResetPassword from '@/views/ViewResetPassword.vue';
import ViewOrders from '@/views/ViewOrders.vue';
import ViewDictionaries from '@/views/ViewDictionaries.vue';
import MapPointsSharedMap from '@/views/MapPointsSharedMap.vue';
import OrdersRegisterDownload from '@/views/OrdersRegisterDownload.vue';
import ViewTransportOrders from '@/views/ViewTransportOrders.vue';
import SingleActionGallery from '../components/actions/SingleActionGallery.vue';
import OrderActionGallery from '../views/OrderActionGallery.vue';
import ViewActionsRegister from '@/views/ViewActionsRegister.vue';
import ActionsRegisterDownload from '@/views/ActionsRegisterDownload.vue';

// users
import UsersTableView from '@/components/users/UsersTableView.vue';
import UserDetails from '@/components/users/UserDetails.vue';
import UserAddEdit from '@/components/users/UserAddEdit.vue';

// orders
import OrdersTableView from '@/components/orders/OrdersTableView.vue';
import OrderDetails from '@/components/orders/OrderDetails.vue';
import OrderAddEditView from '@/components/orders/OrderAddEditView.vue';
import OrderAddressAddEdit from '@/components/addresses/OrderAddressAddEdit.vue';

// transport orders
import TransportOrdersTableView from '@/components/transport/TransportOrdersTableView.vue';
import TransportOrderDetails from '@/components/transport/TransportOrderDetails.vue';
import TransportOrderAddView from '@/components/transport/TransportOrderAddView.vue';

// actions register
import ActionsRegisterTableView from '@/components/actionsRegister/ActionsRegisterTableView.vue';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/login',
      name: 'login',
      component: ViewLogin
    },
    {
      path: '/users',
      component: ViewUsers,
      children: [
        {
          path: '',
          name: 'usersTable',
          component: UsersTableView
        },
        {
          path: 'details/:id?',
          name: 'userDetails',
          component: UserDetails
        },
        {
          path: 'addEdit/:id?',
          name: 'userAddEdit',
          component: UserAddEdit
        }
      ]
    },
    {
      path: '/orders',
      component: ViewOrders,
      alias: '/',
      children: [
        {
          path: '',
          name: 'ordersTable',
          component: OrdersTableView,
          props: (route) => ({
            status: route.query.status,
            geodesyPhase: route.query.geodesyPhase,
            mode: route.query.mode
          })
        },
        {
          path: 'details/:id?/:actionId?',
          name: 'orderDetails',
          component: OrderDetails
        },
        {
          path: 'addEdit/:id?',
          name: 'orderAddEdit',
          component: OrderAddEditView,
          props(route) {
            return route.query || {};
          }
        },
        {
          path: 'addEditOrderAddress/:orderId/:addressId?',
          name: 'orderAddressAddEdit',
          component: OrderAddressAddEdit,
          props(route) {
            return route.query || {};
          }
        }
      ]
    },
    {
      path: '/transportOrders',
      component: ViewTransportOrders,
      alias: '/archivedTransportOrders',
      children: [
        {
          path: '',
          name: 'transportOrdersTable',
          component: TransportOrdersTableView,
          props: (route) => ({
            mode: route.query.mode,
            archived: route.query.archived
          })
        },
        {
          path: 'details/:id?',
          name: 'transportOrderDetails',
          component: TransportOrderDetails
        },
        {
          path: 'add',
          name: 'transportOrderAdd',
          component: TransportOrderAddView,
          props: (route) => ({
            placesIds: route.query.placesIds
          })
        }
      ]
    },
    {
      path: '/mapPoints/:uuid?',
      name: 'mapPointsSharedMap',
      component: MapPointsSharedMap,
      props(route) {
        return route.query || {};
      }
    },
    {
      path: '/ordersRegisterDownload/:uuid?',
      name: 'ordersRegisterDownload',
      component: OrdersRegisterDownload,
      props(route) {
        return route.query || {};
      }
    },
    {
      path: '/forgotPassword',
      name: 'forgotPassword',
      component: ViewForgottenPassword
    },
    {
      path: '/resetPassword/:token?',
      name: 'resetPassword',
      component: ViewResetPassword
    },
    {
      path: '/dictionaries',
      name: 'dictionaries',
      component: ViewDictionaries
    },
    {
      path: '/singleActionGallery',
      name: 'singleActionGallery',
      component: SingleActionGallery,
      props: (route) => ({
        orderId: route.query.orderId,
        actionId: route.query.actionId,
        actionTypeName: route.query.actionTypeName,
        orderNumber: route.query.orderNumber
      })
    },
    {
      path: '/actionGallery',
      name: 'orderActionGallery',
      component: OrderActionGallery,
      props: (route) => ({
        orderNumber: route.query.orderNumber,
        actionId: route.query.actionId,
        actionType: route.query.actionType,
        token: route.query.token
      })
    },
    {
      path: '/actionsRegister',
      component: ViewActionsRegister,
      children: [
        {
          path: '',
          name: 'actionsRegisterTable',
          component: ActionsRegisterTableView,
          props: (route) => ({
            mode: route.query.mode
          })
        }
      ]
    },
    {
      path: '/actionsRegisterDownload/:uuid?',
      name: 'actionsRegisterDownload',
      component: ActionsRegisterDownload,
      props(route) {
        return route.query || {};
      }
    }
  ]
});

router.beforeEach((to, from, next) => {
  const publicPages = [
    'login',
    'forgotPassword',
    'resetPassword',
    'mapPointsSharedMap',
    'ordersRegisterDownload',
    'actionsRegisterDownload',
    'orderActionGallery'
  ];
  const authRequired = !publicPages.includes(to.name);

  if (authRequired && !useAuthStore().isAuthenticated) return next('/login');
  else next();
});

export default router;
