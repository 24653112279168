<script setup>
import { ref, computed } from 'vue';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import {
  transportOrderDriversSortingOptions as sortingOptions,
  getSortingIcon,
  getSortingDirection
} from '@/helpers/sortTableHelper.js';
import { PAGINATION_LIMIT } from '../../data/constants/appConstants.js';
import {
  DRIVER_IS_WAITING_ORDER_STATUS_ID,
  DRIVER_IS_WAITING_FOR_VERIFICATION_STATUS_ID
} from '../../data/constants/appConstants.js';
import PaginationCountPicker from '@/components/utils/PaginationCountPicker.vue';
import ServerErrorPanel from '@/components/utils/ServerErrorPanel.vue';
import TableElementsInfo from '@/components/utils/TableElementsInfo.vue';
import { usePaginationStore } from '@/stores/storePagination';
import { Bootstrap4Pagination } from 'laravel-vue-pagination';
import { TRANSPORT_ORDER_DRIVERS_TABLE_PAGINATION_KEY } from '../../data/paginationInjectionKeys';
import { isNotEmptyArray } from '../../helpers/utilsHelper';
import ManualTransportOrderPerform from '../transport/ManualTransportOrderPerform.vue';
import TransportOrderVerification from './TransportOrderVerification.vue';

const RepositoryDictionaries = RepositoryFactory.get('dictionaries');

const props = defineProps({
  filter: { type: Object, default: null },
  showBorder: { type: Boolean, default: true },
  longerSearchBar: { type: Boolean, default: false },
  noItemsText: { type: String, default: 'Brak elementów do wyświetlenia' },
  showDefaultButtons: { type: Boolean, default: true },
  orderId: { type: Number, default: null },
  orderNumber: { type: String, default: null }
});
const emit = defineEmits(['refresh']);
const isLoading = ref(false);
const userOrdersObject = ref({});
const searchBox = ref('');
const currentSort = ref({ by: null, direction: null });
const paginationStore = usePaginationStore();
const manualPerformUserOrderTransport = ref(null);
const manualTransportOrderPerform = ref();
const verifyTransportOrderComponent = ref();
const verifyTransportUserOrder = ref(null);

const firstNameSortIcon = computed(() => {
  return getSortingIcon(currentSort.value, sortingOptions.FIRST_NAME);
});

const lastNameSortIcon = computed(() => {
  return getSortingIcon(currentSort.value, sortingOptions.LAST_NAME);
});

// created
fetchData();

function fetchData(page = 1) {
  isLoading.value = true;

  RepositoryDictionaries.getDictionaryElementsObject(
    page,
    paginationStore.getPaginationItemsCount(TRANSPORT_ORDER_DRIVERS_TABLE_PAGINATION_KEY),
    searchBox.value,
    currentSort.value.by,
    currentSort.value.direction,
    props.filter.url
  )
    .then((data) => {
      userOrdersObject.value = data;
    })
    .catch((error) => console.log(error))
    .finally(() => {
      isLoading.value = false;
    });
}

function search() {
  fetchData();
}

function sortBy(newSortBy) {
  currentSort.value.direction = getSortingDirection(currentSort.value, newSortBy);
  currentSort.value.by = newSortBy;
  fetchData();
}

function onPerformTheOrderButtonClicked(userOrderTransport) {
  manualPerformUserOrderTransport.value = userOrderTransport;
  manualTransportOrderPerform.value?.show();
}

function onItemsPerPageChanged() {
  fetchData();
}

function canPerformTheOrder(userOrder) {
  return userOrder.status?.id === DRIVER_IS_WAITING_ORDER_STATUS_ID;
}

function isOrderForVerification(userOrder) {
  return userOrder.status?.id === DRIVER_IS_WAITING_FOR_VERIFICATION_STATUS_ID;
}

function onVerifyTheOrderButtonClicked(userOrderTransport) {
  verifyTransportUserOrder.value = userOrderTransport;
  verifyTransportOrderComponent.value?.show();
}
</script>

<template>
  <div class="ibox">
    <div
      class="ibox-content"
      :class="{ 'sk-loading': isLoading }"
      :style="props.showBorder ? '' : 'border-style: none'"
    >
      <div class="sk-spinner sk-spinner-three-bounce">
        <div class="sk-bounce1"></div>
        <div class="sk-bounce2"></div>
        <div class="sk-bounce3"></div>
      </div>
      <div class="row">
        <div :class="props.longerSearchBar ? 'col-sm-4 offset-sm-8' : 'col-sm-3 offset-sm-9'">
          <div class="input-group mb-3">
            <input
              placeholder="Szukaj.."
              type="text"
              class="form-control form-control-sm"
              v-model="searchBox"
              v-on:keyup.enter="search"
            />
            <span class="input-group-append">
              <button @click="search" type="button" class="btn btn-sm btn-primary">
                <i class="fa fa-search"></i> Szukaj
              </button>
            </span>
          </div>
        </div>
      </div>

      <TableElementsInfo :metaObject="userOrdersObject?.meta ?? null" />

      <div v-if="isNotEmptyArray(userOrdersObject?.data)" class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Status</th>
              <th
                @click="sortBy(sortingOptions.FIRST_NAME)"
                style="cursor: pointer; white-space: nowrap"
              >
                Imię <span v-html="firstNameSortIcon" />
              </th>
              <th
                @click="sortBy(sortingOptions.LAST_NAME)"
                style="cursor: pointer; white-space: nowrap"
              >
                Nazwisko <span v-html="lastNameSortIcon" />
              </th>
              <th style="white-space: nowrap">Wykonano</th>
              <th style="white-space: nowrap">Pozostawiono</th>
              <th style="white-space: nowrap">Notatka</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="userOrder in userOrdersObject.data" :key="userOrder.id">
              <td style="white-space: nowrap">
                <span
                  class="label label-primary"
                  :style="
                    userOrder?.status?.color != null
                      ? 'background-color:' + userOrder.status.color
                      : ''
                  "
                >
                  {{ userOrder?.status?.name ?? '-' }}</span
                >
              </td>
              <td style="white-space: nowrap">
                {{ userOrder?.worker?.firstName ?? '-' }}
              </td>
              <td style="white-space: nowrap">
                {{ userOrder?.worker?.lastName ?? '-' }}
              </td>

              <td>
                <div v-if="userOrder?.done_detailed">
                  <div v-for="(propValue, propName) in userOrder.done_detailed" :key="propName">
                    {{ propName }}: {{ propValue }}
                  </div>
                </div>
                <div v-else>-</div>
              </td>

              <td>
                <div v-if="userOrder?.left_detailed">
                  <div v-for="(propValue, propName) in userOrder.left_detailed" :key="propName">
                    {{ propName }}: {{ propValue }}
                  </div>
                </div>
                <div v-else>-</div>
              </td>

              <td style="max-width: 200px">
                {{ userOrder?.note ?? '-' }}
              </td>

              <td>
                <div class="float-right">
                  <span v-if="props.showDefaultButtons">
                    <button
                      v-if="canPerformTheOrder(userOrder)"
                      class="btn btn-outline btn-primary btn-xs mr-2"
                      type="button"
                      @click="onPerformTheOrderButtonClicked(userOrder)"
                      onclick="event.stopPropagation()"
                    >
                      Zamknij transport kierowcy
                    </button>
                    <button
                      v-if="isOrderForVerification(userOrder)"
                      class="btn btn-outline btn-primary btn-xs mr-2"
                      type="button"
                      @click="onVerifyTheOrderButtonClicked(userOrder)"
                      onclick="event.stopPropagation()"
                    >
                      Weryfikuj zlecenie
                    </button>
                  </span>
                  <slot name="buttons" :userOrder="userOrder"></slot>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else-if="userOrdersObject?.error">
        <ServerErrorPanel @onRetry="fetchData" />
      </div>
      <div v-else>
        <p class="text-center mt-3">{{ props.noItemsText }}</p>
      </div>

      <div class="row mt-3">
        <div class="col-xl-6 offset-xl-3">
          <Bootstrap4Pagination
            v-if="userOrdersObject?.data"
            align="center"
            :data="userOrdersObject"
            :limit="PAGINATION_LIMIT"
            @pagination-change-page="fetchData"
          ></Bootstrap4Pagination>
        </div>

        <div class="col-xl-3">
          <PaginationCountPicker
            :paginationKey="TRANSPORT_ORDER_DRIVERS_TABLE_PAGINATION_KEY"
            v-show="!isLoading"
            @selectionChanged="onItemsPerPageChanged"
          />
        </div>
      </div>
    </div>
  </div>

  <ManualTransportOrderPerform
    ref="manualTransportOrderPerform"
    v-if="manualPerformUserOrderTransport"
    :userOrderTransport="manualPerformUserOrderTransport"
    :orderId="props.orderId"
    :orderNumber="props.orderNumber"
    @succeeded="emit('refresh')"
  />

  <TransportOrderVerification
    ref="verifyTransportOrderComponent"
    v-if="verifyTransportUserOrder"
    :userOrderTransport="verifyTransportUserOrder"
    :orderId="props.orderId"
    :orderNumber="props.orderNumber"
    @succeeded="emit('refresh')"
  />
</template>

<style scoped></style>
