<script setup>
/* eslint-env jquery */
import { ref, onMounted, unref, watch } from 'vue';

const props = defineProps({
  inputId: { type: String, required: true },
  label: { type: String, required: true },
  startDate: { type: String, required: false },
  endDate: { type: String, required: false },
  labelCssClass: { type: String, default: '' }
});

const emit = defineEmits(['startDateChanged', 'endDateChanged']);

const tempStartDate = ref('-');
const tempEndDate = ref('-');

watch(
  () => props.startDate,
  () => {
    tempStartDate.value = unref(props.startDate) ?? '-';
    $(`#${props.inputId}StartDateId`).datepicker(
      'setDate',
      tempStartDate.value === '-' ? null : tempStartDate.value
    );
  }
);

watch(
  () => props.endDate,
  () => {
    tempEndDate.value = unref(props.endDate) ?? '-';
    $(`#${props.inputId}EndDateId`).datepicker(
      'setDate',
      tempEndDate.value === '-' ? null : tempEndDate.value
    );
  }
);

onMounted(() => {
  const datepickerConfig = {
    todayBtn: 'linked',
    keyboardNavigation: true,
    forceParse: true,
    calendarWeeks: true,
    autoclose: true,
    weekStart: 1
  };

  $(`#${props.inputId}DateRangeId .input-daterange`).datepicker(datepickerConfig);
  $(document.body).on(
    'change',
    `input[id^="${props.inputId}StartDateId"]`,
    updateStartDateFromPicker
  );
  $(document.body).on('change', `input[id^="${props.inputId}EndDateId"]`, updateEndDateFromPicker);
});

function updateStartDateFromPicker() {
  const valueFromPicker = document.getElementById(`${props.inputId}StartDateId`).value;
  tempStartDate.value = valueFromPicker === '' ? '-' : valueFromPicker;
  emit('startDateChanged', tempStartDate.value === '-' ? null : tempStartDate.value);
}

function updateEndDateFromPicker() {
  const valueFromPicker = document.getElementById(`${props.inputId}EndDateId`).value;
  tempEndDate.value = valueFromPicker === '' ? '-' : valueFromPicker;
  emit('endDateChanged', tempEndDate.value === '-' ? null : tempEndDate.value);
}

function onClearDatesButtonClicked() {
  tempStartDate.value = '-';
  tempEndDate.value = '-';

  $(`#${props.inputId}StartDateId`).datepicker('setDate', null);
  $(`#${props.inputId}EndDateId`).datepicker('setDate', null);
}
</script>

<template>
  <div class="form-group" :id="`${props.inputId}DateRangeId`">
    <label class="font-normal" :class="props.labelCssClass">{{ props.label }}</label>
    <div class="input-daterange input-group" id="dateRangePickerId">
      <input
        :id="`${props.inputId}StartDateId`"
        type="text"
        class="form-control-sm form-control"
        v-model="tempStartDate"
      />
      <span class="input-group-addon" style="width: 50px">do</span>
      <input
        :id="`${props.inputId}EndDateId`"
        type="text"
        class="form-control-sm form-control"
        v-model="tempEndDate"
      />
      <button
        style="width: 22px; border-width: 0px; border-color: gray"
        @click="onClearDatesButtonClicked"
      >
        x
      </button>
    </div>
  </div>
</template>

<style scoped></style>
