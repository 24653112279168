import repository from './repository.js';

export default {
  async getTransportOrdersObject(
    page,
    itemsPerPage,
    search,
    sortBy,
    orderType,
    filter,
    userFilter
  ) {
    let url = '/ordertransport/register';

    if (filter && filter.subject && filter.id != null)
      url = `/${filter.subject}/${filter.id}/transportOrders`;
    else if (filter && filter.url) url = filter.url;

    let userFilterUrlEnding = '';

    if (userFilter) {
      for (const property in userFilter) {
        if (Array.isArray(userFilter[property])) {
          userFilter[property].forEach((x) => {
            userFilterUrlEnding += `&${property}[]=${x}`;
          });
        } else userFilterUrlEnding += `&${property}=${userFilter[property]}`;
      }
    }

    return repository
      .get(
        `${url}?page=${page}&search=${search}&orderBy=${sortBy}&orderType=${orderType}&paginate=${itemsPerPage}${userFilterUrlEnding}`
      )
      .then((response) => {
        if (response.data.error == null) return response.data;
      })
      .catch((error) => {
        return { error };
      });
  },

  async getTransportOrderDetailsGeneral(id) {
    return repository.get(`/ordertransport/${id}/detailsWeb`).then((response) => {
      if (response.data.error == null) return response.data.data;
    });
  },

  async getTransportOrderSummaryData(id) {
    return repository.get(`/ordertransport/${id}/summaryWeb`).then((response) => {
      if (response.data.error == null) return response.data;
    });
  },

  async getTransportOrderUsers(id) {
    return repository.get(`/ordertransport/${id}/workersWeb`).then((response) => {
      if (response.data.error == null) return response.data.data;
    });
  },

  async createTransportOrder(driversIds, actionsIds) {
    return repository
      .post('/ordertransport', { drivers: driversIds, actions: actionsIds })
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async deleteOrder(id) {
    return repository
      .delete(`/ordertransport/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async archiveOrder(orderId) {
    return repository
      .get(`/ordertransport/${orderId}/archive`)
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async completeOrder(orderId) {
    return repository
      .get(`/ordertransport/${orderId}/completeOrder`)
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async addDriverToOrder(orderId, userId) {
    return repository
      .post(`/ordertransport/${orderId}/addWorker`, { id_user: userId })
      .then((response) => {
        return response?.data?.message ?? null;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async getTransportOrderActionsPerUserOrderSets(id) {
    return repository.get(`/ordertransport/${id}/actionsWeb`).then((response) => {
      if (response.data.error == null) return response.data;
    });
  },

  async getTransportOrdersForMapObject(page, itemsPerPage, search, sortBy, orderType, userFilter) {
    let url = '/ordertransport/mapWEB';

    let userFilterUrlEnding = '';

    if (userFilter) {
      for (const property in userFilter) {
        if (Array.isArray(userFilter[property])) {
          userFilter[property].forEach((x) => {
            userFilterUrlEnding += `&${property}[]=${x}`;
          });
        } else userFilterUrlEnding += `&${property}=${userFilter[property]}`;
      }
    }

    return repository
      .get(
        `${url}?page=${page}&search=${search}&orderBy=${sortBy}&orderType=${orderType}&paginate=${itemsPerPage}${userFilterUrlEnding}`
      )
      .then((response) => {
        if (response.data.error == null) return response.data;
      })
      .catch((error) => {
        return { error };
      });
  },

  async getSummaryForSelectedTransportPoints(selectedPlacesIds) {
    return repository
      .post(`/action/getSummaryForSelectedTransportPoints`, { data: selectedPlacesIds })
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async getAdditionalSummaryForSingleSelectedTransportPoint(selectedPlaceId) {
    return repository
      .get(`/action/${selectedPlaceId}/transportMapReview`)
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async getDataForTransportList(selectedPlacesIds) {
    return repository
      .post(`/action/getDataForTransportList`, { data: selectedPlacesIds })
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async setTransportOrderPriority(orderId) {
    return repository
      .get(`/ordertransport/${orderId}/setPriority`)
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async createTransportAction(
    id_order,
    id_user_order_transport,
    location,
    gathered,
    left,
    photos_before,
    photos_after
  ) {
    let formData = new FormData();

    formData.append('id_user_order_transport', id_user_order_transport);
    formData.append('lat', location.lat);
    formData.append('lon', location.lon);
    formData.append('gathered', JSON.stringify(gathered));
    formData.append('left', JSON.stringify(left));

    if (Array.isArray(photos_before)) {
      for (let index = 0; index < photos_before.length; index++) {
        formData.append(`photos_before[${index}][file]`, photos_before[index]);
      }
    }

    if (Array.isArray(photos_after)) {
      for (let index = 0; index < photos_after.length; index++) {
        formData.append(`photos_after[${index}][file]`, photos_after[index]);
      }
    }

    return repository
      .post(`/ordertransport/${id_order}/stopOrderWEB`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then((response) => {
        return response?.data?.id != null
          ? { id: response.data.id }
          : response?.data?.message ?? null;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async getTransportOrderNewActionInitialLocation(orderId) {
    return repository.get(`/ordertransport/${orderId}/getAction`).then((response) => {
      if (response.data.error == null && response.data?.data?.geotimestamp) {
        return {
          lat: response.data?.data?.geotimestamp.lat,
          lon: response.data?.data?.geotimestamp.lon
        };
      }
    });
  },

  async getDataForDriversWorkVerification(userOrderId) {
    return repository
      .get(`/userordertransport/${userOrderId}/getValuesForVerification`)
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async verifyTransportAction(userOrderTransportId, driversVerifiedWorkObject) {
    return repository
      .post(`/userordertransport/${userOrderTransportId}/verifyOrderWEB`, driversVerifiedWorkObject)
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async getTransportOrdersReport(startDate, endDate) {
    if (startDate != null && startDate !== '') {
      startDate += ' 00:00:00';
    }

    if (endDate != null && endDate !== '') {
      endDate += ' 23:59:59';
    }

    return repository
      .post(`/ordertransport/gatherRaport`, {
        date_from: startDate,
        date_to: endDate
      })
      .then((response) => {
        if (response.data.error == null) return response.data?.data;
      })
      .catch((error) => {
        return { error };
      });
  },

  async changeTransportOrderAdditionalStatus(orderId, statusId) {
    return repository
      .post(`/ordertransport/${orderId}/setAdditionalStatus`, {
        id_order_transport_additional_status: statusId
      })
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async verifyDelete(id) {
    return repository
      .get(`/ordertransport/${id}/verifyDelete`)
      .then((response) => {
        if (response.data.error == null && response.data != null) return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  }
};
