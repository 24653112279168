import { ref, computed } from 'vue';
import { isNotEmptyArray } from '../helpers/utilsHelper';
import { updateMarkersIcon } from '../helpers/markersCreator';

export function useMapLogic() {
  const clusterMap = ref();
  const ctrlPressed = ref(false);

  const selectedMarkerIds = computed(() => {
    if (!isNotEmptyArray(clusterMap.value?.markersRef)) return [];

    let selectedIds = [];

    clusterMap.value.markersRef.forEach((marker) => {
      if (marker.data.selected) selectedIds.push(marker.markerId);
    });

    return selectedIds;
  });

  const allSelected = computed(() => {
    if (!isNotEmptyArray(clusterMap.value?.markersRef)) return true;

    let allSelected = true;

    clusterMap.value.markersRef.forEach((marker) => {
      if (!marker.data.selected) allSelected = false;
    });

    return allSelected;
  });

  const noneSelected = computed(() => {
    if (!isNotEmptyArray(clusterMap.value?.markersRef)) return true;

    let noneSelected = true;

    clusterMap.value.markersRef.forEach((marker) => {
      if (marker.data.selected) noneSelected = false;
    });

    return noneSelected;
  });

  function onSelectedMarkersChanged(markersToSelect) {
    if (!isNotEmptyArray(clusterMap.value?.markersRef)) return;

    clusterMap.value.markersRef.forEach((marker) => {
      const newSelectedValue =
        marker.data.selectable !== false &&
        marker.markerId != null &&
        markersToSelect.some((x) => x.markerId === marker.markerId);

      updateMarkerSelectedState(marker, newSelectedValue);
    });
  }

  function onSingleMarkerSelected(markerToSelect) {
    if (markerToSelect.markerId == null) return;
    if (!isNotEmptyArray(clusterMap.value?.markersRef)) return;

    if (ctrlPressed.value) {
      clusterMap.value.markersRef.forEach((marker) => {
        if (markerToSelect.markerId === marker.markerId) {
          const newSelectedValue = !marker.data.selected && marker.data.selectable !== false;
          updateMarkerSelectedState(marker, newSelectedValue);
        }
      });
    } else {
      clusterMap.value.markersRef.forEach((marker) => {
        const newSelectedValue =
          marker.data.selectable !== false && markerToSelect.markerId === marker.markerId;
        updateMarkerSelectedState(marker, newSelectedValue);
      });
    }
  }

  function resetSelectedMarkers() {
    if (!isNotEmptyArray(clusterMap.value?.markersRef)) return;

    clusterMap.value.markersRef.forEach((marker) => {
      updateMarkerSelectedState(marker, false);
    });
  }

  function selectAllSelectableMarkers() {
    if (!isNotEmptyArray(clusterMap.value?.markersRef)) return;

    clusterMap.value?.markersRef.forEach((marker) => {
      if (marker.data.selectable !== false && marker.markerId != null)
        updateMarkerSelectedState(marker, true);
    });
  }

  function resetSelectedIfCtrl() {
    if (ctrlPressed.value) return;

    resetSelectedMarkers();
  }

  function updateMarkerSelectedState(marker, newSelectedValue) {
    const previousSelected = marker.data.selected;

    marker.data.selected = newSelectedValue;

    if (marker.data.selected !== previousSelected) {
      updateMarker(marker);
    }
  }

  function updateMarker(marker) {
    updateMarkersIcon(marker);
  }

  function focusOnMarker(marker) {
    clusterMap.value.focusOnMarker(marker);
  }

  // created
  window.addEventListener('keydown', (event) => {
    if (event.code === 'ControlLeft') ctrlPressed.value = true;
  });

  window.addEventListener('keyup', (event) => {
    if (event.code === 'ControlLeft') ctrlPressed.value = false;
  });

  return {
    clusterMap,
    allSelected,
    noneSelected,
    selectedMarkerIds,
    onSelectedMarkersChanged,
    onSingleMarkerSelected,
    resetSelectedMarkers,
    selectAllSelectableMarkers,
    resetSelectedIfCtrl,
    updateMarkerSelectedState,
    updateMarker,
    focusOnMarker
  };
}
