<script setup>
import { ref, computed, watch } from 'vue';
import { RepositoryFactory } from '@/data/repositoryFactory.js';

const RepositoryOrders = RepositoryFactory.get('orders');
const props = defineProps({ orderId: Number });
const isLoading = ref(false);
const orderRangesData = ref(null);

const columnNames = computed(() => {
  if (!Array.isArray(orderRangesData.value)) return [];

  const columns = [];

  orderRangesData.value.forEach((orderRange) => {
    orderRange.stats.forEach((stat) => columns.push(stat.eName));
  });

  return [...new Set(columns)];
});

watch(
  () => props.orderId,
  () => fetchData()
);

async function fetchData() {
  isLoading.value = true;
  orderRangesData.value = await RepositoryOrders.getOrderSummaryRangesData(props.orderId);
  isLoading.value = false;
}

function getStat(range, columnName) {
  let stat = range.stats.find((s) => s.eName === columnName);
  return stat?.total ?? 0;
}

// created
if (props.orderId) {
  fetchData();
}
</script>

<template>
  <div class="ibox">
    <div class="ibox-content" :class="{ 'sk-loading': isLoading }">
      <div class="sk-spinner sk-spinner-three-bounce">
        <div class="sk-bounce1"></div>
        <div class="sk-bounce2"></div>
        <div class="sk-bounce3"></div>
      </div>

      <h4 class="mt-3">Podsumowanie demontaży</h4>

      <div v-if="columnNames.length > 0" class="table-responsive mt-3 mb-3 ml-auto mr-auto">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Akcja/Zakres</th>
              <th v-for="columnName in columnNames" :key="columnName" class="text-center">
                {{ columnName }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="range in orderRangesData" :key="range.action_type">
              <td>{{ range.action_type }}</td>
              <td v-for="columnName in columnNames" :key="columnName" class="text-center">
                {{ getStat(range, columnName) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        <h4 style="text-align: center">Brak danych</h4>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
