<script setup>
import { ref, watch } from 'vue';
import DictionaryTable from '../dictionaries/DictionaryTable.vue';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import { handleResponseWithMessages } from '@/helpers/wwwHelper.js';
import ActionsTableFilterAndSearch from './ActionsTableFilterAndSearch.vue';
import { useAuthStore } from '../../stores/auth';
import {
  ACCESS_KEY_ACTION_EDIT_BUTTON,
  ACCESS_KEY_ACTION_SHOW_HIDE_BUTTON
} from '../../data/constants/authConstants';

const RepositoryActions = RepositoryFactory.get('actions');
const props = defineProps({
  orderId: Number,
  longerSearchBar: { type: Boolean, default: false },
  initialSearchValue: { type: String, default: null }
});
const emit = defineEmits([
  'onActionsFetched',
  'actionVisibilityChanged',
  'actionUpdateButtonClicked'
]);
const dictTableKey = ref(0);
const dictionaryTable = ref();
const actionsTableFilterAndSearch = ref();
const searchBox = ref('');
const userFilter = ref(null);
const authStore = useAuthStore();

const tableColumns = [
  { key: 'worker', header: 'Wykonawca', computed: true },
  { key: 'actionType', header: 'Typ', computed: true },
  { key: 'demElemType', header: 'Typ elementu', computed: true }
];

watch(
  () => props.orderId,
  () => dictTableKey.value++
);

function onDataFetched(dataObject) {
  emit('onActionsFetched', dataObject);
  actionsTableFilterAndSearch.value.updateAppliedFilters();
}

function onToggleMapVisibilityClick(action) {
  toggleActionVisibility(action);
}

async function toggleActionVisibility(action) {
  var response = await RepositoryActions.toggleActionVisibility(
    action.id,
    action.map_display == 1 ? false : true
  );

  handleResponseWithMessages(
    response,
    'Akcja została ' + (action.map_display === 1 ? 'ukryta' : 'pokazana') + '.',
    () => {
      emit('actionVisibilityChanged');
    }
  );
}

function reloadCurrentPage() {
  dictionaryTable.value.reloadCurrentPage();
}

function onFetchDataWithFiltersRequest({ search, filter }) {
  searchBox.value = search;
  userFilter.value = filter;
  dictTableKey.value++;
}

function onUpdateActionButtonClicked(action) {
  emit('actionUpdateButtonClicked', action.id);
}

defineExpose({ reloadCurrentPage });
</script>

<template>
  <ActionsTableFilterAndSearch
    ref="actionsTableFilterAndSearch"
    :longerSearchBar="props.longerSearchBar"
    :initialSearchValue="props.initialSearchValue"
    @fetchData="onFetchDataWithFiltersRequest"
  />

  <DictionaryTable
    ref="dictionaryTable"
    :key="dictTableKey"
    v-if="props.orderId != null"
    :showHeader="false"
    :showBorder="false"
    :url="`order/${props.orderId}/actions`"
    :columns="tableColumns"
    :customButtons="true"
    :showTableElementsInfo="true"
    :searchFromOutside="searchBox"
    :showSearchBar="false"
    :userFilter="userFilter"
    @dataFetched="onDataFetched"
  >
    <template #worker="{ element }">
      {{
        element.worker?.firstName && element.worker?.lastName
          ? `${element.worker.firstName} ${element.worker.lastName}`
          : '-'
      }}
    </template>
    <template #actionType="{ element }">
      {{ element.action_type?.name ?? '-' }}
    </template>
    <template #demElemType="{ element }">
      {{ element.dem_elem_type?.name ?? '-' }}
    </template>
    <template #buttons="{ element }">
      <div class="buttons-container">
        <button
          v-if="authStore.canAccess(ACCESS_KEY_ACTION_SHOW_HIDE_BUTTON)"
          type="button"
          class="btn btn-outline-info"
          @click="onToggleMapVisibilityClick(element)"
          onclick="event.stopPropagation()"
        >
          {{ `${element.map_display === 1 ? 'Ukryj' : 'Pokaż'}` }}
        </button>
        <button
          v-if="authStore.canAccess(ACCESS_KEY_ACTION_EDIT_BUTTON)"
          type="button"
          class="btn btn-outline-info"
          @click="onUpdateActionButtonClicked(element)"
          onclick="event.stopPropagation()"
        >
          Edytuj
        </button>
      </div>
    </template>
  </DictionaryTable>
</template>

<style scoped>
.buttons-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px 5px;
}
</style>
