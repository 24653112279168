<script setup>
import { ref, watch, computed } from 'vue';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import { handleResponseWithMessages } from '../../helpers/wwwHelper';
import Swal from 'sweetalert2';
import { getSwalConfiguration } from '../../helpers/swalHelper';
import { ACCESS_KEY_SETTLEMENT_COMMENT } from '../../data/constants/authConstants';
import { useAuthStore } from '../../stores/auth';
import { isNotEmptyArray } from '../../helpers/utilsHelper';
import { FAILURE_ACTION_TYPE_ID } from '../../data/constants/appConstants';
import { v4 as uuidv4 } from 'uuid';

const RepositoryOrders = RepositoryFactory.get('orders');
const props = defineProps({ orderId: Number, actionTypes: Array, order: Object });
const emit = defineEmits([
  'disabledActionTypesChanged',
  'disabledUserOrdersChanged',
  'disabledFailureReasonsChanged',
  'showOnlyLastActionsFilterChanged',
  'enabledUserOrdersStartStopsChanged',
  'refresh'
]);
const componentUUID = ref(uuidv4());
const isLoading = ref(false);
const actionTypesFilters = ref([]);
const failureReasonsFilters = ref([]);
const userOrdersFilters = ref([]);
const summaryData = ref(null);
const settlement_comment = ref(null);
const authStore = useAuthStore();
const lastActionsOnlyFilterEnabled = ref(false);

const settlementCommentSaveButtonDisabled = computed(() => {
  if (props.order?.settlement_comment === undefined) return true;
  if (props.order?.settlement_comment === settlement_comment.value) return true;
  if (props.order?.settlement_comment == null && settlement_comment.value === '') return true;

  return false;
});

watch(
  () => props.orderId,
  () => fetchSummaryData()
);

watch(
  () => props.actionTypes,
  () => updateActionTypesFilters()
);

watch(
  () => props.order,
  () => updateSettlementCommentFromProps()
);

function updateSettlementCommentFromProps() {
  settlement_comment.value = props.order?.settlement_comment;
}

async function fetchSummaryData() {
  isLoading.value = true;

  summaryData.value = await RepositoryOrders.getOrderSummaryData(props.orderId);
  let userOrders = await RepositoryOrders.getOrderUsers(props.orderId);
  updateUserOrdersFilters(userOrders);
  updateFailureReasonsFilters();

  isLoading.value = false;
}

function updateActionTypesFilters() {
  if (!Array.isArray(props.actionTypes)) return;

  actionTypesFilters.value = new Array();

  props.actionTypes.forEach((actionType) => {
    actionTypesFilters.value.push({ id: actionType.id, name: actionType.name, enabled: true });
  });
}

function updateFailureReasonsFilters() {
  if (!isNotEmptyArray(summaryData.value?.actions_failures)) return;

  failureReasonsFilters.value = new Array();

  summaryData.value.actions_failures.forEach((failureReason) => {
    failureReasonsFilters.value.push({
      id: failureReason.id_failure_action_reason,
      name: failureReason.name,
      enabled: true
    });
  });
}

function updateUserOrdersFilters(userOrders) {
  if (!Array.isArray(userOrders)) return;

  userOrdersFilters.value = new Array();

  userOrders.forEach((userOrder) => {
    userOrdersFilters.value.push({
      userOrderId: userOrder.id,
      name: userOrder.user.firstName + ' ' + userOrder.user.lastName,
      enabled: true,
      startStopMarkersVisible: false
    });
  });
}

if (props.orderId != null) {
  fetchSummaryData();
  updateActionTypesFilters();
}

function actionTypesFilterChanged() {
  let disabledActionTypes = actionTypesFilters.value.filter((x) => !x.enabled).map((x) => x.id);
  emit('disabledActionTypesChanged', disabledActionTypes);
}

function failureReasonsFilterChanged() {
  let disabledFailureReasons = failureReasonsFilters.value
    .filter((x) => !x.enabled)
    .map((x) => x.id);
  emit('disabledFailureReasonsChanged', disabledFailureReasons);
}

function userOrdersFilterChanged() {
  let disabledUserOrders = userOrdersFilters.value
    .filter((x) => !x.enabled)
    .map((x) => x.userOrderId);
  emit('disabledUserOrdersChanged', disabledUserOrders);
}

function userOrdersStartStopsFilterChanged() {
  let enabledUserOrdersStartStops = userOrdersFilters.value
    .filter((x) => x.startStopMarkersVisible)
    .map((x) => x.userOrderId);
  emit('enabledUserOrdersStartStopsChanged', enabledUserOrdersStartStops);
}

function lastActionsOnlyFilterChanged() {
  emit('showOnlyLastActionsFilterChanged', lastActionsOnlyFilterEnabled.value);
}

function onSettlementCommentSaveButtonClicked() {
  Swal.fire(
    getSwalConfiguration(
      'Aktualizacja komentarza do rozliczenia',
      'Czy na pewno chcesz zaktualizować komentarz?'
    )
  ).then((result) => {
    if (result.value) {
      saveSettlementComment();
    }
  });
}

async function saveSettlementComment() {
  var response = await RepositoryOrders.updateCommentsForSettlement(
    props.orderId,
    settlement_comment.value
  );

  handleResponseWithMessages(response, 'Zmiany zostały zapisane.', () => {
    emit('refresh');
  });
}

function toggleUserOrderFilterStartStop(userOrderFilter) {
  userOrderFilter.startStopMarkersVisible = !userOrderFilter.startStopMarkersVisible;
  userOrdersStartStopsFilterChanged();
}

// created
updateSettlementCommentFromProps();
</script>

<template>
  <div
    class="ibox-content profile-content"
    :class="{ 'sk-loading': isLoading }"
    style="border-style: none"
  >
    <div class="sk-spinner sk-spinner-three-bounce">
      <div class="sk-bounce1"></div>
      <div class="sk-bounce2"></div>
      <div class="sk-bounce3"></div>
    </div>

    <div v-if="isNotEmptyArray(summaryData?.actions_summary)">
      <h4>Podsumowanie:</h4>

      <div
        v-for="actionSummaryRow in summaryData.actions_summary"
        :key="actionSummaryRow.id_action_type"
        class="pl-3"
      >
        {{ actionSummaryRow.name }}: {{ actionSummaryRow.total }}

        <div
          v-if="
            actionSummaryRow.id_action_type === FAILURE_ACTION_TYPE_ID &&
            isNotEmptyArray(summaryData?.actions_failures)
          "
        >
          <div
            v-for="actionFailureRow in summaryData.actions_failures"
            :key="actionFailureRow.id_failure_action_reason"
            class="pl-3"
          >
            {{ actionFailureRow.name }}: {{ actionFailureRow.total }}
          </div>
        </div>
      </div>

      <span v-if="authStore.canAccess(ACCESS_KEY_SETTLEMENT_COMMENT)">
        <h4 class="mt-4">Rozliczenie</h4>

        <div class="input-group">
          <input
            placeholder="Wpisz komentarz.."
            type="text"
            class="form-control form-control-sm"
            v-model="settlement_comment"
          />
          <span class="input-group-append">
            <button
              :disabled="settlementCommentSaveButtonDisabled"
              @click="onSettlementCommentSaveButtonClicked"
              type="button"
              class="btn btn-sm btn-primary"
            >
              Zapisz
            </button>
          </span>
        </div>
      </span>

      <div class="table-responsive mt-4">
        <h4>Filtruj po ostatniej akcji</h4>
        <table class="table table-striped">
          <tbody>
            <tr>
              <td>
                <label>Pokaż tylko, jeśli ostatnia akcja</label>
              </td>
              <td class="d-flex justify-content-end pr-5">
                <div class="switch">
                  <div class="onoffswitch">
                    <input
                      type="checkbox"
                      class="onoffswitch-checkbox"
                      :id="componentUUID + 'lastActionsOnlyFilterInputId'"
                      v-model="lastActionsOnlyFilterEnabled"
                      @change="lastActionsOnlyFilterChanged"
                    />
                    <label
                      class="onoffswitch-label"
                      :for="componentUUID + 'lastActionsOnlyFilterInputId'"
                    >
                      <span class="onoffswitch-inner"></span>
                      <span class="onoffswitch-switch"></span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="Array.isArray(props.actionTypes)" class="table-responsive mt-2">
        <h4>Filtruj po typie akcji</h4>
        <table class="table table-striped">
          <tbody>
            <tr v-for="actionTypeFilter in actionTypesFilters" :key="actionTypeFilter.id">
              <td>
                <label>{{ actionTypeFilter.name }}</label>
              </td>
              <td class="d-flex justify-content-end pr-5">
                <div class="switch">
                  <div class="onoffswitch">
                    <input
                      type="checkbox"
                      class="onoffswitch-checkbox"
                      :id="componentUUID + 'actionType' + actionTypeFilter.id"
                      v-model="actionTypeFilter.enabled"
                      @change="actionTypesFilterChanged"
                    />
                    <label
                      class="onoffswitch-label"
                      :for="componentUUID + 'actionType' + actionTypeFilter.id"
                    >
                      <span class="onoffswitch-inner"></span>
                      <span class="onoffswitch-switch"></span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="isNotEmptyArray(summaryData?.actions_failures)" class="table-responsive mt-2">
        <h4>Filtruj po typie niepowodzenia</h4>

        <table class="table table-striped">
          <tbody>
            <tr v-for="failureReasonFilter in failureReasonsFilters" :key="failureReasonFilter.id">
              <td>
                <label>{{ failureReasonFilter.name }}</label>
              </td>
              <td class="d-flex justify-content-end pr-5">
                <div class="switch">
                  <div class="onoffswitch">
                    <input
                      type="checkbox"
                      class="onoffswitch-checkbox"
                      :id="componentUUID + 'failureReason' + failureReasonFilter.id"
                      v-model="failureReasonFilter.enabled"
                      @change="failureReasonsFilterChanged"
                    />
                    <label
                      class="onoffswitch-label"
                      :for="componentUUID + 'failureReason' + failureReasonFilter.id"
                    >
                      <span class="onoffswitch-inner"></span>
                      <span class="onoffswitch-switch"></span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="Array.isArray(userOrdersFilters)" class="table-responsive mt-2">
        <h4>Filtruj po wykonaniu</h4>
        <table class="table table-striped">
          <tbody>
            <tr v-for="userOrderFilter in userOrdersFilters" :key="userOrderFilter.userOrderId">
              <td>
                <label>{{ userOrderFilter.name }}</label>
              </td>

              <td class="d-flex justify-content-end pr-5" style="gap: 10px">
                <img
                  @click="toggleUserOrderFilterStartStop(userOrderFilter)"
                  src="/static/img/markerIcons/startStop.png"
                  width="30"
                  height="30"
                  style="cursor: pointer"
                  :style="
                    userOrderFilter.startStopMarkersVisible
                      ? ''
                      : 'filter: grayscale(100%) opacity(50%) brightness(70%)'
                  "
                  :title="`Kliknij, aby ${
                    userOrderFilter.startStopMarkersVisible ? 'wyłączyć' : 'włączyć'
                  } markery start/stop zlecenia.`"
                />

                <div class="switch">
                  <div class="onoffswitch">
                    <input
                      type="checkbox"
                      class="onoffswitch-checkbox"
                      :id="componentUUID + 'userOrder' + userOrderFilter.userOrderId"
                      v-model="userOrderFilter.enabled"
                      @change="userOrdersFilterChanged"
                    />
                    <label
                      class="onoffswitch-label"
                      :for="componentUUID + 'userOrder' + userOrderFilter.userOrderId"
                    >
                      <span class="onoffswitch-inner"></span>
                      <span class="onoffswitch-switch"></span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div v-else>
      <span v-if="!isLoading">
        <span v-if="authStore.canAccess(ACCESS_KEY_SETTLEMENT_COMMENT)">
          <h4 class="mt-4">Rozliczenie</h4>

          <div class="input-group">
            <input
              placeholder="Wpisz komentarz.."
              type="text"
              class="form-control form-control-sm"
              v-model="settlement_comment"
            />
            <span class="input-group-append">
              <button
                :disabled="settlementCommentSaveButtonDisabled"
                @click="onSettlementCommentSaveButtonClicked"
                type="button"
                class="btn btn-sm btn-primary"
              >
                Zapisz
              </button>
            </span>
          </div>
        </span>
        <h4 v-else style="text-align: center">Brak danych</h4>
      </span>
    </div>
  </div>
</template>

<style scoped></style>
