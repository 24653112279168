import L from 'leaflet';
import { isNotEmptyArray } from './utilsHelper';

export function getMarker(markerData, onClick, onMoveEnd) {
  let marker = L.marker(tooltipCoords(markerData));

  setMarkerData(marker, markerData);
  setMarkerZIndex(marker, markerData);
  setDraggable(marker, markerData);
  setMarkerId(marker, markerData);
  setAdditionalOptions(marker, markerData);
  setIcon(marker, markerData);
  setTooltip(marker, markerData);
  setPopup(marker, markerData);
  setOnClick(marker, onClick);
  setOnMoveEnd(marker, onMoveEnd);

  return marker;
}

function setMarkerData(marker, markerData) {
  marker.data = markerData;
}

function setMarkerZIndex(marker, markerData) {
  if (markerData?.zIndexOffset == undefined) return;

  marker.options.zIndexOffset = markerData.zIndexOffset;
}

function setDraggable(marker, markerData) {
  if (markerData?.draggable == undefined) return;

  marker.options.draggable = markerData.draggable;
}

function setMarkerId(marker, markerData) {
  if (markerData?.markerId == undefined) return;

  marker.markerId = markerData.markerId;
}

function setAdditionalOptions(marker, markerData) {
  if (markerData.options == undefined) return;

  for (var prop in markerData.options) {
    if (Object.prototype.hasOwnProperty.call(markerData.options, prop)) {
      marker.options[prop] = markerData.options[prop];
    }
  }
}

function setIcon(marker, markerData) {
  let markerIcon = undefined;

  if (markerData.selected) {
    markerIcon = L.icon({
      iconUrl: '/static/img/markerIcons/yellow_marker.png',
      iconSize: [25, 40],
      iconAnchor: [12, 40]
    });

    marker.setIcon(markerIcon);
    return;
  }

  if (markerData.draggable) {
    markerIcon = L.icon({
      iconUrl: '/static/img/markerIcons/red_marker.png',
      iconSize: [25, 40],
      iconAnchor: [12, 40]
    });

    marker.setIcon(markerIcon);
    return;
  }

  if (markerData.grayCircle) {
    markerIcon = L.icon({
      iconUrl: '/static/img/markerIcons/gray_circle_marker.png',
      iconSize: [10, 10],
      iconAnchor: [5, 5]
    });

    marker.setIcon(markerIcon);
    return;
  }

  if (isNotEmptyArray(markerData.shadows)) {
    let divIcon = markerData?.divIcon ?? {
      className: '',
      iconSize: markerData.iconSize,
      iconAnchor: markerData.iconAnchor,
      html: `<img style='position: absolute; top: 0; left: 0; width: ${markerData.iconSize[0]}px; height: ${markerData.iconSize[1]}px;' src='/static/img/markerIcons/${markerData.icon}.png' background-size: cover;"/>`
    };

    markerData.shadows.forEach((shadow) => {
      if (
        shadow.url != null &&
        shadow.topPx != null &&
        shadow.leftPx != null &&
        isNotEmptyArray(shadow?.size) &&
        shadow.size.length === 2
      ) {
        divIcon.html += `<img src='/static/img/markerIcons/${shadow.url}.png' background-size: cover; style="position: absolute; left:${shadow.leftPx}px; top:${shadow.topPx}px; width: ${shadow.size[0]}px; height: ${shadow.size[1]}px;" />`;
      } else if (shadow.html != null) {
        divIcon.html += shadow.html;
      }
    });

    markerData = Object.assign(markerData, { divIcon });
  }

  if (markerData.divIcon) {
    markerIcon = new L.DivIcon(markerData.divIcon);
    marker.setIcon(markerIcon);
    return;
  }

  if (markerData.icon) {
    markerIcon = L.icon({
      iconUrl: '/static/img/markerIcons/' + markerData.icon + '.png',
      iconSize: markerData.iconSize != null ? markerData.iconSize : [25, 40],
      iconAnchor: markerData.iconAnchor != null ? markerData.iconAnchor : [12, 40],
      className: markerData.className != null ? markerData.className : ''
    });

    if (markerData.shadowUrl && markerData.shadowSize && markerData.shadowAnchor) {
      markerIcon.options.shadowUrl = '/static/img/markerIcons/' + markerData.shadowUrl + '.png';
      markerIcon.options.shadowSize = markerData.shadowSize;
      markerIcon.options.shadowAnchor = markerData.shadowAnchor;
    }

    marker.setIcon(markerIcon);
    return;
  }
}

function setTooltip(marker, markerData) {
  if (!markerData.caption && !markerData.draggable) return;

  let tooltipCaption = markerData.draggable ? 'Przemieść marker' : markerData.caption.toString();
  let tooltipOptions = { permanent: true, interactive: true, sticky: true };

  marker.bindTooltip(tooltipCaption, tooltipOptions);
}

function setPopup(marker, markerData) {
  if (markerData.popupCaption) {
    marker.bindPopup(markerData.popupCaption, {
      offset: new L.Point(0, -50),
      className: 'transparency-90'
    });
  }
}

function setOnClick(marker, onClick) {
  if (!isFunctionWithParameters(onClick)) return;

  marker.on('click', function () {
    onClick?.(marker);
  });
}

function setOnMoveEnd(marker, onMoveEnd) {
  if (!isFunctionWithParameters(onMoveEnd)) return;

  marker.on('moveend', function () {
    onMoveEnd?.(marker);
  });
}

function tooltipCoords(marker) {
  return L.latLng(marker.lat, marker.lng);
}

// function isFunction(func) {
//   return typeof func === 'function';
// }

function isFunctionWithParameters(func) {
  return typeof func === 'function' && func.length > 0;
}

export function updateMarkersIcon(marker) {
  setIcon(marker, marker.data);
}
