<script setup>
import { ref, watch } from 'vue';
import DictionaryTable from '../dictionaries/DictionaryTable.vue';
import Swal from 'sweetalert2';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import { handleResponseWithMessages } from '@/helpers/wwwHelper.js';
import { showFileInNewTab } from '@/helpers/utilsHelper.js';
import { getSwalConfiguration } from '../../helpers/swalHelper';
import ManualAcceptanceProtocolUpdatePopup from '../acceptanceProtocols/ManualAcceptanceProtocolUpdatePopup.vue';
import {
  getDateWithoutTime,
  transformDateToYYYYMM,
  getLocalFormattedDateAndTime
} from '../../helpers/dateFormatHelper';

const RepositoryFiles = RepositoryFactory.get('files');
const props = defineProps({ orderId: Number });
const emit = defineEmits(['onAcceptanceProtocolsFetched', 'onFileDeleted']);
const dictTableKey = ref(0);
const manualAcceptanceProtocolUpdatePopupRef = ref();

const tableColumns = [
  { key: 'number', header: 'Numer', computed: true },
  { key: 'created_at', header: 'Data dodania', computed: true },
  { key: 'amount', header: 'Liczba elementów' },
  { key: 'attachment', header: 'Załącznik', computed: true },
  { key: 'billing_month', header: 'Miesiąc rozliczeniowy', computed: true }
];

watch(
  () => props.orderId,
  () => dictTableKey.value++
);

function onDataFetched(dataObject) {
  emit('onAcceptanceProtocolsFetched', dataObject);
}

function onDeleteFileButtonClicked(fileToDelete) {
  const fileExists = fileToDelete?.resource?.id != null;

  Swal.fire(
    getSwalConfiguration(
      fileExists ? 'Usuwanie pliku' : 'Usuwanie protokołu odbioru',
      fileExists
        ? 'Czy na pewno chcesz usunąć wybrany plik?'
        : 'Czy na pewno chcesz usunąć wybrany protokół odbioru?'
    )
  ).then((result) => {
    if (result.value) {
      if (fileExists) {
        deleteFile(fileToDelete);
      } else {
        deleteAcceptanceProtocol(fileToDelete);
      }
    }
  });
}

async function deleteFile(fileToDelete) {
  var response = await RepositoryFiles.deleteFile(fileToDelete?.resource?.id);
  handleResponseWithMessages(response, 'Plik został usunięty.', onFileDeleted);
}

async function deleteAcceptanceProtocol(fileToDelete) {
  var response = await RepositoryFiles.deleteAcceptanceProtocol(fileToDelete?.id);
  handleResponseWithMessages(response, 'Protokół odbioru został usunięty.', onFileDeleted);
}

function onFileDeleted() {
  emit('onFileDeleted');
  dictTableKey.value++;
}

function onEditButtonClicked(acceptanceProtocol) {
  showManualPOUpdatePanel(acceptanceProtocol);
}

function showManualPOUpdatePanel(acceptanceProtocol) {
  console.log('showManualPOUpdatePanel');
  console.log(acceptanceProtocol);
  manualAcceptanceProtocolUpdatePopupRef.value.show(acceptanceProtocol);
}
</script>

<template>
  <div>
    <DictionaryTable
      :key="dictTableKey"
      v-if="props.orderId != null"
      :showHeader="false"
      :showBorder="false"
      :url="`order/${props.orderId}/POManual`"
      :columns="tableColumns"
      :customButtons="true"
      contentCustomStyle="padding: 0px"
      @dataFetched="onDataFetched"
    >
      <template #number="{ element }">
        {{ element.generated ? '-' : element.number }}
      </template>

      <template #created_at="{ element }">
        {{
          element.created_at != null
            ? getDateWithoutTime(getLocalFormattedDateAndTime(element.created_at))
            : '-'
        }}
      </template>

      <template #attachment="{ element }">
        {{ element.resource?.name ?? '-' }}
      </template>

      <template #billing_month="{ element }">
        {{
          element.billing_month
            ? transformDateToYYYYMM(getDateWithoutTime(element.billing_month))
            : '-'
        }}
      </template>

      <template #buttons="{ element }">
        <div style="display: flex; gap: 5px">
          <button
            v-if="element?.resource?.path"
            type="button"
            class="btn btn-outline-info"
            @click="showFileInNewTab(element)"
            onclick="event.stopPropagation()"
          >
            Pokaż
          </button>

          <button
            type="button"
            class="btn btn-outline-primary"
            @click="onEditButtonClicked(element)"
            onclick="event.stopPropagation()"
          >
            Edytuj
          </button>

          <button
            type="button"
            class="btn btn-outline-danger"
            @click="onDeleteFileButtonClicked(element)"
            onclick="event.stopPropagation()"
          >
            Usuń
          </button>
        </div>
      </template>
    </DictionaryTable>

    <ManualAcceptanceProtocolUpdatePopup
      ref="manualAcceptanceProtocolUpdatePopupRef"
      :orderId="props.orderId"
      @succeeded="dictTableKey++"
    />
  </div>
</template>

<style scoped></style>
