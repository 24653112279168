<script setup>
/* eslint-env jquery */
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import { isNotEmptyArray } from '../../helpers/utilsHelper';

const props = defineProps({
  id: { type: String, default: 'modalImageContainer' },
  sources: { type: Array, default: () => [] }
});

const currentSourceIndex = ref(-1);

const sourcesAvailable = computed(() => {
  return isNotEmptyArray(props.sources);
});

const currentSource = computed(() => {
  if (!sourcesAvailable.value) return null;
  if (currentSourceIndex.value >= props.sources.length) return null;

  return props.sources[currentSourceIndex.value];
});

const previousImageAvailable = computed(() => {
  if (!sourcesAvailable.value) return false;

  return currentSourceIndex.value - 1 >= 0;
});

const nextImageAvailable = computed(() => {
  if (!sourcesAvailable.value) return false;

  return currentSourceIndex.value + 1 < props.sources.length;
});

onMounted(() => {
  window.addEventListener('keydown', handleKeyDown);
});

onBeforeUnmount(() => {
  window.removeEventListener('keydown', handleKeyDown);
});

function handleKeyDown(event) {
  if (currentSource.value == null) return;

  if (event.key === 'ArrowLeft') {
    goToPreviousImage();
  } else if (event.key === 'ArrowRight') {
    goToNextImage();
  }
}

function hide() {
  currentSourceIndex.value = -1;
  $(`#${props.id}`).modal('hide');
}

function goToPreviousImage() {
  if (previousImageAvailable.value) {
    currentSourceIndex.value--;
  } else {
    console.log(`ModalGallery.goToPreviousImage(): no previous source available.`);
  }
}

function goToNextImage() {
  if (nextImageAvailable.value) {
    currentSourceIndex.value++;
  } else {
    console.log(`ModalGallery.goToNextImage(): no next source available.`);
  }
}

defineExpose({ currentSourceIndex });
</script>

<template>
  <div class="modal inmodal fade" :id="props.id" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="max-width: 100%; height: 100%">
      <div
        class="modal-content"
        style="background-color: transparent; box-shadow: none; height: 100%; padding: 1%"
      >
        <div
          class="modal-body text-center"
          style="background-color: transparent; height: 100%"
          @click="hide"
        >
          <img
            v-if="currentSource"
            :src="currentSource"
            class="current-img"
            style="max-height: 100%; max-width: 100%"
          />

          <i
            class="fa fa-chevron-left fa-3x previous-img"
            :style="previousImageAvailable ? 'color: whitesmoke' : 'color: darkgray; opacity: 25%'"
            title="Poprzedni"
            @click="goToPreviousImage()"
            onclick="event.stopPropagation()"
          />

          <i
            class="fa fa-chevron-right fa-3x next-img"
            :style="nextImageAvailable ? 'color: whitesmoke' : 'color: darkgray; opacity: 25%'"
            title="Następny"
            @click="goToNextImage()"
            onclick="event.stopPropagation()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.previous-img {
  position: absolute;
  left: 5px;
  top: 50%;
  color: whitesmoke;
}

.next-img {
  position: absolute;
  right: 5px;
  top: 50%;
  color: whitesmoke;
}

.current-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
