import { isNotEmptyArray } from '../helpers/utilsHelper.js';
import repository from './repository.js';

export default {
  async toggleActionVisibility(actionId, visible) {
    let formData = new FormData();
    formData.append('display_state', visible ? 1 : 0);

    return repository
      .post(`/action/${actionId}/changeMapDisplayState`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((response) => {
        return response?.data?.message ?? null;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async convertActionsIdsToOrderNumbers(actionsIds) {
    return repository
      .post(`/action/convertToOrderNumbers`, {
        actions: actionsIds
      })
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async getActionDataToEdit(id) {
    return repository.get(`/action/${id}/updateFields`).then((response) => {
      if (response.data.error == null) return response.data.data;
    });
  },

  async updateAction(
    action,
    actionTypeId,
    demElemTypeId,
    failureReasonTypeId,
    cableTypeForFailureId,
    declarationOfDisagreement
  ) {
    let formData = new FormData();

    if (action?.note != null) {
      formData.append('note', action.note);
    }

    if (action.geotimestamp?.lat) {
      formData.append('lat', action.geotimestamp.lat);
    }

    if (action.geotimestamp?.lon) {
      formData.append('lon', action.geotimestamp.lon);
    }

    if (isNotEmptyArray(action.storage)) {
      formData.append('storage', JSON.stringify(action.storage));
    }

    if (action.priority != null) {
      formData.append('priority', action.priority ? 1 : 0);
    }

    if (isNotEmptyArray(action.files)) {
      for (let index = 0; index < action.files.length; index++) {
        formData.append(`files[${index}][file]`, action.files[index]);
      }
    }

    if (action.osdStringified != null) {
      formData.append('osd', action.osdStringified);
    }

    formData.append('id_user_order', action.userOrderId);

    if (actionTypeId != null) {
      formData.append('id_action_type', actionTypeId);
    }

    if (demElemTypeId != null) {
      formData.append('id_dem_elem_type', demElemTypeId);
    }

    if (failureReasonTypeId != null) {
      formData.append('id_failure_action_reason', failureReasonTypeId);
    }

    if (cableTypeForFailureId != null) {
      formData.append('id_cable_type_for_failure', cableTypeForFailureId);
    }

    if (declarationOfDisagreement != null) {
      formData.append('declaration_of_disagreement', declarationOfDisagreement ? 1 : 0);
    }

    return repository
      .post(`/action/${action.id}/update`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then((response) => {
        return response?.data?.message ?? null;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async deleteAction(id) {
    return repository
      .delete(`/action/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async getSummaryTableForTransportPoints(page, itemsPerPage, placesIds) {
    let url = '/action/getSummaryTableForTransportPoints';
    let userFilterUrlEnding = '';

    return repository
      .post(`${url}?page=${page}&paginate=${itemsPerPage}${userFilterUrlEnding}`, {
        data: placesIds
      })
      .then((response) => {
        if (response.data.error == null) return response.data;
      })
      .catch((error) => {
        return { error };
      });
  },

  async getRegisterSummary(search, userFilter = null) {
    let userFilterUrlEnding = '';

    if (userFilter) {
      for (const property in userFilter) {
        if (Array.isArray(userFilter[property])) {
          userFilter[property].forEach((x) => {
            userFilterUrlEnding += `&${property}[]=${x}`;
          });
        } else userFilterUrlEnding += `&${property}=${userFilter[property]}`;
      }
    }

    return repository
      .get(`/action/registerSummary?search=${search}${userFilterUrlEnding}`)
      .then((response) => {
        if (response.data.error == null) return response.data;
      });
  },

  async requestActionsRegisterReport(authorId, search, userFilter) {
    let userFilterUrlEnding = '';

    if (userFilter) {
      for (const property in userFilter) {
        if (Array.isArray(userFilter[property])) {
          userFilter[property].forEach((x) => {
            userFilterUrlEnding += `&${property}[]=${x}`;
          });
        } else userFilterUrlEnding += `&${property}=${userFilter[property]}`;
      }
    }

    return repository
      .post(`/actionregisterraport/GenerateRaport?search=${search}${userFilterUrlEnding}`, {
        id_author: authorId
      })
      .then((response) => {
        return { success: response?.data?.data?.id != null };
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async getActionsRegisterReportContent(reportId) {
    return repository
      .get(`/actionregisterraport/${reportId}/GetRaportContent`)
      .then((response) => {
        if (response.data.error == null) return response.data;
      })
      .catch((error) => {
        return { error };
      });
  },

  async deleteActionsRegisterReport(id) {
    return repository
      .delete(`/actionregisterraport/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async getActionsRegisterRaportContentByUUID(uuid) {
    return repository
      .get(`/actionregisterraport/${uuid}/GetRaportContentByUUID`)
      .then((response) => {
        if (response.data.error == null) return response.data;
      })
      .catch((error) => {
        return { error };
      });
  }
};
