<script setup>
/* eslint-env jquery */
import { ref, computed } from 'vue';
import { useAuthStore } from '@/stores/auth';
import { useRouter, useRoute } from 'vue-router';
import NavigationPanel from '@/components/navigation/NavigationPanel.vue';
import { useSideMenuStore } from '../stores/sideMenu';
import {
  ACCESS_KEY_SIDE_MENU_GEODESY_PHASES,
  ACCESS_KEY_SIDE_MENU_ORDER_STATUSES,
  ACCESS_KEY_SIDE_MENU_USERS,
  ACCESS_KEY_SIDE_MENU_DICTIONARIES,
  ACCESS_KEY_SIDE_MENU_TRANSPORT_ORDERS,
  ACCESS_KEY_SIDE_MENU_TRANSPORT_ARCHIVED_ORDERS,
  ACCESS_KEY_SIDE_MENU_ARCHIVED_ORDERS,
  ACCESS_KEY_SIDE_MENU_REPORTS_HISTORY,
  ACCESS_KEY_SIDE_MENU_ACTIONS_REGISTER
} from '../data/constants/authConstants.js';
import { RADO_LOGO_SIZE } from '../data/constants/appConstants';

const ordersMenuItemHover = ref(false);
const transportOrdersMenuItemHover = ref(false);
const archivedTransportOrdersMenuItemHover = ref(false);
const authStore = useAuthStore();
const router = useRouter();
const route = useRoute();
const sideMenuStore = useSideMenuStore();

const loggedInUser = computed(() => authStore.loggedInUser);
const isOrdersMenuItemActive = computed(() => {
  return (
    route.fullPath.includes('orders') &&
    !isOrdersStatusesMenuItemActive.value &&
    !isOrdersGeodesyPhasesMenuItemActive.value
  );
});

const isOrdersStatusesMenuItemActive = computed(() => {
  return route.fullPath.includes('orders?status');
});

const isOrdersGeodesyPhasesMenuItemActive = computed(() => {
  return route.fullPath.includes('orders?geodesyPhase');
});

const isTransportOrdersMenuItemActive = computed(() => {
  return route.fullPath.includes('transportOrders');
});

const isActionsRegisterMenuItemActive = computed(() => {
  return (
    route.fullPath.includes('actionsRegisterTable') ||
    route.fullPath.includes('actionsReportsTable')
  );
});

const isArchivedTransportOrdersMenuItemActive = computed(() => {
  return route.fullPath.includes('archivedTransportOrders');
});

function logOut() {
  authStore.logOut().then(() => {
    router.push({ name: 'login' });
  });
}

function showLoggedInUserDetails() {
  var loggedInUserId = loggedInUser.value?.id;

  if (
    route.name === 'userDetails' &&
    route.params?.id != null &&
    loggedInUserId != null &&
    route.params?.id.toString() === loggedInUserId.toString()
  )
    return;

  router.push({ name: 'userDetails', params: { id: loggedInUserId } });
}

function toggleSideMenu() {
  if (localStorage.getItem('menuCollapsed')) {
    localStorage.removeItem('menuCollapsed');
  } else {
    localStorage.setItem('menuCollapsed', true);
  }
}

$(document).ready(function () {
  $('#side-menu').metisMenu();
});
</script>

<template>
  <div id="wrapper">
    <nav
      class="navbar-default navbar-static-side"
      style="background-color: #2f4050"
      role="navigation"
    >
      <div class="sidebar-collapse">
        <ul class="nav metismenu" id="side-menu">
          <li class="nav-header text-center">
            <div
              v-if="loggedInUser?.firstName && loggedInUser?.lastName"
              class="dropdown profile-element"
            >
              <img
                alt="User"
                class="rounded-circle"
                :src="
                  'https://eu.ui-avatars.com/api/?name=' +
                  loggedInUser.firstName +
                  '+' +
                  loggedInUser.lastName +
                  '&color=2f4050&background=f3f3f4'
                "
              />
              <a data-toggle="dropdown" class="dropdown-toggle" href="#">
                <span class="block m-t-xs font-bold"
                  >{{ loggedInUser.firstName }}
                  {{ loggedInUser.lastName }}
                </span>
                <span class="text-muted text-xs block">
                  {{ loggedInUser.type.name }}
                  <b class="caret"></b>
                </span>
              </a>
              <ul class="dropdown-menu animated fadeIn m-t-xs">
                <li>
                  <a class="dropdown-item" @click="showLoggedInUserDetails">Profil</a>
                </li>
                <li class="dropdown-divider"></li>
                <li><a class="dropdown-item" @click="logOut">Wyloguj</a></li>
              </ul>
            </div>
          </li>

          <li :class="[isOrdersMenuItemActive && 'active active2']">
            <a
              id="ordersTableMenuElement"
              href="#"
              @mouseover="ordersMenuItemHover = true"
              @mouseleave="ordersMenuItemHover = false"
            >
              <img
                :src="
                  isOrdersMenuItemActive || ordersMenuItemHover
                    ? '/static/img/order-white.png'
                    : '/static/img/order-gray.png'
                "
                alt="Zlecenia"
                width="13"
                height="13"
                style="margin-right: 6px"
              />
              <span class="nav-label">Zlecenia</span>
              <span class="fa arrow"></span>
            </a>
            <ul class="nav nav-second-level">
              <li
                :class="[
                  route.path === '/orders' &&
                    (route.query.mode === 'table' || route.query.mode == undefined) &&
                    'active'
                ]"
                style="width: 100%"
              >
                <router-link :to="{ path: '/orders', query: { mode: 'table' } }"
                  >Tabela</router-link
                >
              </li>

              <li
                :class="[route.path === '/orders' && route.query.mode === 'map' && 'active']"
                style="width: 100%"
              >
                <router-link :to="{ path: '/orders', query: { mode: 'map' } }">Mapa</router-link>
              </li>

              <li
                v-if="authStore.canAccess(ACCESS_KEY_SIDE_MENU_ARCHIVED_ORDERS)"
                :class="[
                  route.path === '/orders' && route.query.mode === 'archivedTable' && 'active'
                ]"
                style="width: 100%"
              >
                <router-link :to="{ path: '/orders', query: { mode: 'archivedTable' } }"
                  >Zlecenia archiwalne</router-link
                >
              </li>

              <li
                v-if="authStore.canAccess(ACCESS_KEY_SIDE_MENU_REPORTS_HISTORY)"
                :class="[
                  route.path === '/orders' && route.query.mode === 'reportsHistory' && 'active'
                ]"
                style="width: 100%"
              >
                <router-link :to="{ path: '/orders', query: { mode: 'reportsHistory' } }"
                  >Historia raportów</router-link
                >
              </li>
            </ul>
          </li>

          <li
            v-if="authStore.canAccess(ACCESS_KEY_SIDE_MENU_ORDER_STATUSES)"
            :class="[isOrdersStatusesMenuItemActive && 'active active2']"
          >
            <a href="#">
              <i class="fa fa-check-square-o"></i>
              <span class="nav-label">Statusy zleceń</span>
              <span class="fa arrow"></span>
            </a>

            <ul class="nav nav-second-level">
              <li
                v-for="orderStatus in sideMenuStore.orderStatuses"
                :key="orderStatus.id"
                :class="[
                  route.path === '/orders' &&
                    route.query.status === orderStatus.id.toString() &&
                    'active'
                ]"
                style="width: 100%"
              >
                <router-link :to="{ path: '/orders', query: { status: orderStatus.id } }">
                  {{ orderStatus.name }}
                </router-link>
              </li>
            </ul>
          </li>

          <li
            v-if="authStore.canAccess(ACCESS_KEY_SIDE_MENU_GEODESY_PHASES)"
            :class="[isOrdersGeodesyPhasesMenuItemActive && 'active active2']"
          >
            <a href="#">
              <i class="fa fa-globe"></i>
              <span class="nav-label">Etapy geodezji</span>
              <span class="fa arrow"></span>
            </a>

            <ul class="nav nav-second-level">
              <li
                v-for="geodesyPhase in sideMenuStore.orderGeodesyPhases"
                :key="geodesyPhase.id"
                :class="[
                  route.path === '/orders' &&
                    route.query.geodesyPhase === geodesyPhase.id.toString() &&
                    'active'
                ]"
                style="width: 100%"
              >
                <router-link :to="{ path: '/orders', query: { geodesyPhase: geodesyPhase.id } }">
                  {{ geodesyPhase.name }}
                </router-link>
              </li>
            </ul>
          </li>

          <li
            v-if="authStore.canAccess(ACCESS_KEY_SIDE_MENU_ACTIONS_REGISTER)"
            :class="[isActionsRegisterMenuItemActive && 'active active2']"
          >
            <a id="actionsRegisterMenuElement" href="#">
              <i class="fa fa-align-justify" />
              <span class="nav-label">Rejestr akcji</span>
              <span class="fa arrow"></span>
            </a>
            <ul class="nav nav-second-level">
              <li
                :class="[
                  route.path === '/actionsRegister' && route.query.mode !== 'reports' && 'active'
                ]"
                style="width: 100%"
              >
                <router-link :to="{ path: '/actionsRegister', query: { mode: 'actions' } }"
                  >Tabela akcji</router-link
                >
              </li>

              <li
                :class="[
                  route.path === '/actionsRegister' && route.query.mode === 'reports' && 'active'
                ]"
                style="width: 100%"
              >
                <router-link :to="{ path: '/actionsRegister', query: { mode: 'reports' } }"
                  >Raporty akcji</router-link
                >
              </li>
            </ul>
          </li>

          <li
            v-if="authStore.canAccess(ACCESS_KEY_SIDE_MENU_USERS)"
            :class="[route.path === '/users' && 'active']"
          >
            <router-link to="/users">
              <i class="fa fa-user-o"></i>
              <span class="nav-label">Użytkownicy</span>
            </router-link>
          </li>

          <li
            v-if="authStore.canAccess(ACCESS_KEY_SIDE_MENU_DICTIONARIES)"
            :class="[route.path === '/dictionaries' && 'active']"
          >
            <router-link to="/dictionaries">
              <i class="fa fa-edit"></i>
              <span class="nav-label">Słowniki</span>
            </router-link>
          </li>

          <li
            v-if="authStore.canAccess(ACCESS_KEY_SIDE_MENU_TRANSPORT_ORDERS)"
            :class="[isTransportOrdersMenuItemActive && 'active active2']"
          >
            <a
              id="transportOrdersTableMenuElement"
              href="#"
              @mouseover="transportOrdersMenuItemHover = true"
              @mouseleave="transportOrdersMenuItemHover = false"
            >
              <i class="fa fa-truck" />
              <span class="nav-label">Zlecenia transportowe</span>
              <span class="fa arrow"></span>
            </a>
            <ul class="nav nav-second-level">
              <li
                :class="[
                  route.path === '/transportOrders' && route.query.mode !== 'map' && 'active'
                ]"
                style="width: 100%"
              >
                <router-link :to="{ path: '/transportOrders', query: { mode: 'table' } }"
                  >Tabela</router-link
                >
              </li>

              <li
                :class="[
                  route.path === '/transportOrders' && route.query.mode === 'map' && 'active'
                ]"
                style="width: 100%"
              >
                <router-link :to="{ path: '/transportOrders', query: { mode: 'map' } }"
                  >Mapa</router-link
                >
              </li>
            </ul>
          </li>

          <li
            v-if="authStore.canAccess(ACCESS_KEY_SIDE_MENU_TRANSPORT_ARCHIVED_ORDERS)"
            :class="[isArchivedTransportOrdersMenuItemActive && 'active active2']"
          >
            <a
              id="transportOrdersTableMenuElement"
              href="#"
              @mouseover="archivedTransportOrdersMenuItemHover = true"
              @mouseleave="archivedTransportOrdersMenuItemHover = false"
            >
              <i class="fa fa-car" />
              <span class="nav-label">Archiwalne zlecenia transportowe</span>
              <span class="fa arrow"></span>
            </a>
            <ul class="nav nav-second-level">
              <li
                :class="[
                  route.path === '/archivedTransportOrders' &&
                    route.query.mode !== 'map' &&
                    'active'
                ]"
                style="width: 100%"
              >
                <router-link
                  :to="{
                    path: '/archivedTransportOrders',
                    query: { mode: 'table', archived: true }
                  }"
                  >Tabela</router-link
                >
              </li>

              <li
                :class="[
                  route.path === '/archivedTransportOrders' &&
                    route.query.mode === 'map' &&
                    'active'
                ]"
                style="width: 100%"
              >
                <router-link
                  :to="{ path: '/archivedTransportOrders', query: { mode: 'map', archived: true } }"
                  >Mapa</router-link
                >
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>

    <div id="page-wrapper" class="gray-bg">
      <div class="row border-bottom">
        <nav class="navbar navbar-static-top gray-bg" role="navigation" style="margin-bottom: 0">
          <div class="navbar-header">
            <a
              @click="toggleSideMenu()"
              class="navbar-minimalize minimalize-styl-2 btn btn-primary"
              href="#"
              ><i class="fa fa-bars"></i>
            </a>
          </div>

          <ul class="nav navbar-top-links navbar-right">
            <li>
              <img
                src="/static/img/rado_logo.png"
                alt="RADO - logo"
                :width="RADO_LOGO_SIZE[0]"
                :height="RADO_LOGO_SIZE[1]"
              />
            </li>
          </ul>
        </nav>
      </div>

      <NavigationPanel v-if="route.name != 'dashboard'" />

      <div class="wrapper wrapper-content animated fadeIn">
        <router-view :key="route.fullPath"></router-view>
      </div>
      <div class="footer">
        <div class="pull-right"><strong></strong></div>
        <div><strong>Copyright</strong> DKK Development &copy; 2023</div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.active2 {
  border-left: 4px solid #276fb3;
  background: #293846;
}

.menu-level-forth-a {
  padding-left: 72px;
}
</style>
