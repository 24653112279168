<script setup>
import { ref, watch } from 'vue';
import DictionaryTable from '../dictionaries/DictionaryTable.vue';
import Swal from 'sweetalert2';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import { handleResponseWithMessages } from '@/helpers/wwwHelper.js';
import { getSwalConfiguration } from '../../helpers/swalHelper';
import { generateReport } from '../../helpers/acceptanceProtocolReportGeneratorHelper';

const RepositoryFiles = RepositoryFactory.get('files');
const RepositoryAcceptanceProtocols = RepositoryFactory.get('acceptanceProtocols');
const props = defineProps({ orderId: Number });
const emit = defineEmits(['onAcceptanceProtocolsFetched', 'onAcceptanceProtocolDeleted']);
const dictTableKey = ref(0);

const tableColumns = [
  { key: 'report_creation_date', header: 'Data', computed: true },
  { key: 'comments_type', header: 'Typ uwag', computed: true },
  { key: 'ln_present', header: 'LN', computed: true },
  { key: 'po_type', header: 'Typu', computed: true }
];

watch(
  () => props.orderId,
  () => dictTableKey.value++
);

function onDataFetched(dataObject) {
  emit('onAcceptanceProtocolsFetched', dataObject);
}

function onDeleteAPButtonClicked(element) {
  Swal.fire(
    getSwalConfiguration(
      'Usuwanie protokołu odbioru',
      'Czy na pewno chcesz usunąć wybrany protokół odbioru?'
    )
  ).then((result) => {
    if (result.value) {
      deleteAcceptanceProtocol(element);
    }
  });
}

async function deleteAcceptanceProtocol(apToDelete) {
  var response = await RepositoryFiles.deleteAcceptanceProtocol(apToDelete?.id);
  handleResponseWithMessages(response, 'Protokół odbioru został usunięty.', onAPDeleted);
}

function onAPDeleted() {
  emit('onAcceptanceProtocolDeleted');
  dictTableKey.value++;
}

function onDownloadButtonClicked(acceptanceProtocol) {
  Swal.fire(
    getSwalConfiguration('Pobieranie raportu', 'Czy na pewno chcesz pobrać wybrany raport?')
  ).then((result) => {
    if (result.value) {
      generatePOReport(acceptanceProtocol);
    }
  });
}

function generatePOReport(acceptanceProtocol) {
  RepositoryAcceptanceProtocols.fetchReportData(acceptanceProtocol.id)
    .then((data) => {
      generateReport(data, 'Raport z protokołu odbioru');
    })
    .catch((error) => console.log(error))
    .finally(() => {});
}

function getAPContent(protocol) {
  if (protocol?.content == null) return null;
  return JSON.parse(protocol.content);
}
</script>

<template>
  <DictionaryTable
    :key="dictTableKey"
    v-if="props.orderId != null"
    :showHeader="false"
    :showBorder="false"
    :url="`order/${props.orderId}/POGenerated`"
    :columns="tableColumns"
    :customButtons="true"
    contentCustomStyle="padding: 0px"
    @dataFetched="onDataFetched"
  >
    <template #report_creation_date="{ element }">
      {{ getAPContent(element)?.raport_creation_date ?? '-' }}
    </template>

    <template #comments_type="{ element }">
      {{ getAPContent(element)?.comments_type ?? '-' }}
    </template>

    <template #ln_present="{ element }">
      <i v-if="getAPContent(element)?.ln_present === 1" class="fa fa-check text-navy" />
      <span v-else>-</span>
    </template>

    <template #po_type="{ element }">
      {{ getAPContent(element)?.po_type ?? '-' }}
    </template>

    <template #buttons="{ element }">
      <button
        type="button"
        class="btn btn-outline-primary"
        @click="onDownloadButtonClicked(element)"
        onclick="event.stopPropagation()"
      >
        Pobierz
      </button>

      <button
        type="button"
        class="btn btn-outline-danger ml-2"
        @click="onDeleteAPButtonClicked(element)"
        onclick="event.stopPropagation()"
      >
        Usuń
      </button>
    </template>
  </DictionaryTable>
</template>

<style scoped></style>
