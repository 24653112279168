<script setup>
import { ref, computed, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useAuthStore } from '@/stores/auth';
import Swal from 'sweetalert2';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import ModalBase from '@/components/utils/ModalBase.vue';
import PasswordChange from '@/components/auth/PasswordChange.vue';
import UserPasswordUpdate from '../auth/UserPasswordUpdate.vue';
import { getSwalConfiguration } from '../../helpers/swalHelper';
import {
  ACCESS_KEY_USER_UPDATE,
  ACCESS_KEY_OTHER_USER_PASSWORD_UPDATE
} from '../../data/constants/authConstants';

const RepositoryUsers = RepositoryFactory.get('users');

const props = defineProps({ id: Number });
const emit = defineEmits(['userInfoReceived']);

const isLoading = ref(false);
const user = ref(null);
const router = useRouter();
const authStore = useAuthStore();
const modalBase = ref();
const updateUsersPasswordModalBase = ref();

const loggedInUser = computed(() => authStore.loggedInUser);

const currentUserLoggedIn = computed(() => {
  return user.value?.id != null && user.value?.id === loggedInUser.value?.id;
});

watch(
  () => props.id,
  () => getUserDetails()
);

async function getUserDetails() {
  isLoading.value = true;
  user.value = await RepositoryUsers.getUserDetailsGeneral(props.id);
  emit('userInfoReceived', user.value?.type);
  isLoading.value = false;
}

function editUser() {
  router.push({ name: 'userAddEdit', params: { id: props.id } });
}

function changeOwnPassword() {
  Swal.fire(getSwalConfiguration('Czy chcesz zmienić hasło?', undefined)).then((result) => {
    if (result.value) {
      showChangeOwnPasswordModal();
    }
  });
}

function showChangeOwnPasswordModal() {
  modalBase.value.show();
}

function hideChangeOwnPasswordModal() {
  modalBase.value.hide();
}

function updateUsersPassword() {
  Swal.fire(
    getSwalConfiguration(
      `Czy chcesz zaktualizować hasło użytkownika ${user.value?.firstName ?? ''} ${
        user.value?.lastName ?? ''
      }?`,
      undefined
    )
  ).then((result) => {
    if (result.value) {
      showUpdateUsersPasswordModal();
    }
  });
}

function showUpdateUsersPasswordModal() {
  updateUsersPasswordModalBase.value.show();
}

function hideUpdateUsersPasswordModal() {
  updateUsersPasswordModalBase.value.hide();
}

// created
getUserDetails();
</script>

<template>
  <div class="ibox">
    <div class="ibox-content" :class="{ 'sk-loading': isLoading }">
      <div class="sk-spinner sk-spinner-three-bounce">
        <div class="sk-bounce1"></div>
        <div class="sk-bounce2"></div>
        <div class="sk-bounce3"></div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="m-b-md">
            <button
              v-if="authStore.canAccess(ACCESS_KEY_USER_UPDATE)"
              class="btn btn-outline btn-primary btn-xs float-right"
              @click="editUser"
            >
              Edytuj użytkownika
            </button>
            <button
              v-if="currentUserLoggedIn"
              class="btn btn-outline btn-primary btn-xs float-right mr-1"
              @click="changeOwnPassword"
            >
              Zmień hasło
            </button>
            <button
              v-else-if="authStore.canAccess(ACCESS_KEY_OTHER_USER_PASSWORD_UPDATE)"
              class="btn btn-outline btn-primary btn-xs float-right mr-1"
              @click="updateUsersPassword"
            >
              Aktualizuj hasło użytkownika
            </button>
            <h2>{{ user?.firstName ?? '' }} {{ user?.lastName ?? '' }}</h2>
          </div>
        </div>
      </div>
      <!-- commented user icon <div class="row">
        <img
          alt="User"
          class="rounded-circle"
          :src="
            'https://eu.ui-avatars.com/api/?name=' +
              user.firstName +
              '+' +
              user.lastName +
              '&color=2f4050&background=f3f3f4'
          "
        />
      </div> -->
      <div class="row">
        <div class="col-lg-12">
          <dl class="row mb-0">
            <div class="col-sm-4 text-sm-right">
              <dt>Numer telefonu:</dt>
            </div>
            <div class="col-sm-8 text-sm-left">
              <dd class="mb-1">{{ user?.phone ?? '-' }}</dd>
            </div>
          </dl>
          <dl class="row mb-0">
            <div class="col-sm-4 text-sm-right">
              <dt>Adres e-mail:</dt>
            </div>
            <div class="col-sm-8 text-sm-left">
              <dd class="mb-1">{{ user?.email ?? '-' }}</dd>
            </div>
          </dl>
          <dl class="row mb-0">
            <div class="col-sm-4 text-sm-right">
              <dt>Typ użytkownika:</dt>
            </div>
            <div class="col-sm-8 text-sm-left">
              <dd class="mb-1">
                {{ user?.type?.name ?? '-' }}
              </dd>
            </div>
          </dl>
          <dl class="row mb-0">
            <div class="col-sm-4 text-sm-right">
              <dt>Aktywny:</dt>
            </div>
            <div class="col-sm-8 text-sm-left">
              <dd class="mb-1">
                <i v-if="user?.active" class="fa fa-check text-navy" />
                <i v-else-if="user?.active === 0" class="fa fa-times text-danger" />
                <span v-else>-</span>
              </dd>
            </div>
          </dl>

          <dl class="row mb-0">
            <div class="col-sm-4 text-sm-right">
              <dt>Dwuetapowa weryfikacja:</dt>
            </div>
            <div class="col-sm-8 text-sm-left">
              <dd class="mb-1">
                <i v-if="user?.additional_verify === 1" class="fa fa-check text-navy" />
                <i v-else-if="user?.additional_verify === 0" class="fa fa-times text-danger" />
                <span v-else>-</span>
              </dd>
            </div>
          </dl>

          <dl v-if="user?.color" class="row mb-0">
            <div class="col-sm-4 text-sm-right">
              <dt>Kolor użytkownika:</dt>
            </div>
            <div class="col-sm-8 text-sm-left">
              <dd class="mb-1">
                <div
                  style="width: 40px; height: 20px; border-radius: 5px"
                  :style="'background-color: ' + user.color"
                ></div>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>

    <ModalBase ref="modalBase" maxWidth="40%">
      <PasswordChange @on-completed="hideChangeOwnPasswordModal()" />
    </ModalBase>

    <ModalBase ref="updateUsersPasswordModalBase" maxWidth="40%">
      <UserPasswordUpdate :user="user" @on-completed="hideUpdateUsersPasswordModal()" />
    </ModalBase>
  </div>
</template>

<style scoped></style>
