<script setup>
import { ref } from 'vue';
import ModalBase from '@/components/utils/ModalBase.vue';
import ModalBody from '@/components/utils/ModalBody.vue';
import ActionAdd from './ActionAdd.vue';

const props = defineProps({
  orderId: Number,
  demPointsForMap: { type: Array, default: null },
  actionsWithNoDemPointForMap: { type: Array, default: null },
  selectedDemPointsIds: { type: Array, default: null }
});
const emit = defineEmits(['succeeded']);
const modalBase = ref();
const actionAddKey = ref(0);
const modalTitle = ref('Dodaj akcję');

function show() {
  modalBase.value.show();
  actionAddKey.value++;
}

function onActionSuccessfullyAdded() {
  modalBase.value.hide();
  emit('succeeded');
}

function onModalTitleChanged(newTitle) {
  modalTitle.value = newTitle;
}

defineExpose({ show });
</script>

<template>
  <ModalBase ref="modalBase">
    <ModalBody>
      <template v-slot:title> {{ modalTitle }} </template>
      <template v-slot:body>
        <ActionAdd
          v-if="actionAddKey > 0"
          :key="actionAddKey"
          :demPointsForMap="props.demPointsForMap"
          :actionsWithNoDemPointForMap="props.actionsWithNoDemPointForMap"
          :selectedDemPointsIds="props.selectedDemPointsIds"
          :orderId="props.orderId"
          @succeeded="onActionSuccessfullyAdded"
          @modalTitleChanged="onModalTitleChanged"
        />
      </template>
    </ModalBody>
  </ModalBase>
</template>

<style scoped></style>
