<script setup>
import { ref, computed, watch, onMounted, unref, nextTick } from 'vue';
import TransportOrdersTable from './TransportOrdersTable.vue';
import TransportOrdersTableFilterAndSearch from './TransportOrdersTableFilterAndSearch.vue';
import TransportOrdersMap from './TransportOrdersMap.vue';
import TransportReports from './TransportReports.vue';
import { useNavigationPanelStore } from '../../stores/navigationPanel';
import { navItems } from '../../helpers/navigationPanelHelper';

const props = defineProps({
  mode: { type: String, default: null },
  archived: { type: String, default: null }
});

const transportTableComponent = ref();
const transportOrdersMapComponent = ref();
const alreadyLoaded = ref({ table: false, map: false, reports: false });
const navigationPanelStore = useNavigationPanelStore();
const mode = ref(null);
const searchBox = ref('');
const userFilter = ref(null);
const filterPanel = ref({});
const filterPanelDisplay = ref({});
const appliedFilter = ref(new Object());

mode.value = props.mode === 'map' ? 'map' : 'table';

const archived = computed(() => {
  return props.archived === 'true';
});

const url = computed(() => {
  return props.archived ? 'ordertransport/register_archived' : 'ordertransport/register';
});

watch(
  () => props.mode,
  () => {
    mode.value = unref(props.mode);
  }
);

watch(mode, () => {
  onTabClicked(mode.value);
  switchTabs();
});

// created
navigationPanelStore.setNavigationPanelItems(navItems.TRANSPORT_ORDERS_LIST);

if (mode.value === 'table') {
  alreadyLoaded.value.table = true;
} else if (mode.value === 'map') {
  alreadyLoaded.value.map = true;
} else if (mode.value === 'reports') {
  alreadyLoaded.value.reports = true;
}

// mounted
onMounted(() => {
  switchTabs();
});

//methods
function onTabClicked(tabId) {
  switch (tabId) {
    case 'table':
      alreadyLoaded.value.table = true;
      break;
    case 'map':
      alreadyLoaded.value.map = true;
      transportOrdersMapComponent.value?.refreshMap();
      break;
    case 'reports':
      alreadyLoaded.value.reports = true;
      break;
  }
}

function switchTabs() {
  //TODO maybe to refactor
  if (mode.value === 'map') {
    alreadyLoaded.value.map = true;
    document.getElementById('tab-1-header').classList.remove('active');
    document.getElementById('tab-1').classList.remove('active');
    document.getElementById('tab-3-header').classList.remove('active');
    document.getElementById('tab-3').classList.remove('active');
    document.getElementById('tab-2-header').classList.add('active');
    document.getElementById('tab-2').classList.add('active');
  } else if (mode.value === 'table') {
    alreadyLoaded.value.table = true;
    document.getElementById('tab-1-header').classList.add('active');
    document.getElementById('tab-1').classList.add('active');
    document.getElementById('tab-2-header').classList.remove('active');
    document.getElementById('tab-2').classList.remove('active');
    document.getElementById('tab-3-header').classList.remove('active');
    document.getElementById('tab-3').classList.remove('active');
  } else if (mode.value === 'reports') {
    alreadyLoaded.value.reports = true;
    document.getElementById('tab-3-header').classList.add('active');
    document.getElementById('tab-3').classList.add('active');
    document.getElementById('tab-2-header').classList.remove('active');
    document.getElementById('tab-2').classList.remove('active');
    document.getElementById('tab-1-header').classList.remove('active');
    document.getElementById('tab-1').classList.remove('active');
  }
}

function onFetchDataWithFiltersRequest({ filter }) {
  userFilter.value = filter;

  if (alreadyLoaded.value.table) {
    nextTick(() => {
      transportTableComponent.value?.fetchData();
    });
  }

  if (alreadyLoaded.value.map) {
    nextTick(() => {
      transportOrdersMapComponent.value?.forceFetch();
    });
  }
}

function onSearchChangedInFilterPanel(newSearchValue) {
  searchBox.value = newSearchValue;
}

function updateFiltersAfterFetch() {
  appliedFilter.value = Object.assign({}, filterPanel.value);
}
</script>

<template>
  <div class="col-lg-12">
    <div class="tabs-container">
      <ul class="nav nav-tabs" role="tablist">
        <li @click="onTabClicked('table')">
          <a class="nav-link active" id="tab-1-header" data-toggle="tab" href="#tab-1">
            <span v-if="archived">Archiwalne zlecenia transportowe</span>
            <span v-else>Zlecenia transportowe</span>
          </a>
        </li>
        <li @click="onTabClicked('map')">
          <a class="nav-link" id="tab-2-header" data-toggle="tab" href="#tab-2"
            >Mapa ze zleceniami transportowymi</a
          >
        </li>
        <li @click="onTabClicked('reports')">
          <a class="nav-link" id="tab-3-header" data-toggle="tab" href="#tab-3">Raporty</a>
        </li>
      </ul>
      <div class="tab-content">
        <div role="tabpanel" id="tab-1" class="tab-pane active">
          <div class="panel-body">
            <div v-if="alreadyLoaded.table">
              <TransportOrdersTableFilterAndSearch
                @fetchData="onFetchDataWithFiltersRequest"
                :searchBox="searchBox"
                :filterPanel="filterPanel"
                :filterPanelDisplay="filterPanelDisplay"
                :appliedFilter="appliedFilter"
                @searchChanged="onSearchChangedInFilterPanel"
                @updateFilterPanel="
                  (value) => {
                    filterPanel = value;
                  }
                "
                @updateFilterPanelDisplay="
                  (value) => {
                    filterPanelDisplay = value;
                  }
                "
              />

              <TransportOrdersTable
                ref="transportTableComponent"
                :filter="{ url }"
                :showBorder="false"
                :showHeader="false"
                :longerSearchBar="false"
                :searchFromParent="searchBox"
                :filterFromParent="userFilter"
                @afterSuccessfulFetch="updateFiltersAfterFetch"
              />
            </div>
          </div>
        </div>
        <div role="tabpanel" id="tab-2" class="tab-pane">
          <div class="panel-body">
            <TransportOrdersMap
              ref="transportOrdersMapComponent"
              v-if="alreadyLoaded.map"
              :filterFromParent="userFilter"
              :searchFromParent="searchBox"
              @afterSuccessfulFetch="updateFiltersAfterFetch"
            >
              <template v-slot:globalFilterPanel>
                <TransportOrdersTableFilterAndSearch
                  @fetchData="onFetchDataWithFiltersRequest"
                  :hideSearchBar="false"
                  :oneFilterPerRow="true"
                  :searchBox="searchBox"
                  :filterPanel="filterPanel"
                  :filterPanelDisplay="filterPanelDisplay"
                  :appliedFilter="appliedFilter"
                  customSearchBarCssClass="col-sm-6"
                  @updateFilterPanel="
                    (value) => {
                      filterPanel = value;
                    }
                  "
                  @updateFilterPanelDisplay="
                    (value) => {
                      filterPanelDisplay = value;
                    }
                  "
                  @searchChanged="onSearchChangedInFilterPanel"
                />
              </template>
            </TransportOrdersMap>
          </div>
        </div>
        <div role="tabpanel" id="tab-3" class="tab-pane">
          <div class="panel-body">
            <TransportReports v-if="alreadyLoaded.reports" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
