<script setup>
import { useRoute } from 'vue-router';
import ViewMain from '@/views/ViewMain.vue';
import ViewLogin from '@/views/ViewLogin.vue';
import ViewForgottenPassword from '@/views/ViewForgottenPassword.vue';
import ViewResetPassword from '@/views/ViewResetPassword.vue';
import OrdersRegisterDownload from './views/OrdersRegisterDownload.vue';
import OrderActionGallery from './views/OrderActionGallery.vue';
import ActionsRegisterDownload from './views/ActionsRegisterDownload.vue';
import MapPointsSharedMap from './views/MapPointsSharedMap.vue';

const route = useRoute();
</script>

<template>
  <div>
    <ViewLogin v-if="route.name === 'login'" />
    <ViewForgottenPassword v-else-if="route.name === 'forgotPassword'" />
    <ViewResetPassword v-else-if="route.name === 'resetPassword'" />
    <MapPointsSharedMap v-else-if="$route.name === 'mapPointsSharedMap'" />
    <OrdersRegisterDownload v-else-if="$route.name === 'ordersRegisterDownload'" />
    <ActionsRegisterDownload v-else-if="$route.name === 'actionsRegisterDownload'" />
    <OrderActionGallery v-else-if="$route.name === 'orderActionGallery'" />
    <ViewMain v-else />
  </div>
</template>

<style>
.marker-label {
  background-color: #574d4d;
  text-align: center;
}

.marker-label-text {
  color: white;
  padding-top: 1px;
}

.grayscale-75-percent {
  filter: grayscale(75%);
}

.half-transparent {
  filter: opacity(50%);
}

.transparency-30 {
  filter: opacity(30%);
}

.transparency-90 {
  filter: opacity(90%);
}
</style>
