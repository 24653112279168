<script setup>
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { RepositoryFactory } from '@/data/repositoryFactory';
import { PAGINATION_LIMIT } from '../../data/constants/appConstants';
import {
  transportOrdersSortingOptions as sortingOptions,
  getSortingIcon,
  getSortingDirection
} from '@/helpers/sortTableHelper.js';
import PaginationCountPicker from '@/components/utils/PaginationCountPicker.vue';
import { getDateWithoutTime } from '@/helpers/dateFormatHelper';
import ServerErrorPanel from '@/components/utils/ServerErrorPanel.vue';
import TableElementsInfo from '@/components/utils/TableElementsInfo.vue';
import { usePaginationStore } from '@/stores/storePagination';
import { Bootstrap4Pagination } from 'laravel-vue-pagination';
import { isNotEmptyArray } from '../../helpers/utilsHelper';
import { TRANSPORT_ORDERS_TABLE_PAGINATION_KEY } from '../../data/paginationInjectionKeys';

const RepositoryTransportOrders = RepositoryFactory.get('transportOrders');

const props = defineProps({
  filter: { type: Object, default: null },
  selectableItems: { type: Boolean, default: false },
  showHeader: { type: Boolean, default: true },
  showBorder: { type: Boolean, default: true },
  longerSearchBar: { type: Boolean, default: false },
  showTitle: { type: Boolean, default: true },
  initialUserFilter: { type: Object, default: null },
  filterFromParent: { type: Object, default: null },
  searchFromParent: { type: String, default: '' }
});

const emit = defineEmits(['afterSuccessfulFetch', 'on-completed']);

const router = useRouter();
const isLoading = ref(false);
const ordersObject = ref({});
const currentSort = ref({ by: null, direction: null });
const paginationStore = usePaginationStore();
const ordersTableFilterAndSearchChildComponent = ref(null);

// created
fetchData();

const deadlineSortIcon = computed(() => {
  return getSortingIcon(currentSort.value, sortingOptions.DEADLINE);
});

const firstOrder = computed(() => {
  return ordersObject.value?.data?.[0] ?? null;
});

function fetchData(page = 1) {
  isLoading.value = true;

  RepositoryTransportOrders.getTransportOrdersObject(
    page,
    paginationStore.getPaginationItemsCount(TRANSPORT_ORDERS_TABLE_PAGINATION_KEY),
    props.searchFromParent,
    currentSort.value.by,
    currentSort.value.direction,
    props.filter,
    props.filterFromParent
  )
    .then((data) => {
      ordersObject.value = data;
      emit('afterSuccessfulFetch');
      ordersTableFilterAndSearchChildComponent.value?.updateAppliedFilters();
    })
    .catch((error) => console.log(error))
    .finally(() => {
      isLoading.value = false;
      //eslint-disable-next-line no-undef
      $('[data-toggle="tooltip"]').tooltip({ placement: 'right' });
    });
}

function sortBy(newSortBy) {
  currentSort.value.direction = getSortingDirection(currentSort.value, newSortBy);
  currentSort.value.by = newSortBy;
  fetchData();
}

function showDetails(order) {
  let routeData = router.resolve({ name: 'transportOrderDetails', params: { id: order.id } });
  window.open(routeData.href, '_blank');
}

function pickItem(order) {
  emit('on-completed', order);
}

function onItemsPerPageChanged() {
  fetchData();
}

defineExpose({ fetchData });
</script>

<template>
  <div class="ibox">
    <div
      v-if="props.showHeader"
      class="ibox-title"
      :style="props.showBorder ? '' : 'border-style: none'"
    >
      <h5 v-if="props.showTitle">Zlecenia transportowe</h5>
    </div>
    <div
      class="ibox-content"
      :class="{ 'sk-loading': isLoading }"
      :style="props.showBorder ? '' : 'border-style: none'"
    >
      <div class="sk-spinner sk-spinner-three-bounce">
        <div class="sk-bounce1"></div>
        <div class="sk-bounce2"></div>
        <div class="sk-bounce3"></div>
      </div>

      <TableElementsInfo :metaObject="ordersObject ? ordersObject.meta : null" />

      <div v-if="isNotEmptyArray(ordersObject?.data)" class="table-responsive">
        <table class="table table-striped first-column-fixed-table">
          <thead>
            <tr>
              <th v-if="firstOrder?.order_number !== undefined" style="white-space: nowrap">
                Nr zlecenia
              </th>
              <th v-if="firstOrder?.order_range !== undefined" style="white-space: nowrap">
                Zakres prac
              </th>
              <th v-if="firstOrder?.target !== undefined" style="white-space: nowrap">
                Elementy do likwidacji
              </th>
              <th v-if="firstOrder?.done !== undefined" style="white-space: nowrap">
                Elementy zebrane
              </th>
              <th v-if="firstOrder?.gmina !== undefined" style="white-space: nowrap">Gmina</th>
              <th v-if="firstOrder?.region !== undefined" style="white-space: nowrap">Region</th>
              <th v-if="firstOrder?.localisation !== undefined" style="white-space: nowrap">
                Lokalizacja
              </th>
              <th v-if="firstOrder?.storage_date !== undefined">Termin składowania</th>
              <th
                v-if="firstOrder?.deadline !== undefined"
                @click="sortBy(sortingOptions.DEADLINE)"
                style="cursor: pointer; white-space: nowrap"
              >
                Termin realizacji <span v-html="deadlineSortIcon" />
              </th>
              <th
                v-if="firstOrder?.order_status !== undefined"
                style="white-space: nowrap; text-align: center"
              >
                Status zlecenia
              </th>
              <th v-if="firstOrder?.delay !== undefined" style="white-space: nowrap">
                Opóźnienie (dni)
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="order in ordersObject.data"
              :key="order.id"
              @click="selectableItems ? pickItem(order) : showDetails(order)"
              style="cursor: pointer"
            >
              <td v-if="order.order_number !== undefined" style="white-space: nowrap">
                {{ order?.order_number ?? '-' }}
              </td>

              <td v-if="order.order_range !== undefined" style="white-space: nowrap">
                {{
                  order?.order_range != null && order?.order_range !== '' ? order?.order_range : '-'
                }}
              </td>

              <td v-if="order.target !== undefined" style="white-space: nowrap">
                {{ order.target ?? '-' }}
              </td>

              <td v-if="order.done !== undefined" style="white-space: nowrap">
                {{ order.done ?? '-' }}
              </td>

              <td v-if="order.gmina !== undefined" style="white-space: nowrap">
                {{ order?.gmina ?? '-' }}
              </td>

              <td v-if="order.region !== undefined" style="white-space: nowrap">
                {{ order?.region ?? '-' }}
              </td>

              <td v-if="order.localisation !== undefined" style="white-space: nowrap">
                {{ order?.localisation ?? '-' }}
              </td>

              <td v-if="order.storage_date !== undefined" style="white-space: nowrap">
                {{ order?.storage_date != null ? getDateWithoutTime(order.storage_date) : '-' }}
              </td>

              <td v-if="order.deadline !== undefined" style="white-space: nowrap">
                {{ order?.deadline != null ? getDateWithoutTime(order.deadline) : '-' }}
              </td>

              <td
                v-if="order.order_status !== undefined"
                style="white-space: nowrap; text-align: center"
              >
                <span
                  class="label label-primary"
                  :style="
                    order?.order_status?.color != null
                      ? 'background-color:' + order.order_status.color
                      : ''
                  "
                >
                  {{ order?.order_status?.name ?? '-' }}</span
                >
              </td>

              <td v-if="order.delay !== undefined" style="white-space: nowrap">
                {{ order?.delay ?? '-' }}
              </td>

              <td style="white-space: nowrap">
                <div class="float-right">
                  <button
                    class="btn btn-outline btn-primary btn-xs mr-2"
                    type="button"
                    @click="props.selectableItems ? pickItem(order) : showDetails(order)"
                    onclick="event.stopPropagation()"
                  >
                    <span v-if="props.selectableItems">Wybierz</span>
                    <span v-else>Pokaż</span>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else-if="ordersObject?.error">
        <ServerErrorPanel @onRetry="fetchData" />
      </div>
      <div v-else>
        <p class="text-center mt-3">Brak elementów do wyświetlenia</p>
      </div>

      <div class="row mt-3">
        <div class="col-xl-6 offset-xl-3">
          <Bootstrap4Pagination
            v-if="ordersObject?.data"
            align="center"
            :data="ordersObject"
            :limit="PAGINATION_LIMIT"
            @pagination-change-page="fetchData"
          ></Bootstrap4Pagination>
        </div>

        <div class="col-xl-3">
          <PaginationCountPicker
            :paginationKey="TRANSPORT_ORDERS_TABLE_PAGINATION_KEY"
            v-show="!isLoading"
            @selectionChanged="onItemsPerPageChanged"
          ></PaginationCountPicker>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.first-column-fixed-table th:first-child,
td:first-child {
  position: sticky;
  left: 0px;
}

.first-column-fixed-table > tbody > tr:nth-child(odd) > td {
  background-color: rgb(242, 242, 242);
}

.first-column-fixed-table > tbody > tr:nth-child(even) > td,
.first-column-fixed-table > thead > tr:first-child > th {
  background-color: white;
}
</style>
