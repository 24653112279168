<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import ClusterMap from '@/components/map/ClusterMap.vue';
import Swal from 'sweetalert2';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import { handleResponseWithMessages } from '@/helpers/wwwHelper.js';
import { useMapLogic } from '../../composables/mapLogic';
import {
  getDemPointsForMapMarkers,
  getActionsWithNoDemPointForMapMarkers,
  getWorkersStartStopMarkers
} from '@/helpers/actionMarkersHelper.js';
import {
  REMOVE_ACTION_BUTTON_TYPE_NAME,
  HIDE_ACTION_BUTTON_TYPE_NAME,
  COPY_TO_CLIPBOARD_BUTTON_TYPE_NAME,
  SHOW_ACTION_GALLERY_BUTTON_TYPE_NAME,
  UPDATE_ACTION_BUTTON_TYPE_NAME,
  ACTIVATE_DEACTIVATE_DEMPOINT_BUTTON_TYPE_NAME,
  SHOW_COORDS_ON_MAP_BUTTON_TYPE_NAME
} from '../../data/constants/buttonsNamesConstants.js';
import { copyToClipboard } from '../../helpers/utilsHelper';
import OrderSummary from '@/components/orders/OrderSummary.vue';
import ActionsTable from '../actions/ActionsTable.vue';
import { isNotEmptyArray } from '../../helpers/utilsHelper';
import { getSwalConfiguration } from '../../helpers/swalHelper';
import ManualOrderPerform from '../actions/ManualOrderPerform.vue';
import ActionEditModalView from '../actions/ActionEditModalView.vue';
import { createToaster } from '@meforma/vue-toaster';
import { useAuthStore } from '../../stores/auth';
import { ACCESS_KEY_ACTION_ADD_BUTTON } from '../../data/constants/authConstants';
import { addGroupsCountInfoToActions } from '../../helpers/actionMarkersHelper.js';
import { getAvailableActionTypes } from '../../helpers/actionsHelper.js';
import { showOnGoogleMaps } from '../../helpers/navigator';

const RepositoryOrders = RepositoryFactory.get('orders');
const RepositoryActions = RepositoryFactory.get('actions');
const RepositoryDemPoints = RepositoryFactory.get('demPoints');

const props = defineProps({ orderId: Number, orderNumber: String, order: Object });
const emit = defineEmits(['refreshAll']);
const router = useRouter();
const route = useRoute();
const isLoading = ref(false);
const demPointsForMap = ref(null);
const actionsWithNoDemPointForMap = ref(null);
const workersStartStopData = ref(null);
const actionsTable = ref();
const alreadyLoadedTabs = ref({ orderSummary: true, actionsTable: false });
const disabledActionTypesIds = ref([]);
const disabledFailureReasonsIds = ref([]);
const disabledUserOrdersIds = ref([]);
const enabledUserOrdersStartStopsIds = ref([]);
const showOnlyLastActionsFilterEnabled = ref(false);
const manualOrderPerform = ref(null);
const actionEditModalView = ref();
const toaster = createToaster({ position: 'top-right' });
const authStore = useAuthStore();
const {
  clusterMap,
  selectedMarkerIds,
  onSelectedMarkersChanged,
  onSingleMarkerSelected,
  resetSelectedIfCtrl,
  updateMarker,
  focusOnMarker
} = useMapLogic();

const idActionForSearch = computed(() => {
  return parseInt(route.params.actionId);
});

const availableActionTypes = computed(() => {
  return getAvailableActionTypes(demPointsForMap.value, actionsWithNoDemPointForMap.value);
});

watch(
  () => props.orderId,
  () => {
    initialize();
  }
);

function initialize() {
  fetchMarkersInfo(true);
}

async function fetchMarkersInfo(setBoundsAfterRefresh = false) {
  isLoading.value = true;

  demPointsForMap.value = await RepositoryOrders.getDemPointsForMap(props.orderId);
  actionsWithNoDemPointForMap.value = await RepositoryOrders.getActionsWithNoDemPointForMap(
    props.orderId
  );
  addGroupsCountInfoToActions(demPointsForMap.value, actionsWithNoDemPointForMap.value);

  workersStartStopData.value = await RepositoryOrders.getWorkersStartStopData(props.orderId);
  prepareMarkersForMap(setBoundsAfterRefresh);

  isLoading.value = false;
}

function prepareMarkersForMap(setBoundsAfterRefresh = false) {
  const demPointsForMapMarkers = getDemPointsForMapMarkers(
    demPointsForMap.value,
    disabledActionTypesIds.value,
    disabledFailureReasonsIds.value,
    disabledUserOrdersIds.value,
    showOnlyLastActionsFilterEnabled.value,
    true,
    false,
    '',
    true,
    authStore,
    false,
    null
  );

  const actionsWithNoDemPointForMapMarkers = getActionsWithNoDemPointForMapMarkers(
    actionsWithNoDemPointForMap.value,
    disabledActionTypesIds.value,
    disabledFailureReasonsIds.value,
    disabledUserOrdersIds.value,
    true,
    '',
    authStore,
    false,
    null
  );

  const workersStartStopMarkers = getWorkersStartStopMarkers(
    workersStartStopData.value,
    enabledUserOrdersStartStopsIds.value
  );

  const markers = demPointsForMapMarkers.concat(
    actionsWithNoDemPointForMapMarkers,
    workersStartStopMarkers
  );

  clusterMap.value.initializeMap({
    markersData: markers,
    additionalAction: onMapReady,
    setBoundsAfterRefresh
  });
}

function refreshMapBounds() {
  clusterMap.value.refreshMapAfterTick();
}

function onMapClickableClicked(clickable) {
  let actionId = clickable.getAttribute('id_action');
  let clickActionType = clickable.getAttribute('click_action_type');

  if (clickActionType == undefined) return;

  const lat = clickable.getAttribute('lat');
  const lon = clickable.getAttribute('lon');
  const actionTypeName = clickable.getAttribute('action_type_name');
  const demPointId = clickable.getAttribute('id_demPoint');
  const demPointAddActionAllowed = clickable.getAttribute('demPoint_add_action_allowed');

  switch (clickActionType) {
    case REMOVE_ACTION_BUTTON_TYPE_NAME:
      if (actionId != undefined) {
        onDeleteActionButtonClicked(actionId);
      }
      break;
    case HIDE_ACTION_BUTTON_TYPE_NAME:
      if (actionId != undefined) {
        onHideActionButtonClicked(actionId);
      }
      break;
    case COPY_TO_CLIPBOARD_BUTTON_TYPE_NAME:
      if (lat != undefined && lon != undefined) {
        copyActionCoordsToClipboard(lat, lon);
      }
      break;
    case SHOW_COORDS_ON_MAP_BUTTON_TYPE_NAME:
      if (lat != undefined && lon != undefined) {
        showOnGoogleMaps(lat, lon);
      }
      break;
    case SHOW_ACTION_GALLERY_BUTTON_TYPE_NAME:
      if (actionId != undefined && actionTypeName != undefined) {
        onShowActionGalleryButtonClicked(actionId, actionTypeName);
      }
      break;
    case UPDATE_ACTION_BUTTON_TYPE_NAME:
      if (actionId != undefined) {
        onActionUpdateButtonClicked(parseInt(actionId));
      }
      break;
    case ACTIVATE_DEACTIVATE_DEMPOINT_BUTTON_TYPE_NAME:
      if (demPointId != undefined && demPointAddActionAllowed != undefined) {
        onDemPointActivateDeactivateButtonClicked(demPointId, demPointAddActionAllowed);
      }
      break;
  }
}

function onDeleteActionButtonClicked(actionId) {
  Swal.fire(
    getSwalConfiguration('Usuwanie akcji', 'Czy na pewno chcesz usunąć wybraną akcję?')
  ).then((result) => {
    if (result.value) deleteAction(actionId);
  });
}

async function deleteAction(actionId) {
  var response = await RepositoryActions.deleteAction(actionId);
  handleResponseWithMessages(response, 'Akcja została usunięta.', () => {
    emit('refreshAll');
  });
}

function onHideActionButtonClicked(actionId) {
  Swal.fire(
    getSwalConfiguration('Ukrywanie akcji', 'Czy na pewno chcesz ukryć wybraną akcję?')
  ).then((result) => {
    if (result.value) toggleActionVisibility(actionId, false);
  });
}

async function toggleActionVisibility(actionId, visible) {
  var response = await RepositoryActions.toggleActionVisibility(actionId, visible);

  handleResponseWithMessages(response, 'Akcja została ukryta.', () => {
    onActionVisibilityChanged();
  });
}

function copyActionCoordsToClipboard(lat, lon) {
  copyToClipboard(lat + ',' + lon);
}

function onShowActionGalleryButtonClicked(actionId, actionTypeName) {
  let routeData = router.resolve({
    name: 'singleActionGallery',
    query: {
      orderId: props.orderId,
      actionId: actionId,
      actionTypeName: actionTypeName,
      orderNumber: props.orderNumber
    }
  });

  window.open(routeData.href, '_blank');
}

function onDemPointActivateDeactivateButtonClicked(demPointId, demPointAddActionAllowed) {
  const header = demPointAddActionAllowed == 1 ? 'Dezaktywacja' : 'Aktywacja';
  const message = `Czy na pewno chcesz ${
    demPointAddActionAllowed == 1 ? 'dezaktywować' : 'aktywować'
  } punkt?`;

  Swal.fire(getSwalConfiguration(header, message)).then((result) => {
    if (result.value) {
      updateDemPointActivationState(demPointId, demPointAddActionAllowed == 1 ? 0 : 1);
    }
  });
}

async function updateDemPointActivationState(demPointId, newValue) {
  var response =
    newValue == 1
      ? await RepositoryDemPoints.activateDemPoint(demPointId)
      : await RepositoryDemPoints.deactivateDemPoint(demPointId);

  handleResponseWithMessages(
    response,
    newValue ? 'Aktywowano punkt.' : 'Dezaktywowano punkt.',
    () => {
      fetchMarkersInfo();
    }
  );
}

function onTabClicked(tabId) {
  switch (tabId) {
    case 0:
      alreadyLoadedTabs.value.orderSummary = true;
      break;
    case 1:
      alreadyLoadedTabs.value.actionsTable = true;
      break;
  }
}

function onDisabledActionTypesChanged(updatedDisabledActionTypesIds) {
  disabledActionTypesIds.value = updatedDisabledActionTypesIds;
  prepareMarkersForMap();
}

function onDisabledFailureReasonsChanged(updatedDisabledFailureReasonsIds) {
  disabledFailureReasonsIds.value = updatedDisabledFailureReasonsIds;
  prepareMarkersForMap();
}

function onDisabledUserOrdersChanged(updatedDisabledUserOrdersIds) {
  disabledUserOrdersIds.value = updatedDisabledUserOrdersIds;
  prepareMarkersForMap();
}

function onEnabledUserOrdersStartStopsChanged(updatedEnabledUserOrdersStartStopsIds) {
  enabledUserOrdersStartStopsIds.value = updatedEnabledUserOrdersStartStopsIds;
  prepareMarkersForMap();
}

function onShowOnlyLastActionsFilterChanged(updatedShowOnlyLastActionsFilter) {
  showOnlyLastActionsFilterEnabled.value = updatedShowOnlyLastActionsFilter;
  prepareMarkersForMap();
}

function onActionVisibilityChanged() {
  fetchMarkersInfo();
  actionsTable.value?.reloadCurrentPage();
}

function onAddActionButtonClicked() {
  if (props.order?.last_worker) {
    manualOrderPerform.value.show();
  } else {
    toaster.show('Aby utworzyć akcję, proszę najpierw dodać wykonawcę.', {
      duration: 3000,
      type: 'warning'
    });
  }
}

function onActionUpdateButtonClicked(actionId) {
  actionEditModalView.value.show(actionId);
}

function onMarkerClickedOverride(markerToSelect) {
  onSingleMarkerSelected(markerToSelect);
  const groupId = markerToSelect.data.groupId;
  updateMarkersTransparencyBasedOnGroupId(groupId);
}

function onMapClickedOverride() {
  resetSelectedIfCtrl();
  updateMarkersTransparencyBasedOnGroupId(null);
}

function updateMarkersTransparencyBasedOnGroupId(groupId) {
  const transparencyClassName = 'transparency-30';

  clusterMap.value.markersRef.forEach((markerRef) => {
    const classNameSource = markerRef.data.divIcon ?? markerRef.data;

    if (groupId == null && classNameSource.className === transparencyClassName) {
      classNameSource.className = '';
      updateMarker(markerRef);
    }

    if (
      groupId != null &&
      groupId === markerRef.data.groupId &&
      classNameSource.className === transparencyClassName
    ) {
      classNameSource.className = '';
      updateMarker(markerRef);
    } else if (
      groupId != null &&
      groupId !== markerRef.data.groupId &&
      classNameSource.className !== transparencyClassName
    ) {
      classNameSource.className = transparencyClassName;
      updateMarker(markerRef);
    }
  });
}

function onMapReady() {
  if (!isNaN(parseInt(idActionForSearch.value))) {
    focusMapOnActionWithId(idActionForSearch.value);
  }
}

function focusMapOnActionWithId(actionId) {
  const foundMarker = clusterMap.value.markersRef.find(
    (marker) => marker.data.actionId === actionId
  );

  if (foundMarker) {
    focusOnMarker(foundMarker);
  }
}

onMounted(() => {
  if (props.orderId != null) {
    initialize();
  }
});

defineExpose({ refreshMapBounds });
</script>

<template>
  <div>
    <div class="row">
      <div class="col-lg-8">
        <div class="ibox">
          <div class="ibox-content" :class="{ 'sk-loading': isLoading }">
            <div class="sk-spinner sk-spinner-three-bounce">
              <div class="sk-bounce1"></div>
              <div class="sk-bounce2"></div>
              <div class="sk-bounce3"></div>
            </div>

            <ClusterMap
              ref="clusterMap"
              height="700px"
              :showZeroPositions="false"
              :disableClusteringAtZoomOverride="19"
              :maxClusterRadiusOverride="1"
              :refreshable="true"
              :areaSelecting="true"
              @selected-markers-changed="onSelectedMarkersChanged"
              @map-clicked="onMapClickedOverride"
              @markerClicked="onMarkerClickedOverride"
              @clickableClicked="onMapClickableClicked"
            >
              <button
                v-if="
                  isNotEmptyArray(selectedMarkerIds) &&
                  authStore.canAccess(ACCESS_KEY_ACTION_ADD_BUTTON)
                "
                class="btn btn-primary"
                style="position: absolute; top: 10px; right: 10px; z-index: 500"
                @click="onAddActionButtonClicked"
              >
                Dodaj akcję
              </button>
              <button
                class="btn btn-primary"
                style="position: absolute; bottom: 10px; left: 10px; z-index: 500"
                @click="refreshMapBounds"
              >
                <i class="fa fa-refresh" />
              </button>
            </ClusterMap>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="tabs-container">
          <ul class="nav nav-tabs" role="tablist">
            <li @click="onTabClicked(0)">
              <a class="nav-link active" data-toggle="tab" href="#order-actions-details-tab-1">
                <i class="fa fa-info" />
                Informacje
              </a>
            </li>
            <li id="actionsTab" @click="onTabClicked(1)">
              <a class="nav-link" data-toggle="tab" href="#order-actions-details-tab-2">
                <img
                  src="/static/img/owner-gray.png"
                  alt="Typy akcji"
                  width="13"
                  height="13"
                  style="margin-right: 6px"
                />
                Akcje
              </a>
            </li>
          </ul>
          <div class="tab-content">
            <div role="tabpanel" id="order-actions-details-tab-1" class="tab-pane active">
              <div class="panel-body">
                <OrderSummary
                  v-if="alreadyLoadedTabs.orderSummary"
                  :orderId="props.orderId"
                  :order="props.order"
                  :actionTypes="availableActionTypes"
                  @disabledActionTypesChanged="onDisabledActionTypesChanged"
                  @disabledFailureReasonsChanged="onDisabledFailureReasonsChanged"
                  @disabledUserOrdersChanged="onDisabledUserOrdersChanged"
                  @showOnlyLastActionsFilterChanged="onShowOnlyLastActionsFilterChanged"
                  @enabledUserOrdersStartStopsChanged="onEnabledUserOrdersStartStopsChanged"
                  @refresh="emit('refreshAll')"
                />
              </div>
            </div>

            <div role="tabpanel" id="order-actions-details-tab-2" class="tab-pane">
              <div class="panel-body">
                <button
                  v-if="authStore.canAccess(ACCESS_KEY_ACTION_ADD_BUTTON)"
                  class="btn btn-outline-primary btn-xs mb-3"
                  @click="onAddActionButtonClicked"
                >
                  Dodaj akcję
                </button>

                <ActionsTable
                  v-if="alreadyLoadedTabs.actionsTable"
                  ref="actionsTable"
                  :orderId="props.orderId"
                  :longerSearchBar="true"
                  @actionVisibilityChanged="onActionVisibilityChanged"
                  @actionUpdateButtonClicked="onActionUpdateButtonClicked"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ManualOrderPerform
      ref="manualOrderPerform"
      :orderId="props.orderId"
      :demPointsForMap="demPointsForMap"
      :actionsWithNoDemPointForMap="actionsWithNoDemPointForMap"
      :selectedDemPointsIds="selectedMarkerIds"
      @succeeded="emit('refreshAll')"
    />

    <ActionEditModalView
      ref="actionEditModalView"
      :orderId="props.orderId"
      :demPointsForMap="demPointsForMap"
      :actionsWithNoDemPointForMap="actionsWithNoDemPointForMap"
      @succeeded="emit('refreshAll')"
    />
  </div>
</template>

<style scoped></style>
