import repository from './repository.js';

export default {
  async activateDemPoint(demPointId) {
    return repository
      .get(`/dempoint/${demPointId}/activate`)
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async deactivateDemPoint(demPointId) {
    return repository
      .get(`/dempoint/${demPointId}/deactivate`)
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  }
};
