const AGGREGATE_DEM_POINT_TYPE_ID = 2;

export function getIconForDemPointMarker(demPoint, className = '') {
  if (demPoint.add_action_allowed === 0) {
    return {
      icon: 'action_marker_black_x',
      iconSize: [33, 48],
      iconAnchor: [16, 48],
      className: className
    };
  }

  let icon = null;
  let size = [0, 0];
  let anchor = [0, 0];

  if (demPoint.dem_point_type.id === AGGREGATE_DEM_POINT_TYPE_ID) {
    icon = 'blue_marker';
    size = [25, 40];
    anchor = [12, 40];

    const labelIconOffsetPx = 2;
    const labelSizePx = [50, 20];
    const horizontalLabelTranslation = -(labelSizePx[0] / 2 - anchor[0]);
    const verticalLabelTranslation = -(size[1] + labelSizePx[1] + labelIconOffsetPx);

    return {
      divIcon: {
        className,
        iconSize: size,
        iconAnchor: anchor,
        html: `<img src="/static/img/markerIcons/${icon}.png" class="${className}" width="${size[0]}" height="${size[1]}" /><div class="marker-label ${className}" style="width: ${labelSizePx[0]}px; height: ${labelSizePx[1]}px; transform:translate(${horizontalLabelTranslation}px, ${verticalLabelTranslation}px);"><p class="marker-label-text">${demPoint.number}</p></div>`
      }
    };
  }

  switch (demPoint.dem_elem_type.icon) {
    case 'circle':
      icon = 'circle_blue';
      size = [34, 32];
      anchor = [17, 16];
      break;
    case 'square':
      if (demPoint.dem_elem_type.id === 6) {
        // OSD
        icon = 'osd_circle_icon';
        size = [36, 36];
        anchor = [18, 18];
      } else {
        icon = 'square_blue';
        size = [26, 24];
        anchor = [13, 12];
      }
      break;
    case 't_letter':
      icon = 't_letter_blue';
      size = [24, 24];
      anchor = [12, 12];
      break;
    case 'default':
      icon = 'blue_marker';
      size = [25, 40];
      anchor = [12, 40];
      break;
  }

  return {
    icon: icon,
    iconSize: size,
    iconAnchor: anchor,
    className: className
  };
}
