<script setup>
import { ref, computed, watch } from 'vue';
import { useRouter } from 'vue-router';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import DictionaryTable from '../dictionaries/DictionaryTable.vue';

const RepositoryOrders = RepositoryFactory.get('orders');

const props = defineProps({
  orderId: Number,
  isLoading: { type: Boolean, default: false }
});

const localIsLoading = ref(false);
const transportStats = ref(null);
const router = useRouter();

const tableColumns = [
  {
    key: 'id',
    header: 'Id miejsca',
    computed: false,
    thStyle: 'white-space: nowrap;'
  },
  { key: 'worker', header: 'Brygadzista', computed: true },
  { key: 'reported', header: 'Elementy zgłoszone', computed: true, tdStyle: 'white-space: pre;' },
  {
    key: 'done',
    header: 'Elementy wykonane',
    computed: true,
    tdStyle: 'white-space: pre;'
  },
  { key: 'left', header: 'Pozostawione', computed: true, tdStyle: 'white-space: pre;' }
];

const transportStatsText = computed(() => {
  if (transportStats.value == null) return '';
  if (transportStats.value.counterAll === 0) return 'Brak miejsc składowania w zleceniu';
  if (transportStats.value.counterNotFinished > 0)
    return 'Nie zrealizowano wszystkich zleceń transportowych';

  return `Zrealizowano wszystkie zlecenia transportowe ${transportStats.value.date ?? ''}`;
});

watch(
  () => props.orderId,
  () => {
    fetchTransportStats();
  }
);

async function fetchTransportStats() {
  localIsLoading.value = true;
  transportStats.value = await RepositoryOrders.getOrderTransportStats(props.orderId);
  localIsLoading.value = false;
}

function showTransportOrderInNewTab(transportOrderId) {
  let routeData = router.resolve({
    name: 'transportOrderDetails',
    params: { id: transportOrderId }
  });

  window.open(routeData.href, '_blank');
}

function getElementsColumnText(data) {
  if (!data) return null;

  let text = '';

  for (const property in data) {
    text += `${property}: ${data[property]}\n`;
  }

  if (text.endsWith('\n')) {
    text = text.slice(0, -1);
  }

  return text;
}

// created
if (props.orderId != null) {
  fetchTransportStats();
}
</script>

<template>
  <div class="ibox">
    <div
      class="ibox-content"
      :class="{ 'sk-loading': isLoading || localIsLoading }"
      style="border-style: none"
    >
      <div class="sk-spinner sk-spinner-three-bounce">
        <div class="sk-bounce1"></div>
        <div class="sk-bounce2"></div>
        <div class="sk-bounce3"></div>
      </div>

      <h5>I. Miejsca składowania</h5>

      <DictionaryTable
        v-if="props.orderId != null"
        :showHeader="false"
        :showBorder="false"
        :url="`order/${props.orderId}/storagesTable`"
        :no-pagination="true"
        :columns="tableColumns"
        :customButtons="true"
        :show-search-bar="false"
      >
        <template #worker="{ element }">
          {{
            element?.worker == null ? '-' : element.worker.firstName + ' ' + element.worker.lastName
          }}
        </template>
        <template #reported="{ element }">
          {{ getElementsColumnText(element.elements_reported) ?? '-' }}
        </template>
        <template #done="{ element }">
          {{ getElementsColumnText(element.elements_done) ?? '-' }}
        </template>
        <template #left="{ element }">
          {{ getElementsColumnText(element.elements_left) ?? '-' }}
        </template>
        <template #buttons="{ element }">
          <button
            v-if="element?.order_transport?.id != null"
            type="button"
            class="btn btn-outline-info btn-xs"
            @click="showTransportOrderInNewTab(element.order_transport.id)"
            onclick="event.stopPropagation()"
          >
            Pokaż zlecenie
          </button>
        </template>
      </DictionaryTable>

      <div class="mt-4">
        <h5>II. Potwierdzenie realizacji zebrania słupów</h5>

        <h4 class="mt-3">
          {{ transportStatsText }}
        </h4>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
