<script setup>
import { ref, computed, watch, unref } from 'vue';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import { PAGINATION_LIMIT } from '../../data/constants/appConstants.js';
import { usePaginationStore } from '@/stores/storePagination';
import ServerErrorPanel from '@/components/utils/ServerErrorPanel.vue';
import TableElementsInfo from '@/components/utils/TableElementsInfo.vue';
import { Bootstrap4Pagination } from 'laravel-vue-pagination';
import PaginationCountPicker from '@/components/utils/PaginationCountPicker.vue';
import { DICTIONARY_TABLE_COMPONENT_PAGINATION_KEY } from '../../data/paginationInjectionKeys';
import { isNotEmptyArray } from '../../helpers/utilsHelper.js';

const RepositoryDictionaries = RepositoryFactory.get('dictionaries');

const props = defineProps({
  selectableItems: { type: Boolean, default: false },
  showHeader: { type: Boolean, default: true },
  showBorder: { type: Boolean, default: true },
  showSearchBar: { type: Boolean, default: true },
  noPagination: { type: Boolean, default: false },
  searchFromOutside: { type: String, default: null },
  header: { type: String, default: '' },
  url: String,
  columns: {
    type: Array,
    default: () => [
      { key: 'id', header: 'Id' },
      { key: 'name', header: 'Nazwa' }
    ]
  },
  multiSelections: { type: Boolean, default: false },
  initiallyPickedItems: { type: Array, default: null },
  customButtons: { type: Boolean, default: false },
  showTableElementsInfo: { type: Boolean, default: true },
  userFilter: { type: Object, default: null },
  customPaginationKey: { type: String, default: null },
  contentCustomStyle: { type: String, default: '' },
  headerCustomStyle: { type: String, default: '' }
});

const emit = defineEmits(['on-completed', 'dataFetched']);

const isLoading = ref(false);
const dictionaryElementsObject = ref({});
const searchBox = ref('');
const currentSort = ref({ by: null, direction: null });
const paginationStore = usePaginationStore();
const currentlyLoadedPage = ref(null);
const pickedItems = ref([]);

const headerStyle = computed(() => {
  let style = props.showBorder ? '' : 'border-style: none;';
  style += props.headerCustomStyle;

  return style;
});

const contentStyle = computed(() => {
  let style = props.showBorder ? '' : 'border-style: none;';
  style += props.contentCustomStyle;

  return style;
});

watch(
  () => props.url,
  () => {
    fetchData();
  },
  { immediate: true }
);

watch(
  () => props.searchFromOutside,
  () => {
    fetchData();
  }
);

function fetchData(page = 1) {
  isLoading.value = true;

  RepositoryDictionaries.getDictionaryElementsObject(
    page,
    props.noPagination
      ? 1000000
      : paginationStore.getPaginationItemsCount(
          props.customPaginationKey ?? DICTIONARY_TABLE_COMPONENT_PAGINATION_KEY
        ),
    props.searchFromOutside != null ? props.searchFromOutside : searchBox.value,
    currentSort.value.by,
    currentSort.value.direction,
    props.url,
    props.userFilter
  )
    .then((data) => {
      if (props.multiSelections) {
        data.data.forEach((element) => {
          element.picked = pickedItems.value.find((item) => item.id == element.id) != null;
        });
      }

      dictionaryElementsObject.value = data;
      currentlyLoadedPage.value = page;
      emit('dataFetched', data);
    })
    .catch((error) => console.log(error))
    .finally(() => {
      isLoading.value = false;
    });
}

function pickItem(dictionaryElement) {
  if (!props.multiSelections) {
    emit('on-completed', dictionaryElement);
    return;
  }

  dictionaryElement.picked = !dictionaryElement.picked;

  const elementFoundInPicked = pickedItems.value.find((item) => item.id == dictionaryElement.id);

  if (dictionaryElement.picked && elementFoundInPicked == null) {
    pickedItems.value.push(dictionaryElement);
  } else if (!dictionaryElement.picked && elementFoundInPicked != null) {
    const index = pickedItems.value.indexOf(elementFoundInPicked);
    pickedItems.value.splice(index, 1);
  }

  emit('on-completed', dictionaryElement, unref(pickedItems));
}

function search() {
  fetchData();
}

function reloadCurrentPage() {
  fetchData(currentlyLoadedPage.value ?? 1);
}

// created
pickedItems.value = [];

if (Array.isArray(props.initiallyPickedItems)) {
  props.initiallyPickedItems.forEach((element) => {
    pickedItems.value.push(element);
  });
}

defineExpose({ reloadCurrentPage });
</script>

<template>
  <div class="ibox">
    <div v-if="props.showHeader" class="ibox-title" :style="headerStyle">
      <h5>{{ props.header }}</h5>
    </div>
    <div class="ibox-content" :class="{ 'sk-loading': isLoading }" :style="contentStyle">
      <div class="sk-spinner sk-spinner-three-bounce">
        <div class="sk-bounce1"></div>
        <div class="sk-bounce2"></div>
        <div class="sk-bounce3"></div>
      </div>

      <div
        v-if="
          props.searchFromOutside == null &&
          props.showSearchBar &&
          isNotEmptyArray(dictionaryElementsObject.data)
        "
        class="row"
      >
        <div :class="props.selectableItems ? 'col-sm-5 offset-sm-7' : 'col-sm-3 offset-sm-9'">
          <div class="input-group mb-3">
            <input
              placeholder="Szukaj.."
              type="text"
              class="form-control form-control-sm"
              v-model="searchBox"
              v-on:keyup.enter="search"
            />
            <span class="input-group-append">
              <button @click="search" type="button" class="btn btn-sm btn-primary">
                <i class="fa fa-search"></i> Szukaj
              </button>
            </span>
          </div>
        </div>
      </div>

      <TableElementsInfo
        v-if="props.showTableElementsInfo"
        :metaObject="dictionaryElementsObject ? dictionaryElementsObject.meta : null"
      />

      <div v-if="isNotEmptyArray(dictionaryElementsObject.data)" class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th v-for="column in columns" :key="column.key" :style="column.thStyle ?? ''">
                {{ column.header }}
              </th>
              <th v-if="selectableItems"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="dictionaryElement in dictionaryElementsObject.data"
              :key="dictionaryElement.id"
              @click="selectableItems ? pickItem(dictionaryElement) : null"
              :style="selectableItems ? 'cursor: pointer;' : ''"
            >
              <td v-for="column in columns" :key="column.key" :style="column.tdStyle ?? ''">
                <span v-if="column.computed">
                  <slot :name="column.key" :element="dictionaryElement"></slot>
                </span>
                <span v-else>{{ dictionaryElement[column['key']] ?? '-' }}</span>
              </td>
              <td v-if="selectableItems">
                <div class="float-right">
                  <button
                    v-if="props.multiSelections"
                    class="btn btn-primary btn-xs mr-2"
                    :class="dictionaryElement.picked === true ? 'btn-danger' : ''"
                    type="button"
                    @click="pickItem(dictionaryElement)"
                    onclick="event.stopPropagation()"
                  >
                    <span v-if="dictionaryElement.picked === true">Odznacz</span>
                    <span v-else>Zaznacz</span>
                  </button>

                  <button
                    v-else
                    class="btn btn-outline btn-primary btn-xs mr-2"
                    type="button"
                    @click="pickItem(dictionaryElement)"
                    onclick="event.stopPropagation()"
                  >
                    Wybierz
                  </button>
                </div>
              </td>
              <td v-if="customButtons">
                <div class="float-right">
                  <slot name="buttons" :element="dictionaryElement"></slot>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else-if="dictionaryElementsObject?.error">
        <server-error-panel @onRetry="fetchData" />
      </div>
      <div v-else>
        <p class="text-center mt-3">Brak elementów do wyświetlenia</p>
      </div>

      <div class="row mt-3" v-if="isNotEmptyArray(dictionaryElementsObject.data) && !noPagination">
        <div class="col-xl-6 offset-xl-3">
          <Bootstrap4Pagination
            v-if="dictionaryElementsObject && dictionaryElementsObject.data"
            align="center"
            :data="dictionaryElementsObject"
            :limit="PAGINATION_LIMIT"
            @pagination-change-page="fetchData"
          ></Bootstrap4Pagination>
        </div>

        <div class="col-xl-3">
          <pagination-count-picker
            :paginationKey="props.customPaginationKey ?? DICTIONARY_TABLE_COMPONENT_PAGINATION_KEY"
            v-show="!isLoading"
            @selectionChanged="fetchData"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>
