<script setup>
import { ref, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import FormErrorPanel from '@/components/utils/FormErrorPanel.vue';
import ModalTable from '@/components/utils/ModalTable.vue';
import { MODAL_USERS_TABLE } from '../../data/constants/modalConstants.js';
import ModalInputField from '@/components/utils/ModalInputField.vue';
import { createToaster } from '@meforma/vue-toaster';
import { TOASTER_SERVER_ERROR_MSG } from '../../data/constants/toasterMessagesConstants.js';
import { isNotEmptyArray } from '../../helpers/utilsHelper.js';

const RepositoryTransportOrders = RepositoryFactory.get('transportOrders');
const RepositoryActions = RepositoryFactory.get('actions');
const toaster = createToaster({ position: 'top-right', duration: 3000 });
const router = useRouter();
const route = useRoute();
const modalTable = ref();
const formErrors = ref([]);
const isLoading = ref(false);
const ordersNumbers = ref([]);
const drivers = ref([]);

const driversIds = computed(() => {
  return isNotEmptyArray(drivers.value) ? drivers.value.map((x) => x.id) : [];
});

const driversNames = computed(() => {
  let names = '';

  if (!isNotEmptyArray(drivers.value)) return null;

  for (let i = 0; i < drivers.value.length; i++) {
    const driver = drivers.value[i];
    names += `${driver.firstName} ${driver.lastName}, `;
  }

  if (names.endsWith(', ')) {
    names = names.slice(0, -2);
  }

  return names;
});

const placesIds = computed(() => {
  const result = route.query.placesIds.split(',').map((placeId) => parseInt(placeId));
  return isNotEmptyArray(result) ? result : [];
});

async function fetchOrdersNumbers() {
  isLoading.value = true;

  RepositoryActions.convertActionsIdsToOrderNumbers(placesIds.value)
    .then((data) => {
      ordersNumbers.value = data;
      ordersNumbers.value = ordersNumbers.value.join(', ');
    })
    .catch((error) => console.log(error))
    .finally(() => {
      isLoading.value = false;
    });
}

function submitForm(e) {
  e.preventDefault();
  sendAddRequest();
}

async function sendAddRequest() {
  isLoading.value = true;

  var response = await RepositoryTransportOrders.createTransportOrder(
    driversIds.value,
    placesIds.value
  );

  if (response?.serverError) {
    toaster.show(TOASTER_SERVER_ERROR_MSG, { type: 'error' });
  } else {
    if (response?.id_order_transport != null) {
      toaster.show('Zlecenie transportowe zostało utworzone.', { type: 'success' });
      showDetails(response.id_order_transport);
    } else {
      let message = '';

      if (response?.ok && !response.not_ok) {
        message = `Utworzono ${response.ok} ${
          response.ok < 5 ? 'zlecenia transportowe' : 'zleceń transportowych'
        }`;
      } else {
        message = `Liczba utworzonych zleceń transportowych: ${response.ok}\nLiczba niepowodzeń: ${response.not_ok}`;
      }

      toaster.show(message, { type: 'success' });
      router.push({ name: 'transportOrdersTable' });
    }
  }

  isLoading.value = false;
}

function onModalCompleted(data, data2) {
  switch (modalTable.value.modal.type) {
    case MODAL_USERS_TABLE:
      drivers.value = data2 ?? null;
      break;
  }
}

function onModalRejected() {
  modalTable.value.hide();

  switch (modalTable.value.modal.type) {
    case MODAL_USERS_TABLE:
      drivers.value = [];
      break;
  }
}

function showDetails(id) {
  if (id == null) return;
  router.push({ name: 'transportOrderDetails', params: { id } });
}

fetchOrdersNumbers();
</script>

<template>
  <div>
    <FormErrorPanel :errors="formErrors" />

    <div id="row-main" class="row">
      <div id="content" class="col-12">
        <div class="ibox">
          <div class="ibox-title">
            <h5>Dodaj zlecenie transportowe</h5>
          </div>

          <div class="ibox-content" :class="{ 'sk-loading': isLoading }">
            <div class="sk-spinner sk-spinner-three-bounce">
              <div class="sk-bounce1"></div>
              <div class="sk-bounce2"></div>
              <div class="sk-bounce3"></div>
            </div>

            <form role="form" @submit="submitForm">
              <ModalInputField
                title="Kierowcy"
                :displayText="driversNames"
                nullText="Wybierz kierowców"
                :required="true"
                @showModal="
                  modalTable.showModal(MODAL_USERS_TABLE, {
                    additionalProps: {
                      filter: { url: 'user/drivers' },
                      multiSelections: true,
                      initiallyPickedItems: drivers
                    }
                  })
                "
              />

              <div class="form-group">
                <label>Numer zlecenia</label>
                <input type="text" :value="ordersNumbers" class="form-control" disabled />
              </div>

              <div class="row">
                <div class="col-12">
                  <button class="float-right btn btn-sm btn-primary m-t-n-xs" type="submit">
                    <strong>Dodaj</strong>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <ModalTable
      ref="modalTable"
      :showCloseButton="true"
      overrideCloseButtonText="Zatwierdź wybór"
      @onModalCompleted="onModalCompleted"
      @onModalRejected="onModalRejected"
    ></ModalTable>
  </div>
</template>

<style scoped></style>
