<script setup>
import { ref, computed } from 'vue';
import { useRoute } from 'vue-router';
import UserDetailsGeneral from '@/components/users/UserDetailsGeneral.vue';
import UserDetailsAdditional from '@/components/users/UserDetailsAdditional.vue';
import { navItems } from '@/helpers/navigationPanelHelper.js';
import { useNavigationPanelStore } from '@/stores/navigationPanel';
import { DRIVER_TYPE_ID, WORKER_TYPE_ID } from '../../data/constants/authConstants';

const route = useRoute();
const userTypeId = ref(null);

const userIdForDetails = computed(() => {
  return parseInt(route.params.id);
});

const isWorker = computed(() => {
  return userTypeId.value === WORKER_TYPE_ID;
});

const isDriver = computed(() => {
  return userTypeId.value === DRIVER_TYPE_ID;
});

function userInfoReceived(userType) {
  userTypeId.value = userType?.id;
}

// created
useNavigationPanelStore().setNavigationPanelItems(navItems.USER_DETAILS);
</script>

<template>
  <div class="row">
    <div class="col-lg-4">
      <UserDetailsGeneral :id="userIdForDetails" @userInfoReceived="userInfoReceived" />
    </div>
    <div v-if="isWorker || isDriver" class="col-lg-8">
      <UserDetailsAdditional :id="userIdForDetails" :userTypeId="userTypeId" />
    </div>
  </div>
</template>

<style scoped></style>
